<div class="app-content-wrapper">

    <div class="d-md-flex mt-3" *ngIf="!isLoggedIn">
        <div class="flex-fill p-3" id="authent">

            <form [formGroup]="loginForm" (ngSubmit)="login()">

                <div class="row">
                    <div class="col-12 pr-md-0 col-md-4">
                        <label for="inputEmail" class="sr-only">Adresse email</label>
                        <input type="email" class="form-control rounded-0 mb-1" id="email"
                            placeholder="Adresse e-mail" formControlName="email" required
                            (keyup)="resetLoginFailureMessage()">
                    </div>
                    <div class="col-12 pr-md-0 col-md-4">
                        <label for="inputPassword" class="sr-only">Mot de passe</label>
                        <input type="password" class="form-control rounded-0 mb-1" id="password"
                            placeholder="Mot de passe" formControlName="password" required
                            (keyup)="resetLoginFailureMessage()">
                    </div>
                    <div class="col-12 col-md-4">
                        <button class="btn btn-primary btn-block" type="submit"
                            [disabled]="!loginForm.valid || loginAttemptPending">
                            S'identifier
                            <i class="material-icons notranslate rotating align-middle"
                                *ngIf="loginAttemptPending">watch_later</i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="bg-gradient-danger shadow text-small text-center p-2 mt-3 line-height-3"
        *ngIf="loginFailureMessage">
        <i class="material-icons notranslate align-middle mr-1">error</i>
        <ng-container *ngIf="loginFailureMessage=='credentials'">
            <strong>Identifiant ou mot de passe invalide.</strong>
            Merci de vérifier votre saisie.
        </ng-container>

        <ng-container *ngIf="loginFailureMessage=='other'">
            <strong>La connexion n'a pu aboutir.</strong>
            Merci de réessayer dans quelques minutes
        </ng-container>
    </div>
    
</div>
