import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { CoreService } from '@mf-framework/core-service';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, takeUntil, catchError, take } from 'rxjs/operators';
import { FormControl, Validators, FormGroup, ValidatorFn, AbstractControl, FormBuilder } from '@angular/forms';
import { isObject } from '@mf-framework/utils/objects';
import { citySearchResultFormatter } from '@mf-framework/utils/misc';




@Component({
    selector: 'properties-create',
    templateUrl: './properties-create.component.html',
    styleUrls: ['./properties-create.component.scss']
})
export class PropertiesCreateComponent implements OnInit, OnDestroy {
    private _destroyed$ = new Subject();
    references
    
    constructor(
        public coreService: CoreService, 
        private router: Router,
        private cd: ChangeDetectorRef,
        private fb: FormBuilder,
    ) { }

    ngOnInit() {
        this.setCountries()

        // this.newProperty.valueChanges
        // .pipe(takeUntil(this._destroyed$))
        // .subscribe(val => {
        //     if(
        //         val.currentCity
        //         && val.currentCity.code
        //         && val.currentCity.code != this.lastCitySectionsQueried
        //     ){
        //         this.querySections()
        //     }

        //     this.newProperty.markAsDirty() 
        //     this.cd.detectChanges()
        // })
    }

    selectedCountry = "FR"
    
    updateFormCountry(country) {
        let countryConf = this.countries.find(o=>o.key==country.key)
        if(countryConf.localityId) {
            this.newProperty.patchValue({currentCity:{id:countryConf.localityId}})
        }
        else{
            this.newProperty.patchValue({currentCity:null})
        }

        this.cd.detectChanges()
    }

    countries
    // Wallonie (BE), Grand-Est (F), Rhénanie-Palatinat (D), Sarre (D), Grand-Duché (LU).
    setCountries() {
        let countries = [
            {
                key:"FR",
                label:"France"
            },
            {
                key:"BE",
                label:"Wallonie (BE)"
            },
        ]
       
        this.countries = countries
    }

    newProperty: FormGroup = this.fb.group(
        {
            propertyName : new FormControl("", [
                Validators.required,
                Validators.minLength(2),
            ]),
            currentCity : new FormControl("", [
                Validators.required,
                Validators.minLength(2),
            ])
        },
        { validator: this.validateNewPropertyForm }
    )

    validateNewPropertyForm(fg: FormGroup) {
        const errorNotObjectCity = {'noCitySelected': ""}
        let errors = {}
        // const countryKey = fg.get('country').value
        const city = fg.get('currentCity')
        
        if(typeof city.value != "object"){
            city.setErrors(errorNotObjectCity)
            Object.assign(errors,errorNotObjectCity)
        }
        
        return errors
    }

    submitted
    posted
    onSubmit(event) {
        event.preventDefault()
        this.submitted = true
        this.newProperty.controls.propertyName.markAsDirty() 
        this.newProperty.controls.currentCity.markAsDirty() 

        if(!this.newProperty.valid)return false

        if(this.posted)return false
        this.posted = true
        
        this.coreService.Reducer$({ 
            type: 'addProperty',
            payload: { 
                name: this.newProperty.value.propertyName,
                localityId : this.newProperty.value.currentCity.id
            } 
        })
        .pipe(takeUntil(this._destroyed$))
        .subscribe(
            r => {
                this.router.navigate(['/dashboard']);
            },
            e => {
                this.posted = false
                console.log(e)
            }
        )
    }
    
    // divisionsSearchFailed
    // lastCityDivisionsQueried

    // queryDivisions() {
    //     if(!this.newProperty.value.currentCity)return 

    //     let localityCode = this.newProperty.value.currentCity.code
        
    //     this.lastCityDivisionsQueried = localityCode
    //     this.divisionsSearchFailed = undefined

    //     this.coreService.Selector$({
    //         type:'queryCadastralDivisionsByCityCode',
    //         payload:{ localityCode : localityCode }
    //     })
    //     .pipe(takeUntil(this._destroyed$))
    //     .subscribe(divisions => {

    //         if(!divisions || !divisions.length){
    //             this.divisionsSearchFailed = true
    //         }
    //         this.cd.detectChanges()
    //     })
    // }

    // lastCitySectionsQueried
    // sectionsSearchFailed
    // querySections() {
    //     if(!this.newProperty.value.currentCity)return 
    //     let localityCode = this.newProperty.value.currentCity.code

    //     this.lastCitySectionsQueried = localityCode
    //     this.sectionsSearchFailed = undefined

    //     this.coreService.Selector$({
    //         type: 'queryCadastralSectionsByCityCode',
    //         payload: { countryCode : this.selectedCountry, localityCode: localityCode }
    //     })
    //     .pipe(
    //         takeUntil(this._destroyed$)
    //     )
    //     .subscribe(
    //         sections => {
    //             if(!sections || !sections.length){
    //                 this.sectionsSearchFailed = true
    //             }
    //             this.cd.detectChanges()
    //         },
    //         error => {

    //         }
    //     )
    // }

    citySearching = false
    citySearchFailed = false
    searchCity = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => {
                // this.sectionsSearchFailed = undefined
                this.citySearching = true
            }),
            switchMap(term => {
                // if(this.selectedCountry!="BE-WAL") {
                //     return  of([])
                //             .pipe(tap(() => {
                //                 this.citySearchFailed = false
                //             }))
                // }

                if(term.length < 1) {
                    return  of([])
                            .pipe(tap(() => {
                                this.citySearchFailed = false
                            }))
                }
                
                return this.coreService.Selector$({type:'findCityByString',payload:{countryCode:this.selectedCountry,text:term}})
                .pipe(
                    takeUntil(this._destroyed$),
                    tap(() => {
                        this.citySearchFailed = false
                    }),
                    catchError(() => {
                        this.citySearchFailed = true;
                        return of([]);
                    })
                )
            }),
            tap(() => {
                this.citySearching = false
            })
          )
        
    cityResultFormatter = citySearchResultFormatter
        
    cityInputFormatter = (x) => {return x.name}

    public ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
