import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { AppToastService } from './app-toast.service';

@Component({
    selector: 'mff-app-toasts',
    templateUrl: './app-toast.component.html',
    styleUrls: ['./app-toast.component.scss']
})
export class AppToastsComponent implements OnInit {
    constructor(
        public appToastService: AppToastService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.appToastService.notification.subscribe(()=> {
            this.cd.detectChanges()
        })
    }
}

