import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { OperationMeasure } from './operationMeasure.typing';

@Injectable({
    providedIn: 'root',
})
export class OperationMeasuresFetcher {
    constructor(private http: HttpClient) { }
    
    create(data) {
        delete data.propertyId
        let url =  endpointsUrls.property.operationMeasure
        return this.http.post<OperationMeasure>(url, data)
    }

    delete(operationMeasureId) {
        let url =  `${endpointsUrls.property.operationMeasure}/${operationMeasureId}`
        return this.http.delete(url)
    }

    patch(operationMeasureId: string, data) {
        delete data.id
        let url =  `${endpointsUrls.property.operationMeasure}/${operationMeasureId}`
        return this.http.patch<OperationMeasure>(url, data)
    }
}