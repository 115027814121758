import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
registerLocaleData(localeFr, 'fr', localeFrExtra);

import { NgbModalModule, NgbToastModule, NgbCollapseModule, NgbTypeaheadModule, NgbTooltipModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { RollbarErrorHandler, RollbarService, rollbarFactory } from '@mf-framework/rollbar/rollbar';

import { environment } from '@current-environment/environment';

import { InterfaceModule } from '@mf-framework/interface/interface.module';
import { HttpErrorInterceptor  } from '@mf-framework/utils/http-error.interceptor'
import { AuthModule } from '@mf-framework/authentification/auth.module';
import { TokenInterceptor } from '@mf-framework/authentification/token.interceptor';
import { CoreService } from '@mf-framework/core-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PropertiesCreateComponent } from './pages/properties-create/properties-create.component';

// import { ProfileComponent } from './pages/profile/profile.component';
import { MandateComponent } from './pages/mandate/mandate.component';
import { MapModule } from '@mf-framework/map/map.module';

let providers = [
    {
        provide: LOCALE_ID,
        useValue: 'fr'
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor,
        multi: true
    },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
]

if(!environment.production){
    providers.pop()
    providers.pop()
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        DashboardComponent,
        PropertiesCreateComponent,
        // ProfileComponent,
        MandateComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule, ReactiveFormsModule,
        AppRoutingModule,
        MapModule,
        HttpClientModule,
        NgbModalModule, NgbToastModule, NgbCollapseModule, NgbTypeaheadModule, NgbTooltipModule,
        InterfaceModule, BrowserAnimationsModule, AuthModule, NgbDropdownModule,
        PasswordStrengthMeterModule
    ],
    providers: providers,
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule {
    constructor(public coreService : CoreService) {}
 }
