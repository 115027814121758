import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Contact } from './contact.typing';
import { map } from 'rxjs/operators';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';

const PARAMS = new HttpParams({
    fromObject: {
        "extra_fields": "address,person,organization"
    }
})

@Injectable({
    providedIn: 'root',
})
export class ContactsFetcher {
    constructor(private http: HttpClient) { }

    addContact(data) {
        let url =  endpointsUrls.property.contact
        return this.http.post<Contact>(url, data,{params : PARAMS})
        .pipe(
            map((newContact) => {
                if(newContact.organization==null)delete newContact.organization
                if(newContact.person==null)delete newContact.person
                return newContact;
            })
        )
    }

    delete(contactId) {
        let url =  `${endpointsUrls.property.contact}/${contactId}`
        return this.http.delete(url)
    }

    patch(contactId: string, data) {
        let url =  `${endpointsUrls.property.contact}/${contactId}`
        return this.http.patch<Contact>(url, data, {params : PARAMS})
    }

    getContactLots(contactId) {
        let url =  `${endpointsUrls.property.contact}/${contactId}/lots`
        return this.http.get<Contact>(url, {params : PARAMS})
    }
}