import { Properties } from './model/Properties/properties.typing'

interface Ui {
    loadingSpinner: boolean;
    padlocked: boolean;
}

interface References {
    fetched : boolean,
    fetching : boolean,
    publicfetched : boolean,
    publicfetching : boolean,
    data : {}
}

interface Model {
    properties: Properties;
}

interface UserProfile {
    fetched : boolean,
    fetching : boolean,
    data : {}
}

export interface State {
    ui: Ui
    references: References
    model: Model
    userProfile: UserProfile
}

export const initialState = {
    ui : {
        loadingSpinner: true,
        padlocked: false,
        data : {}
    },
    references : {
        fetched : false,
        fetching : false,
        publicfetched : false,
        publicfetching : false,
        data : null
    },
    model : { 
        properties: {
            fetched : false,
            fetching : false,
            collection : []
        }
    },
    userProfile : {
        fetched : false,
        fetching : false,
        data : {}
    }
}