import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@current-environment/environment'
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { getApiUrl } from '@mf-framework/utils/application';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ReferencesFetcher {
    constructor(private http: HttpClient) { }
    index() {
        let url =  getApiUrl(environment,endpointsUrls.references.private)
        return this.http.get(url)
        // .pipe(
        //     catchError(err => {
        //         console.log("Refernces")
        //         if(err.status == 0){
        //             let stored = JSON.parse(localStorage.getItem("STATE"))
        //             console.log(1)
        //             if(stored){
        //                 console.log(2)
        //                 if(stored.references.data){
        //                     console.log(stored.references)
        //                     return of(stored.references.data)
        //                 }
        //             }
        //         }
        //         return of(false)
        //         // return throwError('Error!');
        //     }),
        // )
    }
    
    publicIndex() {
        let url =  getApiUrl(environment,endpointsUrls.references.public)
        return this.http.get(url)
    }
}