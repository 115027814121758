import { Component } from '@angular/core';

@Component({
    selector: 'mff-no-connexion-modal',
    template: `
        <div class="p-5">
            <h4>
            Accès internet indisponible
            </h4>
            Vous devez bénéficier d'une connexion internet (wifi, 3G, 4g, etc...) pour récupérer les données de vos propriétés. Après un premier chargement réussie, vous pourrez y accéder sur le terrain, cette fois en l'absence de connexion.
        </div>
    `,
    styles: ['']
})
export class NoConnexionModal{}