import * as uuid from 'uuid';

export function toRadian(degrees) {
    return degrees * (Math.PI/180);
}

export function toDegreesMinutesAndSeconds(coordinate) {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    return degrees + "° " + minutes + "′ " + seconds + "″";
    // 33° 22′ 59″ N, 44° 13′ 52″ E
}

export function convertDMS(lat, lng) {
    var latitude = toDegreesMinutesAndSeconds(lat);
    var latitudeCardinal = lat >= 0 ? "Nord" : "Sud";

    var longitude = toDegreesMinutesAndSeconds(lng);
    var longitudeCardinal = lng >= 0 ? "Est" : "Ouest";

    return latitude + " " + latitudeCardinal + ", " + longitude + " " + longitudeCardinal;
}

let uuidCounter = 0
export function getUuid(){
    try {
        return uuid.v4()
    } catch (error) {
        return ++uuidCounter        
    }
}