<header class="user-select-none"  *ngIf="coreService.Selector$({type:'uiState'}) | async as uiState">

    <!-- Web header -->
    <div id="mainHeaderWeb" class="bg-white d-none d-sm-block" *ngIf="isLoggedIn && isConfirmed">

        <div class="bg-gray-800 text-white text-medium">
            <div class="d-block px-2 px-md-3 py-2 pointer overflow-hidden text-truncate" (click)="switchUserMenuDisplay()">
                    <i class="material-icons notranslate mt--3px mr-1">menu</i>
                    {{userInfos?.person?.givenName}}
                    {{userInfos?.person?.familyName}}
            </div>
            
            <div [@smoothCollapse]="userMenuDisplayed?'initial':'final'">
                <div [routerLink]="['/dashboard']" class="d-md-flex px-1 px-md-3 py-2 pointer border-bottom" *ngIf="isConfirmed" (click)="userMenuDisplayed = !userMenuDisplayed">
                    <i class="material-icons notranslate mr-2 d-none d-md-block">home</i>
                    Tableau de bord
                </div>
                <div class="d-md-flex px-1 px-md-3 py-2 pointer border-bottom" *ngIf="isConfirmed" (click)="exitApp('profile')">
                    <i class="material-icons notranslate mr-2 d-none d-md-block">account_circle</i>
                    Infos utilisateur
                </div>
                <div (click)="exitApp()" class="d-md-flex bg-warning px-1 px-md-3 py-2 pointer" (click)="userMenuDisplayed = !userMenuDisplayed">
                    <i class="material-icons notranslate mr-2 d-none d-md-block">arrow_back</i>
                    Quitter l'application
                </div>
            </div>
        </div>

        <div class="bg-secondary">
            <div class="p-3 p-lg-4">
                <a class="navbar-brand m-1 mb-2 d-flex align-items-center" [routerLink]="['/dashboard']"></a>        
            </div>
        </div>

        <div id="mainNavigationMenu" class="bg-primary text-white" *ngIf="currentPropertyId && property">
            <div class="d-sm-block d-md-flex pl-2 pl-md-3 pt-4 pb-2 font-weight-bold align-items-center">
                <!-- <div class="pr-0 pr-md-2">
                    <div class="padlock user-select-none mb-2 mb-md-0 align-middle" [class.locked]="!uiState.padlocked"
                        (click)="coreService.SyncReducer({type:'togglePadlockState'})">
                        <i class="material-icons notranslate" *ngIf="uiState.padlocked">lock</i>
                        <i class="material-icons notranslate" *ngIf="!uiState.padlocked">lock_open</i>
                    </div>
                </div> -->

                <div class="flex-grow-1 text-uppercase text-large line-height-1  overflow-hidden text-truncate">
                    {{property.name}}
                </div>

                <div *ngIf="properties.length>1" class="pr-2 pointer" (click)="displayProperties($event)">
                    <i class="material-icons notranslate ml-1 d-none d-md-block" *ngIf="!propertiesDisplayed">keyboard_arrow_left</i>
                    <i class="material-icons notranslate ml-1 d-none d-md-block" *ngIf="propertiesDisplayed">keyboard_arrow_down</i>
                </div>
            </div>
            
            <div id="menuPropertieListing" *ngIf="properties.length>1 && propertiesDisplayed" class="position-absolute shadowed bg-secondary px-2 px-md-3 py-3">
                <h5>PROPRIÉTÉS</h5>
                <ul class="list-unstyled m-0">
                    <ng-container *ngFor="let property of properties; trackBy: propertiesTrackFn" >
                        <li *ngIf="property.id != currentPropertyId" class="border-bottom border-bottom-last-none"> 
                            <a  class="d-flex text-white p-1 pointer" (click)="jumpToProperty(property.id)">
                                <!-- <i class="material-icons notranslate align-middle mr-1">keyboard_arrow_right</i> -->
                                {{ property.name }}
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </div>

            <ul id="mainNavigationMenuEntries" class="list-unstyled  m-0 p-0">
                <li class="px-2 px-md-3 pt-3 pb-2 nav-link pointer" routerLink="/property/{{currentPropertyId}}/infos" routerLinkActive="active">
                    <div class="d-md-flex align-items-center my-1">
                        <i class="material-icons notranslate mr-1 d-none d-md-block">description</i> INFOS GÉNÉRALES
                    </div>
                </li>
                <li class="px-2 px-md-3 py-2 nav-link pointer" routerLink="/property/{{currentPropertyId}}/cadaster" routerLinkActive #cadasterActive="routerLinkActive" [ngClass]="{'active': cadasterActive.isActive || cadasterInformationActive.isActive || cadasterNeighborhoodActive.isActive || cadasterEditActive.isActive}">
                    <a routerLink="/property/{{currentPropertyId}}/cadaster-information" routerLinkActive #cadasterInformationActive="routerLinkActive" style="display: none"></a>
                    <a routerLink="/property/{{currentPropertyId}}/cadaster-neighborhood" routerLinkActive #cadasterNeighborhoodActive="routerLinkActive" style="display: none"></a>
                    <a routerLink="/property/{{currentPropertyId}}/cadaster-edit" routerLinkActive #cadasterEditActive="routerLinkActive" style="display: none"></a>
                    <div class="d-md-flex align-items-center my-1">
                        <i class="material-icons notranslate mr-1 d-none d-md-block">dashboard</i> CADASTRE
                    </div>
                </li>
                <li class="px-2 px-md-3 py-2 nav-link pointer" routerLink="/property/{{currentPropertyId}}/stands" routerLinkActive #standsActive="routerLinkActive" [ngClass]="{'active': standActive.isActive || standsActive.isActive || standsGeometryActive.isActive}">
                    <a routerLink="/property/{{currentPropertyId}}/stand" routerLinkActive #standActive="routerLinkActive" style="display: none"></a>
                    <a routerLink="/property/{{currentPropertyId}}/stand-geometry" routerLinkActive #standsGeometryActive="routerLinkActive" style="display: none"></a>
                    <div class="d-md-flex align-items-center my-1">
                        <i class="material-icons notranslate mr-1 d-none d-md-block">nature</i> PARCELLES FORESTIÈRES
                    </div>
                </li>
                <li class="px-2 px-md-3 py-2 nav-link pointer" routerLink="/property/{{currentPropertyId}}/access" routerLinkActive="active">
                    <div class="d-md-flex align-items-center my-1">
                        <i class="material-icons notranslate mr-1 d-none d-md-block">explore</i> CARTOGRAPHIE
                    </div>
                </li>
                <!-- <li class="px-2 px-md-3 py-2 nav-link pointer" routerLink="/property/{{currentPropertyId}}/management-plans" routerLinkActive="active">
                    <div class="d-md-flex align-items-center my-1">
                        <i class="material-icons notranslate mr-1 d-none d-md-block">bar_chart</i> PLANS DE GESTION
                    </div>
                </li> -->
                <li class="px-2 px-md-3 py-2 nav-link pointer" routerLink="/property/{{currentPropertyId}}/cartography" routerLinkActive="active">
                    <div class="d-md-flex align-items-center my-1">
                        <i class="material-icons notranslate mr-1 d-none d-md-block">map</i> EDITION DE CARTES
                    </div>
                </li>
                <li class="px-2 px-md-3 py-2 nav-link pointer" routerLink="/property/{{currentPropertyId}}/documents" routerLinkActive="active">
                    <div class="d-md-flex align-items-center my-1">
                        <i class="material-icons notranslate mr-1 d-none d-md-block">import_export</i> EXPORTS
                    </div>
                </li>
                
                <li class="px-2 px-md-3 pt-2 pb-3 nav-link pointer" routerLink="/property/{{currentPropertyId}}/mandate" routerLinkActive="active" *ngIf="property && doesUserHaveGrantingPrivileges(property)">
                    <div class="d-md-flex align-items-center my-1">
                        <i class="material-icons notranslate mr-1 d-none d-md-block">people</i> PARTAGE D'ACCÈS
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <!-- Mobile header -->
    <nav id="navbarHeaderMobile" class="d-flex d-sm-none navbar-primary bg-gradient-secondary text-white">
        <div class="flex-grow-1">
            <a class="navbar-brand m-2 d-flex align-items-center" [routerLink]="['/dashboard']" *ngIf="isLoggedIn"></a> 
        </div>

        <button class="navbar-toggler d-block d-sm-none" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed" *ngIf="isLoggedIn">
            <i class="material-icons notranslate">menu</i>
        </button>
    </nav>

    <div [ngbCollapse]="isNavbarCollapsed" class="collapse bg-primary shadow" id="navbarHeaderMobileMenu" *ngIf="isLoggedIn">
        <ul class="list-unstyled text-white m-0 p-0">

            <li class="p-3 border-bottom" *ngIf="isConfirmed" [routerLink]="['/dashboard']" routerLinkActive="active" (click)="isNavbarCollapsed = !isNavbarCollapsed" >
                <i class="material-icons notranslate align-text-top mr-1">dashboard</i>
                TABLEAU DE BORD
            </li>
        </ul>

        <ul class="list-unstyled text-white m-0 p-0" *ngIf="currentPropertyId">
            <li class="p-1 pl-5 border-bottom" routerLink="/property/{{currentPropertyId}}/infos" routerLinkActive="active" (click)="isNavbarCollapsed = !isNavbarCollapsed">
                INFOS GÉNÉRALES
            </li>
            <li class="p-1 pl-5 border-bottom" routerLink="/property/{{currentPropertyId}}/cadaster" routerLinkActive="active" (click)="isNavbarCollapsed = !isNavbarCollapsed">
                CADASTRE
            </li>
            <li class="p-1 pl-5 border-bottom" routerLink="/property/{{currentPropertyId}}/stands" routerLinkActive="active" (click)="isNavbarCollapsed = !isNavbarCollapsed">
                PARCELLES FORESTIÈRES
            </li>
            <li class="p-1 pl-5 border-bottom" routerLink="/property/{{currentPropertyId}}/access" routerLinkActive="active" (click)="isNavbarCollapsed = !isNavbarCollapsed">
                CARTOGRAPHIE
            </li>
            <!-- <li class="p-1 pl-5 border-bottom" routerLink="/property/{{currentPropertyId}}/management-plans" routerLinkActive="active" (click)="isNavbarCollapsed = !isNavbarCollapsed">
                PLAN DE GESTION
            </li> -->
            <li class="p-1 pl-5 border-bottom" routerLink="/property/{{currentPropertyId}}/cartography" routerLinkActive="active" (click)="isNavbarCollapsed = !isNavbarCollapsed">
                EDITION DE CARTES
            </li>
            <li class="p-1 pl-5 border-bottom" routerLink="/property/{{currentPropertyId}}/mandate" routerLinkActive="active" (click)="isNavbarCollapsed = !isNavbarCollapsed"  *ngIf="property && doesUserHaveGrantingPrivileges(property)">
                PARTAGE D'ACCÈS
            </li>
        </ul>

        <ul class="list-unstyled text-white m-0 p-0">
            <li class="p-3 border-bottom" *ngIf="isConfirmed" [routerLink]="['/profile']" routerLinkActive="active" (click)="exitApp('profile')">
                <i class="material-icons notranslate align-text-top mr-1">account_circle</i>
                INFOS UTILISATEUR
            </li>
            <li class="p-3 bg-danger" (click)="exitApp()">
                <i class="material-icons notranslate align-text-top mr-1">arrow_back</i>
                QUITTER L'APPLICATION
            </li>
        </ul>
    </div>

</header>