<div id="map-wrapper">

    <div class="map-side-panel user-select-none" [class.opened]="sidePanelOpened" >

        <div *ngIf="displayableObjectsMenuDisplayed">
            <div class="p-3 font-weight-bold">
                COUCHES D'INFORMATION

                <div class="float-right">
                    <a class="pointer" (click)="displayDisplayableObjectsPanel()">
                        <i class="material-icons notranslate hover">cancel</i>
                    </a>
                </div>
            </div>

            <div class="layers-menu-wrapper pb-3">
                <div *ngFor="let dObject of displayableObjects">

                    <div class="px-3 py-2">

                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="{{dObject.id}}"
                                [checked]="dObject.selected" (change)="switchDisplayableObjectDisplay(dObject)">
                            <label class="custom-control-label" for="{{dObject.id}}">
                                {{dObject.name}}
                            </label>
                        </div>

                        <div *ngIf="dObject.selected && dObject.displayOpacity>-1">
                            <div class="row">
                                <div class="col-2 offset-1 pt-1">
                                    <small>Opacité</small>
                                </div>
                                <div class="col-8">
                                    <input class="small-range" type="range" min="0" max="100" step="10"
                                        value="{{dObject.displayOpacity}}" (input)="setDisplayableObjectOpacity(dObject,$event)">
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>

        <div *ngIf="layersMenuDisplayed">
            <div class="p-3 font-weight-bold">
                FONDS DE CARTE ACTIFS

                <div class="float-right">
                    <a class="pointer" (click)="displayLayersPanel()">
                        <i class="material-icons notranslate hover">cancel</i>
                    </a>
                </div>
            </div>

            <div class="layers-menu-wrapper pb-3">

                <div dndDropzone dndEffectAllowed="copyMove" (dndDrop)="onLayerDrop($event)">
                
                    <div *ngIf="!getSelectedLayers(true).length" class="px-3 text-small text-center font-italic">
                        Sélectionnez un fond ce carte ci-dessous
                    </div>
                    
                    <div dndPlaceholderRef class="bg-gray-100" style="height:5px;"> </div>

                    <div *ngFor="let layer of getSelectedLayers()"
                        class="d-flex align-items-stretch selected-layer-entry border-top border-bottom inner-shadow-top" 
                        [dndDraggable]="layer.id"
                        [dndEffectAllowed]="'move'"
                        [dndDraggingSourceClass]="'d-none'">
                        

                        <div class="flex-grow-1 px-3 py-2">
                            <div class="font-weight-bold cursor-pointer" (click)="setLayerVisibility(layer)">
                                <!-- <div class="float-right bg-danger font-weight-bold px-2">{{layer.index}}</div> -->

                                <i *ngIf="layer.displayOpacity>0" class="material-icons notranslate">visibility</i> 
                                <i *ngIf="layer.displayOpacity<1" class="material-icons notranslate">visibility_off</i> 
                                {{layer.name}}
                            </div>

                            <div>
                                <div class="d-flex">
                                    <div class="pt-1 pr-2">
                                        <small>Opacité</small>
                                    </div>
                                    <div class="flex-grow-1">
                                        <input class="small-range" type="range" min="10" max="100" step="10"
                                            value="{{layer.displayOpacity}}" (input)="setLayerOpacity(layer,$event)">
                                    </div>
                                </div>
                                <div class="row" *ngIf="layer.legende">
                                    <div class="col-12">
                                        <div class="mb-1">
                                            Légende : 
                                        </div>

                                        <img [src]="layer.legende" style="border:10px solid white">
                                    </div>
                                </div>
                            </div>
                            <div class="text-small pt-2">
                                <a href="javascript:void(0)" class="text-white" (click)="switchLayerGrayscale(layer)">
                                    <i class="material-icons notranslate text-small">brightness_medium</i> 
                                    <span *ngIf="!layer.grayscale">
                                        Noir & blanc
                                    </span>
                                    <span *ngIf="layer.grayscale">
                                        Couleur
                                    </span>
                                </a>

                                <a href="javascript:void(0)" class="float-right text-white" (click)="switchLayer(layer)">
                                    <i class="material-icons notranslate text-small">delete</i> Supprimer
                                </a>
                            </div>

                        </div>
                        
                        <div [class]="{'d-none':getSelectedLayers().length==1,'d-flex':getSelectedLayers().length>1}" class="align-items-center bg-secondary px-1 cursor-row-resize" dndHandle>
                            <span class="material-icons notranslate">drag_handle</span>
                        </div>

                    </div>
                </div>


                <div class="px-3 pt-3 pb-1 font-weight-bold">
                    PLUS DE FONDS DE CARTE
                </div>

                <div *ngFor="let libLayer of getlayersLibrary()">
                    <div *ngIf="!isLayerLibraryCategory(libLayer)" class="px-3 py-2 cursor-pointer" [class.font-weight-bold]="libLayer.selected" (click)="switchLayer(libLayer)">
                        <i *ngIf="!libLayer.selected" class="material-icons notranslate text-gray-600">add_circle</i>
                        <i *ngIf="libLayer.selected" class="material-icons notranslate">remove_circle</i>
                        {{libLayer.name}}
                    </div>

                    <div *ngIf="isLayerLibraryCategory(libLayer) && hasLayerLibraryAvalaibleContent(libLayer)" class="px-3 py-2 layer-category font-weight-bold cursor-pointer" (click)="switchLibraryCategoryDeploy(libLayer)">
                        <div class="float-right">
                            <i *ngIf="libLayer.deployed" class="material-icons notranslate">keyboard_arrow_up</i>
                            <i *ngIf="!libLayer.deployed" class="material-icons notranslate">keyboard_arrow_down</i>
                        </div>
                        {{libLayer.categoryLabel}}
                    </div>

                    <div *ngIf="isLayerLibraryCategory(libLayer) && hasLayerLibraryAvalaibleContent(libLayer) && libLayer.deployed" class="layer-category-collection inner-shadow-top border-bottom">
                        <ng-container *ngFor="let libSubLayer of getlayersLibraryCategoryCollection(libLayer)">
                            <div class="px-3 py-2 cursor-pointer" [class.font-weight-bold]="libSubLayer.selected" (click)="switchLayer(libSubLayer)">
                                <i *ngIf="!libSubLayer.selected" class="material-icons notranslate text-gray-600">add_circle</i>
                                <i *ngIf="libSubLayer.selected" class="material-icons notranslate">remove_circle</i>
                                {{libSubLayer.name}}
                            </div>
                        </ng-container>
                    </div>

                </div>

            </div>
        </div>

    </div>

    <div id="map-main-view">

        <div leaflet (leafletMapReady)="onMapReady($event)" [leafletOptions]="mapOptions" [leafletLayers]="layers"
            (leafletClick)="onMapClick($event)" (leafletDoubleClick)="onMapDoubleClick($event)"
            (leafletMapMove)="onMapMove($event)"
            (leafletMapMoveEnd)="onMapMoveEnd($event)"
            (leafletMapZoomEnd)="onMapZoomEnd($event)"
            >
            <!-- [leafletFitBounds]="mapBounds" -->
        </div>


    </div>

    <ng-content></ng-content>

    <nav class="map-toolbar {{mapToolBarPosClass}} user-select-none">

        <div class="map-toolbar-buttons-line" [ngClass]="{'d-flex':mapToolBarPos!='bottom'}">
            
            <div ngbTooltip="Fonds de carte" placement="right" class="map-toolbar-button square-control mr-2 mb-2" (click)="displayLayersPanel()">
                <svg x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve">
                    <g>
                        <path fill="currentColor"
                            d="M16,8l-8,5l2.4,1.5L8,16l2.4,1.5L8,19l8,5l8-5l-2.4-1.5L24,16l-2.4-1.5L24,13L16,8z M22.1,19L16,22.8L9.9,19 l1.4-0.9L16,21l4.7-2.9L22.1,19z M22.1,16L16,19.7L9.9,16l1.4-0.9L16,18l4.7-2.9L22.1,16z M16,16.7L9.9,13L16,9.2l6.1,3.8L16,16.7z ">
                        </path>
                    </g>
                </svg>
            </div>
            
            <div class="d-flex flex-grow-1" *ngIf="!preventMeasuringOnMap">
                <div *ngIf="property" ngbTooltip="Obtenir des informations pour un point de la carte" placement="right" class="map-toolbar-button square-control mr-2 mb-2"
                    (click)="activateQueryPoint()" [ngClass]="{'active':isQueryingPoint}">
                    <i class="material-icons notranslate">info</i>
                </div>

                <div ngbTooltip="Mesure de distance ou de surface" placement="right" class="map-toolbar-button square-control mr-2 mb-2"
                    (click)="activateMeasureOnMap()" [ngClass]="{'active':isMeasuringOnMap}">
                    <i class="material-icons notranslate">straighten</i>
                </div>
                <div>
                    <div class="square-control-info-box active text-small line-height-18 px-3" *ngIf="isMeasuringOnMap">
                        {{measureOnMapMessage}}
                    </div>
                </div>
            </div>
        </div>

        <div ngbTooltip="Couches d'information" placement="right" class="map-toolbar-button square-control mr-2 mb-2" (click)="displayDisplayableObjectsPanel()" *ngIf="displayableObjects && displayableObjects.length">
            <i class="material-icons notranslate">rule</i>
        </div>

        <div class="square-control-wrapper d-none d-md-block" *ngIf="zoomEnabled">
            <div ngbTooltip="Zoomer" placement="right" class="square-control top" (click)="zoomMap(true)"><i
                    class="material-icons notranslate">zoom_in</i></div>
            <div *ngIf="property" ngbTooltip="Recadrer sur la propriété" placement="right" class="square-control" (click)="zoomOnProperty()"><i
                    class="material-icons notranslate">gps_fixed</i></div>
            <div ngbTooltip="Dézoomer" placement="right" class="square-control bottom" (click)="zoomMap(false)"><i
                    class="material-icons notranslate">zoom_out</i></div>
        </div>

    </nav>

    <div class="screen-splitter-controls square-control-wrapper">
        <div ngbTooltip="Réduire la carte" placement="right" class="square-control top ctrl-thirty-seventy"
            (click)="setScreenSplitterHeight('thirty-seventy')"><i class="material-icons notranslate">expand_less</i></div>

        <div ngbTooltip="Agrandir la carte" placement="right" class="square-control top ctrl-fifty-fifty-bottom"
            (click)="setScreenSplitterHeight('fifty-fifty')"><i class="material-icons notranslate">expand_more</i></div>

        <div ngbTooltip="Réduire la carte" placement="right" class="square-control top ctrl-fifty-fifty-top"
            (click)="setScreenSplitterHeight('fifty-fifty')"><i class="material-icons notranslate">expand_less</i></div>

        <div ngbTooltip="Agrandir la carte" placement="right" class="square-control bottom ctrl-seventy-thirty"
            (click)="setScreenSplitterHeight('seventy-thirty')"><i class="material-icons notranslate">expand_more</i></div>
    </div>

    <div class="popover fade show bs-popover-top no-lot-at-position-popover" role="tooltip" x-placement="top"
        (click)="messageAtPos = undefined"
        [ngStyle]="messageStyle">
        <div class="arrow" style="left: 124px;"></div>
        <h3 class="popover-header"></h3>
        <div class="popover-body text-danger">
            <i class="material-icons notranslate float-left mt-2 mr-2">not_interested</i>
            {{messageString}}
        </div>
    </div>
</div>


<ng-template #queryPointInfosModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{queriedPoint}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="!queriedPointData.length">
            Aucune donnée répertoriée pour ce point
        </ng-container>

        <ng-container *ngFor="let feature of queriedPointData">
            <ng-container [ngSwitch]="feature.type">
                <div *ngSwitchCase="'forestry_management'" class="mb-2 pb-2 border-bottom">
                    <div class="font-weight-bold text-uppercase">Triage DNF</div>
                    {{feature.name}}
                    <div>
                        Cantonnement de {{feature.canton_name}}
                    </div>
                    <div>
                        Tel : {{feature.canton_phone}}
                    </div>
                </div>

                <div *ngSwitchCase="'game_management'" class="mb-2 pb-2 border-bottom">
                    <div class="font-weight-bold text-uppercase">Conseil cynégétique </div>
                    {{feature.name}} ({{feature.code}})
                </div>

                <div *ngSwitchCase="'pedology'" class="mb-2 pb-2 border-bottom">
                    <div class="font-weight-bold text-uppercase">Pédologie</div>
                    {{feature.name}}
                    
                    <div *ngIf="feature.substrat && feature.substrat.trim()">
                        Substrat : {{feature.substrat}}
                    </div>
                    <div *ngIf="feature.texture && feature.texture.trim()">
                        Texture : {{feature.texture}}
                    </div>
                    <div *ngIf="feature.drainage && feature.drainage.trim()">
                        Drainage : {{feature.drainage}}
                    </div>
                    <div *ngIf="feature.developpement_de_profil && feature.developpement_de_profil.trim()">
                        Développement de profil : {{feature.developpement_de_profil}}
                    </div>
                    <div *ngIf="feature.symbologie && feature.symbologie.trim()">
                        Symbologie : {{feature.symbologie}}
                    </div>
                    <div *ngIf="feature.code && feature.code.trim()">
                        Code : {{feature.code}}
                    </div>
                </div>
                
                <div *ngSwitchCase="'hydrology'" class="mb-2 pb-2 border-bottom">
                    <div class="font-weight-bold text-uppercase">Cours d'eau</div>
                    {{feature.name}}

                    <div *ngIf="feature.category && feature.category.trim()">
                        Catégorie : {{feature.category}}
                    </div>
                    
                    <div *ngIf="feature.bassin_principal && feature.bassin_principal.trim()">
                        Bassin principal : {{feature.bassin_principal}}
                        <span *ngIf="feature.bassin_principal_id">({{feature.bassin_principal_id}})</span>
                    </div>
                    <div *ngIf="feature.bassin_gestion && feature.bassin_gestion.trim()">
                        Bassin de gestion : {{feature.bassin_gestion}}
                        <span *ngIf="feature.bassin_gestion_id">({{feature.bassin_gestion_id}})</span>
                    </div>
                </div>

                <div *ngSwitchCase="'bioclim'" class="mb-2 pb-2 border-bottom">
                    <div class="font-weight-bold text-uppercase">Zone bioclimatique</div>
                    {{feature.name}}
                    
                    <!-- <div>
                        Code : {{feature.code}}
                    </div> -->
                </div>
                
                <div *ngSwitchCase="'natura'" class="mb-2 pb-2 border-bottom">
                    <div class="font-weight-bold text-uppercase">Emprise Natura 2000</div>
                    {{feature.name}} ({{feature.code}})
                    
                    <div>
                        Code site : {{feature.code_site}}
                    </div>
                    <div>
                        Code unique : {{feature.code_unique}}
                    </div>
                    <div>
                        Infos : <a href="{{feature.link}}" target="_blank">{{feature.link}}</a>
                    </div>
                </div>
                
                <div *ngSwitchCase="'classified'" class="mb-2 pb-2 border-bottom">
                    <div class="font-weight-bold text-uppercase">Site classé</div>
                    {{feature.name}}
                    
                    <div>
                        Code : {{feature.code}}
                    </div>
                    <div>
                        Type : {{feature.lawType}}
                    </div>
                    <div>
                        Historique : {{feature.history}}
                    </div>
                    <div>
                        Date : {{feature.lawDate|date:'long'}}
                    </div>
                </div>

                <div *ngSwitchCase="'zoning_area'" class="mb-2 pb-2 border-bottom">
                    <span class="font-weight-bold text-uppercase">{{getZoningAreaTypeLabel(feature.zoningType)}} : </span> {{feature.name}}
                    
                    <div>
                        Code plan de secteur : {{feature.code}}
                    </div>
                    <div>
                        Réglementation locale : {{feature.lawTerritory}}
                    </div>
                    <div>
                        Réglementation urbanisme : {{feature.lawUrba}}
                    </div>
                </div>
             </ng-container>
        </ng-container>
    </div>
    
</ng-template>