import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from '@mf-framework/core-service';
import { takeUntil, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { Property } from '@mf-framework/model/Property/property.typing';


@Component({
    selector: 'maforet-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss'],
    // animations: [
    //     trigger('smoothCollapse', [
    //         state('initial', style({
    //             height: '0',
    //             overflow: 'hidden',
    //         })),
    //         state('final', style({
    //             height: '392px',
    //             overflow: 'hidden',
    //         })),
    //         transition('initial=>final', animate('150ms')),
    //         transition('final=>initial', animate('150ms'))
    //     ]),
    // ]
})
export class RootComponent implements OnInit {
    // private _destroyed$ = new Subject();
    // public isNavbarCollapsed = true;
    // property: Property

    constructor(
        // public coreService: CoreService,
        // private route: ActivatedRoute,
        // config: NgbTooltipConfig
    ) {
        // config.openDelay = 500;
        // config.triggers = "hover";
    }

    ngOnInit() {
        // this.route.params
        //     .pipe(
        //         switchMap(routeParams =>
        //             this.coreService.Selector$({ type: 'selectProperty', payload: { id: routeParams.id } })
        //         ),
        //         takeUntil(this._destroyed$)
        //     )
        //     .subscribe(res => {
        //         this.property = res
        //     })
    }

    // public ngOnDestroy(): void {
    //     this._destroyed$.next();
    //     this._destroyed$.complete();
    // }
}
