import { Injectable } from '@angular/core';
import { Observable, merge, of, throwError } from 'rxjs'
import { map, filter, switchMap, tap, mergeMap, catchError } from 'rxjs/operators'

import { AppStore } from '../../application-store'
import { UiService } from '@mf-framework/ui/ui.service';
import { AppModalService } from '@mf-framework/interface/app-modal/app-modal.service';
import { ForecastsFetcher } from './forecasts.communication';

@Injectable({
    providedIn: 'root'
})
export class ForecastsService {
    constructor(
        public appStore: AppStore,
        public uiService: UiService,
        public forecastsFetcher: ForecastsFetcher,
        public appModalService: AppModalService,
    ) { }
        
    getStandForecast$(payload){
        return this.forecastsFetcher.getStandForecast(payload.propertyId,payload.standId,payload.lastYear)
    }
}