import {Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, NgZone, AfterViewInit} from '@angular/core';
import {Subject, combineLatest} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CoreService} from '@mf-framework/core-service';
import {ActivatedRoute, Router} from '@angular/router';
import {Property} from '@mf-framework/model/Property/property.typing';
import {take} from 'rxjs/operators';
import * as L from 'leaflet';
import {getContactName} from '@mf-framework/model/Contacts/utils';
import * as turfhelpers from '@turf/helpers';
import turfcentroid from '@turf/centroid';
import {cleanStringForSearch} from '@mf-framework/utils/strings';
import {sortProperties} from '@mf-framework/model/Properties/utils';
import {doesUserHaveGrantingPrivileges, getPropertyArea, getPropertyOwners} from '@mf-framework/model/Property/utils';

@Component({
  selector: 'maforet-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
  , changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    public coreService: CoreService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone
  ) {
  }
  private destroyed$ = new Subject();
  properties: Property[];
  filteredProperties: Property[];
  references;

  screenSplitterHeight = 'fifty-fifty';

  deletionPropertyName = '';

  map: L.Map;
  mapLayers = [];
  mapInitialized = false;

  componentInitialized = false;

  componentDataInitialized = false;

  initialized = false;

  propertiesLayerGroup;


  ngOnInit() {
    combineLatest([
      this.coreService.Selector$({type: 'references'}).pipe(take(1)),
      this.coreService.Selector$({type: 'selectProperties'})
    ])
      .subscribe(results => {
        this.references = results[0];
        this.properties = sortProperties(results[1].collection);

        this.filteredProperties = this.properties;

        this.componentDataReady();
        this.cd.markForCheck();
      }, error => {
        console.log(error);
      });
  }

  doesUserHaveGrantingPrivileges(property) {
    return doesUserHaveGrantingPrivileges(property);
  }

  filterProperties(event) {
    const str = cleanStringForSearch(event.target.value);
    if (str.trim().length) {
      this.filteredProperties = this.properties.filter(p => {
        if (cleanStringForSearch(p.name).includes(str)) {
          return true;
        }
        if (cleanStringForSearch(p.locality.name).includes(str)) {
          return true;
        }
        const owners = this.getPropertyOwners(p);
        for (let i = 0, ii = owners.length; i < ii; i++) {
          if (cleanStringForSearch(this.getContactName(owners[i])).includes(str)) {
            return true;
          }
        }
      });
    } else {
      this.filteredProperties = this.properties;
    }
  }

  propertiesTrackFn = (i, property) => property.id;

  getPropertyCurrentArea(property) {
    return getPropertyArea(property);
  }

  getPropertyOwners(property) {
    return getPropertyOwners(property);
  }

  getContactName(contact) {
    return getContactName(contact);
  }

  propertyDeleteConfirmCall(content, property) {
    this.deletionPropertyName = property.name;
    this.modalService.open(content, {centered: true})
      .result.then((result) => {
      this.deleteProperty(property);
    }, (reason) => {
    });
  }

  deleteProperty(property) {
    this.coreService.Reducer$({type: 'deleteProperty', payload: {id: property.id}})
      .subscribe(res => {
        this.displayProperties();
      });
  }

  withdrawMandateConfirmCall(content, property) {
    this.deletionPropertyName = property.name;
    this.modalService.open(content, {centered: true})
      .result.then((result) => {
      this.withdrawMandate(property);
    }, (reason) => {
    });
  }

  withdrawMandate(property) {
    this.coreService.Reducer$({
      type: 'propertyRevokeMandate'
      , payload: {
        propertyId: property.id,
        mandateId: property.userMandate.id,
        memoryRemove: true
      }
    })
      .subscribe(res => {
        this.displayProperties();
      });
  }

  screenSplitterHeightSetted(className) {
    this.screenSplitterHeight = className;
  }

  mapReady(map) {
    this.map = map;
    this.mapInitialized = true;
    this.init();
  }

  ngAfterViewInit(): void {
    this.componentInitialized = true;
    this.init();
  }

  componentDataReady() {
    this.componentDataInitialized = true;
    this.init();
  }

  init() {
    if (!this.mapInitialized || !this.componentDataInitialized || !this.componentInitialized || this.initialized) {
      return;
    }
    this.initialized = true;

    this.displayProperties();
  }

  displayProperties() {
    const geoJson = turfhelpers.featureCollection([]);
    const envelopesJson = turfhelpers.featureCollection([]);
    this.properties.forEach(property => {
      let centroid;
      let envelope;
      if (property.envelope) {
        centroid = turfcentroid(property.envelope);
        envelope = property.envelope;

      } else if (property.locality.envelope) {
        centroid = turfcentroid(property.locality.envelope);
        envelope = property.locality.envelope;
      }

      if (centroid) {
        geoJson.features.push({
          type: 'Feature',
          properties: {
            id: property.id,
            name: property.name
          },
          geometry: centroid.geometry
        });
      }
      if (envelope) {
        envelopesJson.features.push({
          type: 'Feature',
          properties: {
            id: property.id,
            name: property.name
          },
          geometry: envelope
        });
      }
    });

    const geo = L.geoJSON(geoJson as any, {
      pointToLayer: (feature, latlng) => {
        return L.marker(latlng, {
          pane: 'front',
          icon: L.divIcon({
            className: 'placeIcon white scaleMapIcon',
            html: '<div class="mapIconWrapper"><i class="material-icons notranslate align-middle">nature</i><div>',
            iconSize: [24, 24],
          }),
          riseOnHover: true,
          bubblingMouseEvents: false,
        });
      },
      onEachFeature: (feature, layer) => {
        layer.on({
          click: (e) => {
            this.ngZone.run(() => {
              this.jumpToProperty(feature.properties.id);
            });
          },
        });
        layer.bindTooltip(
          `${feature.properties.name.toUpperCase()}`,
          {
            pane: 'tools',
            offset: [0, -20],
            direction: 'top',
            sticky: true,
            opacity: .7
          }
        ).openTooltip();
      }
    });

    if (!this.propertiesLayerGroup) {
      this.propertiesLayerGroup = L.layerGroup([geo]);
      this.propertiesLayerGroup.addTo(this.map);
    }

    this.propertiesLayerGroup.clearLayers();
    this.propertiesLayerGroup.addLayer(geo);


    const envelopeGeo = L.geoJSON(envelopesJson as any);
    let bounds = envelopeGeo.getBounds();

    if (bounds.isValid()) {
      this.map.flyToBounds(bounds, {padding: [30, 30], animate: false});
    } else {
      bounds = geo.getBounds();
      if (bounds.isValid()) {
        this.map.flyToBounds(bounds, {padding: [30, 30], animate: false});
      }
    }
  }

  jumpToProperty(id) {
    const path = ['../property', id];
    const property = this.properties.find(p => p.id === id);
    if (this.getPropertyCurrentArea(property)) {
      path.push('cadaster');
    }
    this.router.navigate(path, {relativeTo: this.route});
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}



