import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, filter, take, switchMap, mapTo, map, mergeMap } from 'rxjs/operators';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { environment } from '@current-environment/environment';

// function parseErrorBlob(err: HttpErrorResponse): Observable<any> {
//     const reader: FileReader = new FileReader();

//     const obs = Observable.create((observer: any) => {
//       reader.onloadend = (e) => {
//         observer.error(JSON.parse(<any>reader.result));
//         observer.complete();
//       }
//     });
//     reader.readAsText(err.error);
//     return obs;
// }

// function hasAuthentErrorReasonCode(error) {
//     let isAuthError = false
//     if(error.error && error.error["reason-code"]) {
//         let reasonCode = error.error["reason-code"]
//         const AuthErrorReasonCodes = [
//             '3211ecae-411e-400a-967d-f32d03f0cf05', // expired token: jeton périmé
//             "a701c75d-914d-4b09-994d-6c01aaafff41", // jeton absent de l’entête
//             'ff242b52-76ca-4657-837f-16498a01fc1b', // invalid token: jeton mal encodé
//         ]
//         if(AuthErrorReasonCodes.includes(reasonCode)) {
//             isAuthError = true
//         }
//     }
//     return isAuthError
// }

// function shouldRefreshToken(request,error) {
//     return  error instanceof HttpErrorResponse 
//             && error.status === 401 
//             && request.url != endpointsUrls.user.refreshToken
//             && request.url != endpointsUrls.user.userLogout
// }

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    isRefreshing = false;
    refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(public authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if (this.authService.getJwtToken()) {
        //     request = this.addToken(request, this.authService.getJwtToken());
        // }
        
        return next.handle(request).pipe(
            catchError(error => {
                if(request.responseType == "blob") {
                    if (this.shouldRefreshToken(request,error)) {
                        return this.handle401Error(request, next)
                    } else {
                        return throwError(error);
                    }
                    // return parseErrorBlob(error).pipe(
                    //     mergeMap(jerror => {
                    //         if (this.hasAuthentErrorReasonCode({error:jerror}) && this.shouldRefreshToken(request,error)) {
                    //             return this.handle401Error(request, next)
                    //         } else {
                    //             return throwError(jerror);
                    //         }
                    //     })
                    // )
                }
                
                if (this.hasAuthentErrorReasonCode(error) && this.shouldRefreshToken(request,error)) {
                    return this.handle401Error(request, next)
                } else {
                    return throwError(error);
                }
            })
        );
    }

    shouldRefreshToken(request: HttpRequest<any>, error) {
        return  error instanceof HttpErrorResponse 
                && error.status === 401 
                && request.url != endpointsUrls.user.refreshToken
                && request.url != endpointsUrls.user.userLogout
    }

    hasAuthentErrorReasonCode(error) {
        let isAuthError = false
        if(error.error && error.error["reason-code"]) {
            let reasonCode = error.error["reason-code"]
            const AuthErrorReasonCodes = [
                '3211ecae-411e-400a-967d-f32d03f0cf05', // expired token: jeton périmé
                "a701c75d-914d-4b09-994d-6c01aaafff41", // jeton absent de l’entête
                'ff242b52-76ca-4657-837f-16498a01fc1b', // invalid token: jeton mal encodé
            ]
            if(AuthErrorReasonCodes.includes(reasonCode)) {
                isAuthError = true
            }
        }
        return isAuthError
    }

    addToken(request: HttpRequest<any>, token: string) {
        if(environment.sessionManagement.type == "clientside"){
            return request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${token}`
                }
            })
        }
        return request.clone()
    }

    handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    if(environment.sessionManagement.type == "clientside"){
                        this.refreshTokenSubject.next(token.token);
                        return next.handle(this.addToken(request, token.token))
                    }
                    else{
                        this.refreshTokenSubject.next("serverSide");
                        return next.handle(request.clone())
                    }
                }),
                catchError((error) => {
                    console.log("401 response in refresh token call")
                    // alert("Votre session a expiré. Merci de vous identifier à nouveau")
                    this.authService.dryLogout()
                    
                    if(environment.sessionManagement.type == "clientside"){
                        if(window.location.pathname && window.location.pathname!="/home"){
                            window.location.replace("/home")
                        }
                    }
                    else{
                        window.location.replace(environment.outgoingUrls.login)
                        // if(window.location.pathname && window.location.pathname!="/home"){
                        //     window.location.replace("/home")
                        // }
                    }
                    return throwError(error);
                })
            );

        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    // return next.handle(this.addToken(request, token));
                    if(environment.sessionManagement.type == "clientside"){
                        return next.handle(this.addToken(request, token))
                    }
                    else{
                        return next.handle(request.clone())
                    }
                }));
        }
    }
}