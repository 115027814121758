export function formatSiArea(area:number, unit?:string, string?:string){
    unit = unit || "ha"
    string = string || ""

    switch (unit) {
        case 'ha':
            var int = Math.floor(area / 10000)
            string += int + ' ' + unit + ' '
            area = area % 10000
            unit = 'a'
        break;
        
        case 'a':
            var int = Math.floor(area / 100)
            if(int > 0) {
                string += padLeft('00', int) + ' ' + unit + ' '
            }
            area = area % 100
            unit = 'ca'
        break;
        
        case 'ca':
            var int = Math.floor(area / 1)
            if (int > 0) {
                string += padLeft('00', int) + ' ' + unit
            }
            unit = null;
        break;
        
        default:
            console.log('Unknown unit : ', unit)
    }

    if (unit !== null) {
        return formatSiArea(area, unit, string)
    }

    return string
}

export function padLeft(paddingString, str) {
    str = str + "";
    return paddingString.substring(0, paddingString.length - str.length) + str;
}