import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'
import { State, initialState } from './application-state'
import { tap } from 'rxjs/operators';
import { environment } from '@current-environment/environment'

const store = new BehaviorSubject<State>(initialState);

@Injectable({
    providedIn: 'root'
})
export class AppStore {

    readonly _store = store;

    readonly store$ = this._store.asObservable()
    // .pipe(tap(changes => {
    //     console.log('state subscribe', changes)
    // }))

    // getObservableState() {
    //     return this._store.asObservable()
    // }

    getState() {
        return this._store.getValue()
    }
    setState(state: State, storeLocally?) {
        this._store.next(state)
    }
}