import { Injectable } from '@angular/core';
import { AppStore } from '../../application-store'
import { TRUNKSIZE_MEASUREMENT_CIR, VOLUME_UNIT_M3, TrunkSizeMeasurement, VolumeUnit, VolumeMeasurement, VOLUME_MEASUREMENT_WOOD } from '../Stands/Dendrometrics/dendrometrics.typing';
import mergeDeep, { clone } from '@mf-framework/utils/objects';
import { MEASUREINPUTLEVEL_STAND_AVERAGE, measureInputLevel } from '../Stands/stand.typing';
import { scaling_formulas } from '@mf-framework/utils/scaling-methods';

export interface Preferences {
    stands : {
        measureInputLevel : measureInputLevel
        dendrometrics : {
            trunkSize : {
                measurement : TrunkSizeMeasurement
            }
            volume : {
                unit : VolumeUnit
                measurement : VolumeMeasurement
            }
        }
    }
    scalingMethod : scaling_formulas
    scalingRate : number
}

export const PREFERENCES = {
    stands : {
        measureInputLevel : MEASUREINPUTLEVEL_STAND_AVERAGE,
        dendrometrics : {
            trunkSize : {
                measurement : TRUNKSIZE_MEASUREMENT_CIR
            },
            volume : {
                unit : VOLUME_UNIT_M3,
                measurement : VOLUME_MEASUREMENT_WOOD
            },
        },
    },
    scalingMethod : undefined,
    scalingRate : undefined
}

@Injectable({
    providedIn: 'root'
})
export class PropertyPreferencesService {
    constructor(
        private appStore: AppStore,
        // private customDataService: CustomDataService
    ) {}

    customDataService
    instantiateCustomDataService(customDataService){
        this.customDataService = customDataService
    }

    definePreferencesGetter(propertyData) {
        Object.defineProperty(propertyData, 'preferences', {
            get : () => {return this.getPreferences(propertyData.id)},
            enumerable: true
        });
    }

    getPreferences(propertyId){
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === propertyId)
        let customPreferences = property.customData.find(k => {return k.key == "preferences"})
        if(customPreferences){
            return mergeDeep(clone(PREFERENCES),JSON.parse(customPreferences.value))
        }
        return PREFERENCES
    }

    async setPreference(payload){
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        let customPreferences = property.customData.find(k => {return k.key == "preferences"})

        let refStructure = {}
        payload.key.split(".").reduce(
            (accumulator,value,index,array) => {
                if(index==array.length-1)accumulator[value] = payload.value
                else {
                    accumulator[value] = {}
                    return accumulator[value]
                }
            }, 
            refStructure
        )

        if(!customPreferences){
            const Json = JSON.stringify(refStructure)
            return await this.customDataService.addCustomDataKey({
                propertyId: payload.propertyId,
                data : {
                    key : "preferences",
                    value : Json
                }
            })
        }
        else{
            const Json = JSON.stringify(mergeDeep(JSON.parse(customPreferences.value),refStructure))
            return await this.customDataService.patchCustomDataKey({
                propertyId: payload.propertyId,
                key : "preferences",
                data : {
                    value : Json
                }
            })
        }
        
    }
}

