import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GameDamage } from './gameDamage.typing';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';

const PARAMS = new HttpParams({
    fromObject: {
        "extra_fields": ""
    }
})

@Injectable({
    providedIn: 'root',
})
export class GameDamagesFetcher {
    constructor(private http: HttpClient) { }

    create(data) {
        let url =  endpointsUrls.property.gameDamage
        return this.http.post<GameDamage>(url, data,{params : PARAMS})
    }

    delete(gameDamageId) {
        let url =  `${endpointsUrls.property.gameDamage}/${gameDamageId}`
        return this.http.delete(url)
    }

    patch(gameDamageId: string, data) {
        let url =  `${endpointsUrls.property.gameDamage}/${gameDamageId}`
        return this.http.patch<GameDamage>(url, data, {params : PARAMS})
    }
}