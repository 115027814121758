import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@current-environment/environment'
import { NgbDateStructToIsoDate } from '@mf-framework/utils/date';
import { mergeMap, map, toArray, tap } from 'rxjs/operators';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';

@Injectable({
    providedIn: 'root',
})
export class LocalizationCommunication {
    // private readonly apiCadxmapBeUrl = environment.apiCadxmapBe
    // private readonly apiCadxmapBeUrl = endpointsUrls.cadxmap.be

    constructor(private http: HttpClient) { }

    lotIntersects(countryCode,latlng) {
        const PARAMS = new HttpParams({
            fromObject: {
              "extra_fields": "country,geometry,locality"
            }
        })

        let cadxmapUrl = endpointsUrls.cadxmap[countryCode.toLowerCase()]

        return this.http.post<any>(
            `${cadxmapUrl}/lot/intersects`,
            {
                "type": "Point",
                "coordinates": [latlng.lng, latlng.lat]
            },
            {params:PARAMS}
        )
    }

    featuresIntersects(countryCode,latLng) {
        let cadxmapUrl = endpointsUrls.cadxmap[countryCode.toLowerCase()]
        return this.http.post<any>(
            `${cadxmapUrl}/features/intersects`
            ,
            {
                "geometry" : {
                    "type": "Point",
                    "coordinates": [latLng.lng, latLng.lat]
                },
                "features": ["pedology", "natura", "forestry_management", "game_management", "classified" ,"bioclim", "zoning_area","hydrology"],
                "includeGeometry": false,
                "intersectionArea": false
            }
        )
    }

    findCityByString(countryCode,text,level1?) {
        let queryVar = `name:${text}%`
        if(level1) {
            queryVar += `,level1:${level1}`
        }

        let PARAMS = new HttpParams({
            fromObject: {
              "q": queryVar,
              "limit": "20",
              "extra_fields": "country,level1,level2,level3",
            }
        })

        let cadxmapUrl = endpointsUrls.cadxmap[countryCode.toLowerCase()]
        return this.http.get<any>(`${cadxmapUrl}/locality/search`,{params:PARAMS})
        .pipe(
            mergeMap(Response => {
                if(Response.collection)return Response.collection
                return Response
            }),
            // map((result:{name:string,freName:string}) => {
            //     if(!result.name)result.name = result.freName
            //     return result
            // }),
            toArray()
        )
    }

    queryCadastralDivisionsByCityCode(countryCode,localityCode) {
        let cadxmapUrl = endpointsUrls.cadxmap[countryCode.toLowerCase()]
        const PARAMS = new HttpParams({
            fromObject: {
                "locality": localityCode
            }
        })

        return this.http.get<any>(`${cadxmapUrl}/lot/divisions`,{params:PARAMS})
    }

    queryCadastralSectionsByDivisonCode(countryCode,divisionCode) {
        let cadxmapUrl = endpointsUrls.cadxmap[countryCode.toLowerCase()]
        const PARAMS = new HttpParams({
            fromObject: {
                "division": divisionCode
            }
        })

        return this.http.get<any>(`${cadxmapUrl}/lot/sections`,{params:PARAMS})
    }

    queryCadastralSectionsByCityCode(countryCode,localityCode) {
        let cadxmapUrl = endpointsUrls.cadxmap[countryCode.toLowerCase()]
        const PARAMS = new HttpParams({
            fromObject: {
                "locality": localityCode
            }
        })

        return this.http.get<any>(`${cadxmapUrl}/lot/sections`,{params:PARAMS})
    }

    queryCadastralNumbersBySectionCode(countryCode,sectionCode) {
        let cadxmapUrl = endpointsUrls.cadxmap[countryCode.toLowerCase()]
        const PARAMS = new HttpParams({
            fromObject: {
                "section": sectionCode
            }
        })

        return this.http.get<any>(`${cadxmapUrl}/lot/numbers`,{params:PARAMS})
    }

    queryLotByCode(countryCode,lotCode) {
        let cadxmapUrl = endpointsUrls.cadxmap[countryCode.toLowerCase()]
        const PARAMS = new HttpParams({
            fromObject: {
                "extra_fields": "country,geometry,centroid,locality"
                // "code" : lotCode,
            }
        })
        return this.http.get<any>(`${cadxmapUrl}/lot/${lotCode}`,{params:PARAMS})
    }
}
