import { Injectable } from '@angular/core';
import { throwError } from 'rxjs'

import { AppStore } from '../../application-store'
import mergeDeep from '@mf-framework/utils/objects';
import { PlannedOperationsFetcher } from './plannedOperations.communication';


@Injectable({
    providedIn: 'root'
})
export class PlannedOperationsService {
    constructor(
        private appStore: AppStore,
        private plannedOperationsFetcher: PlannedOperationsFetcher,
    ) { }

    async addPlannedOperation(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const index = state.model.properties.collection.indexOf(property)
            return await this.plannedOperationsFetcher.create(payload)
            .toPromise()
            .then(
                newPlannedOperation => {
                    state.model.properties.collection[index] = {
                        ...property,
                        plannedOperations : [
                            ...property.plannedOperations,
                            newPlannedOperation
                        ]
                    }

                    this.appStore.setState(state)
                    return newPlannedOperation
                }
            )
        }
        else {throw `addPlannedOperation : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchPlannedOperation(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const plannedOperationRef = property.plannedOperations.find(o => o.id == payload.elementId)
            const plannedOperationRefIndex = property.plannedOperations.indexOf(plannedOperationRef)
            
            state.model.properties.collection[index].plannedOperations[plannedOperationRefIndex] = {
                ...plannedOperationRef,
                ...mergeDeep(plannedOperationRef,payload.data)
            }

            this.appStore.setState(state)

            try {
                return await this.plannedOperationsFetcher.patch(payload.elementId,payload.data).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {
            throw `patchPlannedOperation : Unidentified Property with id [${payload.propertyId}]`
        }
    }

    async deletePlannedOperation(payload) {
        const state = this.appStore.getState()
        const propertyId = payload.propertyId
        const property = state.model.properties.collection.find(p => p.id === propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const newPlannedOperationsArray = property.plannedOperations.filter(event => event.id != payload.id)
            state.model.properties.collection[index] = {
                ...property,
                plannedOperations : newPlannedOperationsArray
            }
            this.appStore.setState(state)

            try {
                return await this.plannedOperationsFetcher.delete(payload.id).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `deletePlannedOperation : Unidentified Property with id [${payload.propertyId}]`}
    }
    

}