import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@current-environment/environment'
import { map, mergeMap, toArray } from 'rxjs/operators';
import { parseIsoDateString, NgbDateStructToIsoDate } from '@mf-framework/utils/date';
import { Commitment } from './commitments.typing';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';

@Injectable({
    providedIn: 'root',
})
export class CommitmentsFetcher {
    constructor(private http: HttpClient) { }

    index(propertyId) {
        let url =  `api/property/${propertyId}/commitments`
        return this.http.get<Commitment[]>(url)
    }

    addCommitment(data) {
        let url =  endpointsUrls.property.commitment
        return this.http.post<Commitment>(url, data)
    }

    delete(commitmentId) {
        let url =  `${endpointsUrls.property.commitment}/${commitmentId}`
        return this.http.delete(url)
    }

    patch(id: string, data) {
        let url =  `${endpointsUrls.property.commitment}/${id}`
        return this.http.patch<Commitment>(url, data)
    }
}