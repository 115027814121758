import { Injectable } from '@angular/core';
import { map, take, filter } from 'rxjs/operators'

import { AppStore } from '../application-store'

@Injectable({
    providedIn: 'root'
})
export class UiService {
    constructor(
        private appStore: AppStore,
    ) {
    }

    uiState$() {
        return this.appStore.store$.pipe(
            map(data => data.ui)
        )
    }

    isSpinnerShowed() {
        const state = this.appStore.getState()
        return state.ui.loadingSpinner
    }

    showSpinnerCallsCount = 0
    showLoadingSpinner(provenance?) {
        if(this.showSpinnerCallsCount<1)this.showSpinnerCallsCount = 0
        this.showSpinnerCallsCount++
        // console.log("SHOW",provenance,this.showSpinnerCallsCount)
        const state = this.appStore.getState()
        state.ui.loadingSpinner = true
        this.appStore.setState(state)
    }
    hideLoadingSpinner(provenance?) {
        this.showSpinnerCallsCount--
        // console.log("HIDE",provenance,this.showSpinnerCallsCount)
        if(this.showSpinnerCallsCount<1){
            const state = this.appStore.getState()
            state.ui.loadingSpinner = false
            this.appStore.setState(state)
        }
    }
    
    togglePadlockState() {
        const state = this.appStore.getState()
        state.ui.padlocked = !state.ui.padlocked
        this.appStore.setState(state)
    }
    setPadlockState(pstate) {
        const state = this.appStore.getState()
        if(state.ui.padlocked != pstate) {
            state.ui.padlocked = pstate
            this.appStore.setState(state)
        }
    }
}