import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { mergeMap, map, toArray, tap } from 'rxjs/operators';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { Job } from './jobs.typing';

const PARAMS = new HttpParams({
    fromObject: {
        "extra_fields" : "job"
    }
})

@Injectable({
    providedIn: 'root',
})
export class JobsCommunication {

    constructor(private http: HttpClient) { }

    updateJob(jobId) {
        // api/stand/{{stand}}/geographical-feature/{{type}}/{{codeUnique}}
        let url =  `${endpointsUrls.property.job}/${jobId}` 
        return this.http.get<Job>(url, {params : PARAMS})
    }
}
