import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Property, Mandate } from './property.typing'
import { Lot } from '../Lots/lot.typing';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { tap } from 'rxjs/operators';
import { processPropertyLots } from '../Lots/utils';
import { properties_extraFields_detailed } from '../Properties/properties-extra_fields';

const PARAMS = new HttpParams({
    fromObject: {
        "extra_fields": properties_extraFields_detailed
    }
})

@Injectable({
    providedIn: 'root',
})
export class PropertyFetcher {
    constructor(private http: HttpClient) { }

    select(propertyId: string) {
        let url =  `${endpointsUrls.property.property}/${propertyId}`

        return this.http.get<Property>(url,{params : PARAMS})
        .pipe(
            tap(Response => processPropertyLots(Response)),
        )
    }

    patch(propertyId: string, data) {
        let url =  `${endpointsUrls.property.property}/${propertyId}`
        return this.http.patch<Property>(url, data, {params : PARAMS})
    }

    addLot(data) {
        let url =  endpointsUrls.lots.lot
        return this.http.post<Lot>(url, data, {params : PARAMS})
    }

    deleteLot(lotId) {
        let url =  `${endpointsUrls.lots.lot}/${lotId}`
        return this.http.delete(url)
    }

    getSuperDelegates() {
        return this.http.get(endpointsUrls.property.superDelegates, {params : PARAMS})
    }

    mandate(propertyId: string, data) {
        let url =  `${endpointsUrls.property.property}/${propertyId}/create-mandate-request`
        return this.http.post<Mandate>(url, data, {params : PARAMS})
    }

    revokeMandate(mandateId: string) {
        let url =  `${endpointsUrls.property.mandate}/${mandateId}`
        return this.http.delete(url)
    }
}