import { MultiPolygon } from 'geojson';
import { Commitment } from '../Commitments/commitments.typing';
import { Dendrometrics } from './Dendrometrics/dendrometrics.typing';
import { SpeciesMeasure } from './SpeciesMeasures/speciesMeasure.typing';
import { Areas } from '../areas.typing';
import { Component } from './Component/component.typing';
import { Geometry } from '@turf/helpers';
import { Job } from '@mf-framework/utils/jobs.typing';

export const STANDTYPE_FAMILY_RESINOUS  = 'res';
export type standtype_family_resinous  = typeof STANDTYPE_FAMILY_RESINOUS;
export const STANDTYPE_FAMILY_DECIDUOUS = 'dec';
export type standtype_family_deciduous = typeof STANDTYPE_FAMILY_DECIDUOUS;
export const STANDTYPE_FAMILY_MIX       = 'mix';
export type standtype_family_mix       = typeof STANDTYPE_FAMILY_MIX;
export type standTypeFamily = standtype_family_resinous | standtype_family_deciduous | standtype_family_mix

export const STANDTYPE_SYSTEM_COPPICE               = 'coppice';
export const STANDTYPE_SYSTEM_HIGHFOREST            = 'highforest';
export const STANDTYPE_SYSTEM_COPPICE_HIGHFOREST    = 'coppice_highforest';
export const STANDTYPE_SYSTEM_CHESTNUT              = 'chestnut';
export const STANDTYPE_SYSTEM_WALNUT                = 'walnut';
export const STANDTYPE_SYSTEM_BARREN                = 'barren';
export const STANDTYPE_SYSTEM_POPLAR                = 'poplar';
export const STANDTYPE_SYSTEM_SCRUB                 = 'scrub';
export const STANDTYPE_SYSTEM_HEATH                 = 'heath';
export const STANDTYPE_SYSTEM_RESERVED              = 'reserved';
export const STANDTYPE_SYSTEM_EMPTY                 = 'empty';
export const STANDTYPE_SYSTEM_OTHER                 = 'other';
export type standTypeSystem = "coppice"|"highforest"|"coppice_highforest"|"chestnut"|"walnut"|"barren"|"poplar"|"scrub"|"heath"|"reserved"|"empty"|"other"


export const STANDTYPE_TAGS_RIPARIAN                = 'rip'; // Ripisylve
export const STANDTYPE_TAGS_PRE_FOREST              = 'pfo'; // Pré-bois
export const STANDTYPE_TAGS_DENSE                   = 'den'; // Dense
export const STANDTYPE_TAGS_SPARSE                  = 'spa'; // Eparse
export const STANDTYPE_TAGS_PLANTED                 = 'pla'; // Planté
export const STANDTYPE_TAGS_REGENERATED             = 'reg'; // Issu de régénération naturelle
export const STANDTYPE_TAGS_TO_REFOREST             = 'ref'; // A reboiser
export const STANDTYPE_TAGS_GARDENED                = 'gar'; // Jardiné
export const STANDTYPE_TAGS_NO_PRODUCTION           = 'nop'; // Non productif (intérêt environnemental)
export const STANDTYPE_TAGS_DEVELOPMENT             = 'dev'; // Aménagement de terrain
export type standTypeTag = 'rip' | 'pfo' | 'den' | 'spa' | 'pla' | 'reg' | 'ref' | 'gar' | 'nop' | 'dev'

export const STANDTYPE_STAGE_SEEDING                = 'see'; // Semis (1-3m)
export const STANDTYPE_STAGE_SAPLING                = 'sap'; // Gaulis (3-8m)
export const STANDTYPE_STAGE_POLE                   = 'pol'; // Perchis (8-15m)
export const STANDTYPE_STAGE_MATURE                 = 'mat'; // Mature (>15m)
export const STANDTYPE_STAGE_TO_CUT                 = 'cut'; // Prêt à récolter
export type standTypeStage = 'see' | 'sap' | 'pol' | 'mat' | 'cut'

export const STANDTYPE_STRUCTURE_REGULAR            = 'regular';
export const STANDTYPE_STRUCTURE_IRREGULAR          = 'irregular';
export type standTypeStructure = 'regular' | 'irregular'

export interface Species {}
export interface StandType {
    id : number;
    family : standTypeFamily;
    code : string;
    presetCode : string;
    stage : standTypeStage;
    structure : standTypeStructure;
    system : standTypeSystem;
    tags : standTypeTag[];

    propertyId: number
    color: string
    countries: []
    isPreset: boolean
    key: string
    label: string
    pattern: string
}

export interface AltitudeRange {
    min: string|number
    max: string|number
}
export interface SlopeRange {
    min: string|number
    max: string|number
}

export type ContextHumidity = string
export type ContextHumus = string
export type ContextTexture = string
export type ContextTopography = string
export interface Context {
    altitudeRange: AltitudeRange
    slopeRange: SlopeRange
    bedrockLevel: boolean
    deepObstacle: boolean
    depth: number
    humidity: ContextHumidity[]
    humus: ContextHumus
    texture: ContextTexture
    orientation: string
    potential: string
    rocky: boolean
    standPotential: string
    topography: ContextTopography
}

export interface Partition {}

export interface GeographicalFeatures {
    game_management: GeographicalFeature[]
    forestry_management: GeographicalFeature[]
    natura: GeographicalFeature[]
}
export interface GeographicalFeature {
    code: string
    type: string 
    name: string
    geometry: MultiPolygon
    intersectionArea: IntersectionArea
}
export interface IntersectionArea {
    admin: number 
    geom: number 
    input: number
}

export const MEASUREINPUTLEVEL_STAND_AVERAGE       = 'stand_average';
export const MEASUREINPUTLEVEL_SPECIES_VALUES       = 'species_values';
export type measureInputLevel = "stand_average"|"species_values"

export interface Stand {
    id : number;
    area? : Areas;
    geometry :	MultiPolygon;
    centroid : Geometry;
    code :	string;
    name :	string;
    comments :	string;
    color :	string;
    pattern :	string;
    measureInputLevel : measureInputLevel;
    speciesMeasures : SpeciesMeasure[];
    components : Component[]
    geographicalFeatures? : GeographicalFeatures
    context :	Context;
    dendrometrics :	Dendrometrics;
    gameImprovement :	string;
    gameConsequences :	"high" | "medium" | "low"
    healthState : string;
    groupCode : string;
    hunted :	boolean;
    huntingContract :	Commitment;
    huntingContractId :	number;
    publicAccess : "permanent" | "no_access" | "temporary"
    mainTargetSpeciesId : number;
    mainTargetSpecies :	Species;
    typeCurrent?	: StandType;
    typeCurrentId?	: number;
    typeTarget? : StandType;
    typeTargetId? : number;
    objective :	string;
    partition :	Partition;
    propertyId : number;
    geometryUpdateJob? : Job;
}