import { Injectable } from '@angular/core';
import { throwError } from 'rxjs'

import { AppStore } from '../../application-store'
import mergeDeep from '@mf-framework/utils/objects';
import { MapLayersRasterFetcher } from './mapLayersRaster.communication';
import { MapLayerRaster } from './mapLayerRaster.typing';


@Injectable({
    providedIn: 'root'
})
export class MapLayersRasterService {
    constructor(
        private appStore: AppStore,
        private mapLayersRasterFetcher: MapLayersRasterFetcher,
    ) { }

    async addMapLayerRaster(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const index = state.model.properties.collection.indexOf(property)
            return await this.mapLayersRasterFetcher.create(payload)
            .toPromise()
            .then(
                newMapLayerRaster => {
                    state.model.properties.collection[index] = {
                        ...property,
                        mapLayersRaster : [
                            ...property.mapLayersRaster,
                            newMapLayerRaster as MapLayerRaster
                        ]
                    }

                    this.appStore.setState(state)
                    return newMapLayerRaster
                }
            )
        }
        else {throw `addMapLayerRaster : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchMapLayerRaster(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const mapLayerRasterRef = property.mapLayersRaster.find(o => o.id == payload.elementId)
            const mapLayerRasterRefIndex = property.mapLayersRaster.indexOf(mapLayerRasterRef)
            
            state.model.properties.collection[index].mapLayersRaster[mapLayerRasterRefIndex] = {
                ...mapLayerRasterRef,
                ...mergeDeep(mapLayerRasterRef,payload.data)
            }

            this.appStore.setState(state)

            try {
                return await this.mapLayersRasterFetcher.patch(payload.elementId,payload.data).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `patchMapLayerRaster : Unidentified Property with id [${payload.propertyId}]`}
    }

    async deleteMapLayerRaster(payload) {
        const state = this.appStore.getState()
        const propertyId = payload.propertyId
        const property = state.model.properties.collection.find(p => p.id === propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const newMapLayersRasterArray = property.mapLayersRaster.filter(event => event.id != payload.id)

            state.model.properties.collection[index] = {
                ...property,
                mapLayersRaster : newMapLayersRasterArray
            }
            this.appStore.setState(state)

            try {
                return await this.mapLayersRasterFetcher.delete(payload.id).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `deleteMapLayerRaster : Unidentified Property with id [${payload.propertyId}]`}
    }
    

}