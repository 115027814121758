import { Injectable } from '@angular/core';
import { throwError } from 'rxjs'
import { AppStore } from '../../application-store'
import mergeDeep from '@mf-framework/utils/objects';
import { ProductionTablesFetcher } from './productionTables.communication';


@Injectable({
    providedIn: 'root'
})
export class ProductionTablesService {
    constructor(
        private appStore: AppStore,
        private productionTablesFetcher: ProductionTablesFetcher,
    ) { }

    async addProductionTable(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const index = state.model.properties.collection.indexOf(property)
            return await this.productionTablesFetcher.create(payload)
            .toPromise()
            .then(
                newProductionTable => {
                    state.model.properties.collection[index] = {
                        ...property,
                        productionTables : [
                            ...property.productionTables,
                            newProductionTable
                        ]
                    }

                    this.appStore.setState(state)
                    return newProductionTable
                }
            )
        }
        else {throw `addProductionTable : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchProductionTable(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const productionTableRef = property.productionTables.find(o => o.id == payload.elementId)
            const productionTableRefIndex = property.productionTables.indexOf(productionTableRef)
            
            state.model.properties.collection[index].productionTables[productionTableRefIndex] = {
                ...productionTableRef,
                ...mergeDeep(productionTableRef,payload.data)
            }

            this.appStore.setState(state)

            try {
                return await this.productionTablesFetcher.patch(payload.elementId,payload.data).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `patchProductionTable : Unidentified Property with id [${payload.propertyId}]`}
    }

    async deleteProductionTable(payload) {
        const state = this.appStore.getState()
        const propertyId = payload.propertyId
        const property = state.model.properties.collection.find(p => p.id === propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const newProductionTablesArray = property.productionTables.filter(event => event.id != payload.id)
            state.model.properties.collection[index] = {
                ...property,
                productionTables : newProductionTablesArray
            }
            this.appStore.setState(state)

            try {
                return await this.productionTablesFetcher.delete(payload.id).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `deleteProductionTable : Unidentified Property with id [${payload.propertyId}]`}
    }
    

}