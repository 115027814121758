import { Component, ChangeDetectorRef } from '@angular/core';
import { CoreService } from '@mf-framework/core-service';
import { UiService } from '@mf-framework/ui/ui.service';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';

@Component({
  selector: 'maforet-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'maforet';
    constructor (
        public coreService: CoreService,
        public uiService: UiService,
        private cd: ChangeDetectorRef,
        private router: Router
    ) {}

    ngOnInit() {
        this.coreService.Selector$({type:'uiState'}).subscribe(uiState => {
            this.cd.detectChanges()
        })

        this.router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart) {
                this.uiService.showLoadingSpinner("AppComponent:ngOnInit:RouteConfigLoadStart")
            }
            else if (event instanceof RouteConfigLoadEnd) {
                this.uiService.hideLoadingSpinner("AppComponent:ngOnInit:RouteConfigLoadEnd")
            }
        });
        this.uiService.hideLoadingSpinner("AppComponent:ngOnInit")
    }
}
