import { Pipe, PipeTransform } from '@angular/core';
import { formatSiArea } from '@mf-framework/utils/area-formatting';


@Pipe({
    name: 'surface'
})
export class SurfacePipe implements PipeTransform {

    transform(value:any, type?:string, novaluemessage?:string) : string {
        if (isNaN(value)) value = parseInt(value);
        if (!value) return novaluemessage?novaluemessage:'Surface inconnue';

        if(!type){
            return formatSiArea(value)
        }
        else if (type.indexOf("metric") == 0) {
            let decimals=2
            if(type.indexOf("metric-")==0){
                decimals = parseInt(type.split("-")[1])
            }

            if (value < 10000) {
                return Math.round(value) + ' m²';
            }
            else {
                return (value / 10000).toFixed(decimals).toString().replace(".", ",") + ' ha';
            }
        }
        else if (type == "meters") {
            return Math.round(value) + ' m²';
        }
    }

}
