import { Component, Input } from '@angular/core';

@Component({
    selector: 'mff-surface',
    template: `
        <span ngbTooltip="{{ area | surface }}" container="body" [openDelay]="100">{{ area | surface:surfaceUnit }}</span>
    `
})
export class SurfaceComponent {
    @Input() area:number;
    @Input() decimals;

    surfaceUnit = "metric"

    ngOnInit() {
        if(this.decimals){
            this.surfaceUnit = "metric-" + this.decimals
        }
    }
}
