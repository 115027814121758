import { Injectable, Inject, LOCALE_ID, EventEmitter } from '@angular/core';
import { formatDate } from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class AppToastService {
    notification:EventEmitter<any> = new EventEmitter();

    toasts: any[] = [];

    constructor(
        @Inject(LOCALE_ID) private locale: string
    ){}

    remove(toast){
        this.toasts = this.toasts.filter(t => t !== toast)
    }

    getTime() {
        return formatDate(new Date(), 'HH:mm:ss', this.locale)
    }
    notifyCreate() {
        this.notify('Ajout à '+ this.getTime())
    }
    notifyUpdate() {
        this.notify('Modification à '+ this.getTime())
    }
    notifyDelete() {
        this.notify('Suppression à '+ this.getTime())
    }

    debounceNotification
    notify(message) {
        clearTimeout(this.debounceNotification)
        this.debounceNotification = setTimeout(()=>{
            this.toasts.push({ body:message, classname: 'bg-gradient-dark text-light toast-body-thin'})
            this.notification.emit()
        },300)
    }
    notifyError(error) {
        let message = 'Une erreur est survenue à '+ this.getTime()
        if(error)message += '\n[' + error + ']'
        console.log("JS ERROR",message)
        this.toasts.push({ body: message, classname: 'bg-gradient-danger text-light', delay:8000 })
        this.notification.emit()
    }
    notifyHttpError() {
        let message = 'Echec de communication avec le serveur à '+ this.getTime()
        // if(error)message += '\n[' + error + ']'
        this.toasts.push({ body: message, classname: 'bg-gradient-danger text-light', delay:8000 })
        this.notification.emit()
    }
}