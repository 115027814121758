import { Component, OnInit, Output, EventEmitter, Input, OnChanges, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { latLng, LatLngBounds } from 'leaflet';
import * as L from 'leaflet';
import '@mf-framework/utils/types/leaflet/index'
// import * as Layers from '@mf-framework/map-layers/layers'
import * as Layers from '@current-app/map-layers'
import * as boundsUtils from '@mf-framework/utils/bounds';
import * as turfhelpers from '@turf/helpers';
import turflength from '@turf/length';
import turfarea from '@turf/area';
import { clone } from '@mf-framework/utils/objects';
import { MapLayer } from '@mf-framework/map-layers/mapLayer.typing';
import { CoreService } from '@mf-framework/core-service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from '@current-environment/environment';
import { basecolors } from '@mf-framework/utils/colors';
import { SurfacePipe } from '@mf-framework/interface/pipes/surface.pipe';
import { isDefined } from '@mf-framework/utils/lang';
import { DndDropEvent } from 'ngx-drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { convertDMS } from '@mf-framework/utils/math';

@Component({
  selector: 'mff-base-map',
  templateUrl: './base-map.component.html',
  styleUrls: ['./base-map.component.scss'],
  styles: [':host{display:contents;}'],
  providers: [SurfacePipe]
})
export class BaseMapComponent implements OnInit,  OnChanges {
    private _destroyed$ = new Subject();

    @ViewChild('queryPointInfosModal') queryPointInfosModal: TemplateRef<any>;
    // @Input() 
    // MapLayerRasters : MapLayerRaster[] = [];

    @Input() defaultCenter:L.LatLng;
    @Input() layers = [];
    @Input() mapToolBarPos = "top";
    @Input() cadaster = false;
    @Input() printableOnly = false;
    @Input() property;
    @Input() displayableObjects;
    
    @Output() mapReady: EventEmitter<any> = new EventEmitter();
    @Output() mapClick: EventEmitter<any> = new EventEmitter();
    @Output() mapDoubleClick: EventEmitter<any> = new EventEmitter();
    @Output() mapMove: EventEmitter<any> = new EventEmitter();
    @Output() mapMoveEnd: EventEmitter<any> = new EventEmitter();
    @Output() mapZoomed: EventEmitter<any> = new EventEmitter();
    @Output() screenSplitterHeightSetted: EventEmitter<any> = new EventEmitter();
    @Output() displayableObjectsUpdated: EventEmitter<any> = new EventEmitter();

    constructor(
        public coreService: CoreService,
        public route: ActivatedRoute,
        public surfacePipe : SurfacePipe,
        public modalService: NgbModal,
    ) { }
    
    ngOnChanges() {}
    
    references
    mapToolBarPosClass

    mapOptions = {
        zoomControl: false,
        zoom: 6,
        zoomSnap: 0.25,
        zoomDelta: 0.5,
        doubleClickZoom:false,
        center: environment.mapsDefaultCenter
        // center: latLng(46.6027, 1.8752)
    }
    
    ngOnInit() {
        if(this.defaultCenter)this.mapOptions.center = this.defaultCenter
        this.mapToolBarPosClass = `map-toolbar-${this.mapToolBarPos}`
    }

    onMapReady(mymap: L.Map) {
        this.map = mymap

        this.map.createPane("mapLayersRaster")
        this.map.createPane("mapLayersVector")
        this.map.createPane("back")
        this.map.createPane("middle")
        this.map.createPane("front")
        this.map.createPane("frontIcons")
        this.map.createPane("tools")

        this.mapReady.emit(this.map)
        
        this.initDefaultLayers()

        L.control.scale().setPosition("bottomright").addTo(this.map);

        setTimeout(() => { this.map.invalidateSize()}, 150)

        setTimeout(() => { 
            this.getSelectedLayers().forEach(l=>{
                this.updateLayerGrayscale(l)
            })
        }, 500)
    }

    setScreenSplitterHeight(className){
        this.screenSplitterHeightSetted.emit(className)
        setTimeout(() => { this.map.invalidateSize()}, 250)
    }

    @Input() set messageAtPos(message) {
        if(message) {
            let x = message.pos.x -138
            let y = message.pos.y -70
            this.messageStyle = {
                display:"block",
                transform: `translate3d(${x}px, ${y}px, 0px)`,
            }
            this.messageString = message.message
        }
        else {
            this.messageStyle = {display:"none",transform:"none"}        
            this.messageString = ""
        }
    }
    messageStyle = {
        display:"none",
        transform:"none"
    }
    messageString = ""

    inhibitExternalMapClick = false
    onMapClick(event) {
        this.messageAtPos = undefined
        
        if(this.isQueryingPoint){
            this.queryPoint(event.latlng)
        }
        if(this.isMeasuringOnMap){
            this.addMeasurePoint(event.latlng)
        }

        if(!this.inhibitExternalMapClick){
            this.mapClick.emit(event)
        }
        
        if(this.displayableObjectsMenuDisplayed)this.displayDisplayableObjectsPanel()
        if(this.layersMenuDisplayed)this.displayLayersPanel()
    }
    
    onMapDoubleClick(event) {
        this.messageAtPos = undefined
        this.mapDoubleClick.emit(event)
    }
    
    onMapMove(event) {
        this.messageAtPos = undefined
        this.mapMove.emit(event)
    }
    onMapMoveEnd(event) {
        this.messageAtPos = undefined
        this.mapMoveEnd.emit(event)
    }
    onMapZoomEnd(event) {
        // console.log(this.map.getZoom());        
    }
    
    map: L.Map;
    mapBounds: LatLngBounds
    zoomEnabled = true

    public disableZoom() {
        this.map.scrollWheelZoom.disable()
        this.map.doubleClickZoom.disable()
        this.map.touchZoom.disable()
        this.map.boxZoom.disable()
        this.zoomEnabled = false
    }
    public enableZoom() {
        this.map.scrollWheelZoom.enable()
        this.map.doubleClickZoom.enable()
        this.map.touchZoom.enable()
        this.map.boxZoom.enable()
        this.zoomEnabled = true
    }

    zoomMap(sens){
        this.messageAtPos = undefined
        if(sens)this.map.zoomIn()
        else this.map.zoomOut()
        this.mapZoomed.emit(this.map.getZoom())
    }

    @Input() zoomOnProperty(animateZoom?) {
        if(this.property){
            let bounds
            if(this.property.lots && this.property.lots.length) {
                let geoJson = turfhelpers.featureCollection([])
                this.property.lots.forEach(lot => {
                    if(lot.geometry){
                        geoJson.features.push(turfhelpers.feature(lot.geometry))
                    }
                    else if(lot.centroid){
                        geoJson.features.push(turfhelpers.feature(lot.centroid))
                    }
                })
                const geo = L.geoJSON(<any>geoJson)
                bounds = geo.getBounds()
                
            }
            
            if(bounds && bounds.isValid()){
                this.map.flyToBounds(bounds,{animate:animateZoom})
                return
            }
            
            if(this.property.envelope && this.property.envelope.coordinates && this.property.envelope.coordinates.length) {
                boundsUtils.fitPropertyEnvelopeBounds(this.map, this.property.envelope)
            }
            else if(this.property.locality && this.property.locality.envelope && this.property.locality.envelope.coordinates){
                boundsUtils.fitPropertyEnvelopeBounds(this.map, this.property.locality.envelope)
            }
        }
    }

    sidePanelOpened:boolean = false

    openSidePanel() {
        this.messageAtPos = undefined
        this.sidePanelOpened = true
    }
    closeSidePanel() {
        this.messageAtPos = undefined
        this.sidePanelOpened = false
    }

    displayableObjectsMenuDisplayed:boolean = false

    displayDisplayableObjectsPanel() {
        if(this.displayableObjectsMenuDisplayed){
            this.closeSidePanel()
            this.displayableObjectsMenuDisplayed = false
        }
        else {
            this.openSidePanel()
            this.displayableObjectsMenuDisplayed = true
        }
    }

    switchDisplayableObjectDisplay(displaybleObject) {
        displaybleObject.selected = !displaybleObject.selected
        this.displayableObjectsUpdated.emit()
    }
    
    setDisplayableObjectOpacity(displaybleObject,event) {
        displaybleObject.displayOpacity = event.target.value
        this.displayableObjectsUpdated.emit()
    }
    setDisplayableObjectStrokeWidth(displaybleObject,event) {
        displaybleObject.strokeWidthIncrease = event.target.value
        this.displayableObjectsUpdated.emit()
    }

    layersMenuDisplayed:boolean = false

    displayLayersPanel(){
        if(this.layersMenuDisplayed){
            this.closeSidePanel()
            this.layersMenuDisplayed = false
        }
        else {
            this.openSidePanel()
            this.layersMenuDisplayed = true
        }
    }
    switchLayer(layer:MapLayer,forceSelect=false) {
        if(!layer.selected || forceSelect){
            layer.selected = true
            layer.index = isDefined(layer.index) ? layer.index : this.layers.length+1
            if(layer.cadaster)layer.index = 99999
            // if(layer.zIndex){
            //     layer.layers.forEach(l=>{
            //         l.setZIndex(layer.zIndex)
            //     })
            // }
            // else{
                // const order = this.avalaibleLayers.findIndex(l => l === layer)
                // layer.layers.forEach(l=>{
                //     l.setZIndex(order>0 ? order*-1 : 1)
                // })
                layer.layers.forEach(l=>{
                    l.setZIndex(layer.index)
                })
            // }

            layer.layers.forEach(l=>{
                l.setOpacity(layer.displayOpacity/100)
            })

            this.layers = layer.layers.concat(this.layers)
        }
        else {
            layer.selected = false
            delete layer.index
            layer.layers.forEach(l=>{
                const index = this.layers.findIndex(ml => ml === l)
                this.layers.splice(index,1)
            })
            this.updateLayersIndexes()
        }

    }
    
    setLayerVisibility(layer:MapLayer) {
        if(layer.displayOpacity>0){
            layer.visibility = layer.displayOpacity
            layer.displayOpacity = 0
        }
        else{
            layer.displayOpacity = layer.visibility
        }
        layer.layers.forEach(l=>{
            l.setOpacity(layer.displayOpacity/100)
        })
    }

    setLayerOpacity(layer:MapLayer,event){
        layer.displayOpacity = event.target.value
        layer.layers.forEach(l=>{
            l.setOpacity(event.target.value/100)
        })
    }

    switchLayerGrayscale(layer:MapLayer) {
        layer.grayscale = !layer.grayscale
        this.updateLayerGrayscale(layer)
    }

    updateLayerGrayscale(layer) {
        this.layers.forEach(gl=>{
            layer.layers.forEach(ll=>{
                if(ll==gl){
                    const container = gl.getContainer()
                    if(container){
                        if(layer.grayscale){
                            L.DomUtil.addClass(container,"grayscale")
                        }
                        else{
                            L.DomUtil.removeClass(container,"grayscale")
                        }
                    }
                }
            })
        })
    }

    initDefaultLayers() {
        let layers = []

        let activeLayers = this.collectAllLayers().filter(al => {
            if(al.cadaster)al.selected = false
            if(al.selected)return true
        })
        if(this.printableOnly){
            activeLayers = activeLayers.filter(layer=>{
                return layer.printLayers
            })
        }
        if(activeLayers.length) {
            activeLayers.forEach(al=>{
                this.switchLayer(al,true)
            })
            // const order = this.avalaibleLayers.findIndex(l => l === activeLayer)
            // activeLayer.layers.forEach(l=>{
            //     l.setZIndex(order>0 ? order*-1 : 1)
            // })
            // layers = layers.concat(activeLayer.layers)
        }
    
        if(this.cadaster){
            let cadasterLayer = this.collectAllLayers().find(al => {
                if(al.cadaster)return true
            })
            if(cadasterLayer) {
                // cadasterLayer.selected = true
                // layers = layers.concat(cadasterLayer.layers)
                this.switchLayer(cadasterLayer)
            }
        }
        this.updateLayersIndexes()
        // this.layers = layers
    }
    
    // avalaibleLayers: MapLayer[] = Layers.avalaibleLayers
    layersLibrary  = Layers.layersLibrary

    isLayerLibraryCategory(obj) {
        return isDefined(obj.layersCollection)
    }

    hasLayerLibraryAvalaibleContent(category){
        if(this.printableOnly){
            return category.layersCollection.find(l=>{
                return isDefined(l.printLayers)
            })
        }
        else{
            return true
        }
    }

    switchLibraryCategoryDeploy(layerLibCat) {
        this.getlayersLibrary().forEach(ll=>{
            if(this.isLayerLibraryCategory(ll)){
                if(ll!=layerLibCat)ll.deployed = false
            }
        })
        layerLibCat.deployed = !layerLibCat.deployed
    }

    updateLayersIndexes(selectedLayers?) {
        if(!selectedLayers){
            selectedLayers = this.getSelectedLayers()
        }
        selectedLayers.forEach((l,i)=>{
            l.index = (selectedLayers.length) - (i)
        })

        selectedLayers.forEach(l=>{
            l.layers.forEach(ll=>{
                ll.setZIndex(l.index)
            })
        })
    }

    onLayerDrop( event:DndDropEvent ) {
        const selectedLayers = this.getSelectedLayers()
        const droppedLayer = selectedLayers.find(l=>l.id == event.data)
        const position = selectedLayers.indexOf( droppedLayer )
        let newIndex = event.index
        if(position<event.index){newIndex--}
        
        selectedLayers.splice( position, 1 );
        selectedLayers.splice( newIndex, 0, droppedLayer )
        this.updateLayersIndexes(selectedLayers)
    }

    collectAllLayers() {
        let allLayers = []
        this.layersLibrary.forEach(ll=>{
            if(this.isLayerLibraryCategory(ll)){
                ll.layersCollection.forEach(sll=>{
                    allLayers.push(sll)
                })
            }
            else {
                allLayers.push(ll)
            }
        })
        
        return allLayers
    }

    getSelectedLayers() {
        let selectedLayers = []
        this.layersLibrary.forEach(ll=>{
            if(this.isLayerLibraryCategory(ll)){
                ll.layersCollection.forEach(sll=>{
                    if(sll.selected){
                        selectedLayers.push(sll)
                    }
                })
            }
            else if(ll.selected){
                selectedLayers.push(ll)
            }
        })
        if(this.printableOnly){
            selectedLayers = selectedLayers.filter(layer=>{
                return layer.printLayers
            })
        }
        return selectedLayers.sort((a,b) => {
            return b.index - a.index
        })
    }

    getlayersLibrary() {
        if(this.printableOnly){
            return this.layersLibrary.filter(layer=>{
                if(this.isLayerLibraryCategory(layer))return true
                return layer.printLayers
            })
        }
        else{
            return this.layersLibrary
        }
    }

    getlayersLibraryCategoryCollection(category) {
        if(this.printableOnly){
            return category.layersCollection.filter(layer=>{
                return layer.printLayers
            })
        }
        else{
            return category.layersCollection
        }
    }
    // getAvalaibleLayers() {
    //     if(this.printableOnly){
    //         return this.avalaibleLayers.filter(layer=>{return layer.printLayers})
    //     }
    //     else {
    //         return this.avalaibleLayers
    //     }
    // }

    public getPrintLayers() {
        let activeLayers = []
        this.layers.forEach(activelayer => {
            this.collectAllLayers().forEach(avalaibleLayerRef => {
                avalaibleLayerRef.layers.forEach(all=>{
                    if(all == activelayer){
                        if(!activeLayers.find(listedLayer => listedLayer.id == avalaibleLayerRef.id) && avalaibleLayerRef.displayOpacity>0){
                            activeLayers.push({
                                id:avalaibleLayerRef.id,
                                printLayers:avalaibleLayerRef.printLayers,
                                opacity:avalaibleLayerRef.displayOpacity,
                                grayscale : avalaibleLayerRef.grayscale
                            })
                        }
                    }
                })
            })
        })
        return activeLayers.reverse()
    }
    
    initialMapCursorClass
    storeInitialMapCursorClass() {
        let mapClasses =  L.DomUtil.getClass(this.map.getContainer())
        if(mapClasses.includes('cursor-')){
            mapClasses.split(" ").forEach(c => {
                if(c.includes('cursor-')){
                    this.initialMapCursorClass = c
                }
            })
        }
    }

    restoreInitialMapCursorClass() {
        if(this.initialMapCursorClass){
            L.DomUtil.addClass(this.map.getContainer(),this.initialMapCursorClass)
        }
        this.initialMapCursorClass = undefined
    }

    @Input() preventBaseMapDraws() {
        this.preventMeasuringOnMap = true
        this.resetMeasureOnMap()
    }

    @Input() restoreBaseMapDraws() {
        this.preventMeasuringOnMap = false
    }

    @Output() inhibitMapFeaturesClick: EventEmitter<any> = new EventEmitter();

    isQueryingPoint
    activateQueryPoint() {
        if(!this.isQueryingPoint){
            this.resetMeasureOnMap()
            this.inhibitMapFeaturesClick.emit(true)
            this.inhibitExternalMapClick = true
            this.isQueryingPoint = true
            this.storeInitialMapCursorClass()
            L.DomUtil.addClass(this.map.getContainer(), 'cursor-crosshair')
            this.waitForQueryPoint()
        }
        else{
            this.resetQueryPoint()
        }
    }
    resetQueryPoint() {
        this.isQueryingPoint = false
        L.DomUtil.removeClass(this.map.getContainer(), 'cursor-crosshair')
        this.restoreInitialMapCursorClass()
        this.inhibitMapFeaturesClick.emit(false)
        this.inhibitExternalMapClick = false
    }

    waitForQueryPoint() {}
    queriedPointData
    queriedPoint
    isWaitingQueryPointResult
    queryPoint(latLng:L.LatLngLiteral) {
        if(this.isWaitingQueryPointResult)return
        
        // latLng = {lat:50.45258, lng:3.96502} // site classé

        this.isWaitingQueryPointResult = true
        L.DomUtil.addClass(this.map.getContainer(), 'cursor-wait')
        this.coreService.Selector$({ type: 'queryGeographicalPoint', payload: {
            countryCode : this.property.countryCode,
            latlng:latLng
         } })
            .subscribe(
                res => {
                    this.queriedPointData = res
                    this.queriedPoint = convertDMS(latLng.lat,latLng.lng)
                    this.modalService.open(this.queryPointInfosModal, { size:"md", centered: true })
                    L.DomUtil.removeClass(this.map.getContainer(), 'cursor-wait')
                    this.isWaitingQueryPointResult = false
                },
                error => {
                    console.log(error)
                    L.DomUtil.removeClass(this.map.getContainer(), 'cursor-wait')
                    this.isWaitingQueryPointResult = false
                }
            )
    }

    getZoningAreaTypeLabel(zoningType) {
        switch(zoningType){
            case "pipeline" :
                return "Canalisation"
            break;
            case "road" :
                return "Réseau routier"
            break;
            case "railway" :
                return "Réseau ferroviaire"
            break;
            case "waterway" :
                return "Voie navigable"
            break;
            case "power_line" :
                return "Ligne électrique haute tension"
            break;
            case "point_of_view" :
                return "Point de vue remarquable"
            break;
            case "view_area" :
                return "Périmètre de points de vue remarquable"
            break;
            case "landscape" :
                return "Intérêt paysager"
            break;
            case "culture" :
                return "Intérêt culturel, historique ou esthétique"
            break;
            case "ecology_link" :
                return "Liaison écologique"
            break;
            case "infrastructure_res" :
                return "Réservation d'infrastructure principale"
            break;
            case "extraction_ext" :
                return "Extension de zone d'extraction"
            break;
            case "land_cover" :
                return "Affectation"
            break;
        }
    }

    preventMeasuringOnMap = false
    isMeasuringOnMap
    
    activateMeasureOnMap() {
        if(!this.isMeasuringOnMap){
            this.resetQueryPoint()
            this.inhibitMapFeaturesClick.emit(true)
            this.inhibitExternalMapClick = true
            this.isMeasuringOnMap = true
            this.storeInitialMapCursorClass()
            L.DomUtil.addClass(this.map.getContainer(), 'cursor-crosshair')
            this.computeMeasure()
        }
        else{
            this.resetMeasureOnMap()
        }
    }
    
    resetMeasureOnMap() {
        this.isMeasuringOnMap = false
        L.DomUtil.removeClass(this.map.getContainer(), 'cursor-crosshair')
        this.restoreInitialMapCursorClass()
        if(this.measurePathLayer)this.measurePathLayer.clearLayers()
        this.measurePath = undefined
        this.measureOnMapMessage = undefined
        this.inhibitMapFeaturesClick.emit(false)
        this.inhibitExternalMapClick = false
    }

    measurePath
    measurePathLayer

    addMeasurePoint(latLng:L.LatLngLiteral) {
        if(!this.measurePath){
            this.measurePath = []
        }

        let coords = [latLng.lng,latLng.lat]
        this.measurePath.push(coords)
        this.displayMeasurePath()
        this.computeMeasure()
    }
    
    @HostListener('window:keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
        if(e.ctrlKey && e.key == "z"){
            if (this.isMeasuringOnMap) {
                this.undoLastMeasurePoint()
            }
        }
    }
    
    undoLastMeasurePoint() {
        if(this.measurePath && this.measurePath.length){
            this.measurePath.pop()
            if(this.measurePathLayer)this.measurePathLayer.clearLayers()
            this.displayMeasurePath()
            this.computeMeasure()
        }
    }

    measureOnMapMessage
    computeMeasure() {
        if(!this.measurePath || !this.measurePath.length){
            this.measureOnMapMessage = "Cliquez sur le premier point de la distance à mesurer"
        }
        else if(this.measurePath.length == 1){
            this.measureOnMapMessage = "Cliquez sur le second point de la distance à mesurer"
        }
        else {
            let geoJson = turfhelpers.lineString(this.measurePath)
            let length = turflength(geoJson)
            let message
            if(length <= 0.1){
                // decimales en dessous de 100m
                message = `Longueur : ${(length*1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1})} m`

            }
            else if(length < 1){
                message = `Longueur : ${Math.round(length*1000)} m`

            }
            else {
                message = `Longueur : ${length.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})} km`
            }

            if(this.measurePath.length > 2) {
                let closed = clone(this.measurePath)
                closed.push(closed[0])
                let polyGeoJson = turfhelpers.polygon([closed])
                let area = this.surfacePipe.transform(turfarea(polyGeoJson),"metric")
                let areaSi = this.surfacePipe.transform(turfarea(polyGeoJson))
                message += ` | Surface : ${area} (${areaSi})`
                // this.surfacePipe.transform(value,"meters")
            }
            this.measureOnMapMessage = message
        }
    }

    displayMeasurePath(){
        if(!this.measurePath || !this.measurePath.length)return

        let geoJson = turfhelpers.featureCollection([])

        if(this.measurePath.length > 1){
            geoJson.features.push(turfhelpers.feature(
                turfhelpers.lineString(this.measurePath).geometry,
                {}
            ))
        }

        this.measurePath.forEach((coords,index,path) => {
            if(index==0 || index == path.length-1){
                geoJson.features.push(turfhelpers.feature(
                    turfhelpers.point(coords).geometry
                ))
            }
        })

        const geo = L.geoJSON(<any>geoJson, {
            pane: "tools",
            style: (feature) => {
                if(feature.geometry.type=="LineString"){
                    return {
                        stroke: true,
                        color: basecolors.$blue,
                        interactive: false,
                        weight: 2,
                        dashArray: "10"
                    }
                }
                else{
                    return {
                        stroke: true,
                        fillColor: basecolors.$blue,
                        fillOpacity: 1,
                        interactive: false,
                        radius: 5,
                    }
                }
            },
            pointToLayer: (feature, latlng) => {
                return L.circleMarker(latlng, {
                    
                    pane: "tools",
                });
            },
        })

        if(!this.measurePathLayer)this.measurePathLayer = L.layerGroup().addTo(this.map)
        this.measurePathLayer.clearLayers()
        this.measurePathLayer.addLayer(geo)
    }

    public ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
