export function getPropertyArea(property){
    if(property.area.input)return property.area.input

    let countryCode = property.countryCode
    if(countryCode.toUpperCase() == "BE" && property.area.geom) {
        return property.area.geom
    }
    if(countryCode.toUpperCase() == "FR" && property.area.admin) {
        return property.area.admin 
    }

    return property.area.geom || property.area.admin
}

export function increasePropertyArea(property,surfaceToAdd){
    if(property.area.input)property.area.input += surfaceToAdd

    let countryCode = property.countryCode
    if(countryCode.toUpperCase() == "BE") {
        return property.area.geom += surfaceToAdd
    }
    if(countryCode.toUpperCase() == "FR") {
        return property.area.admin += surfaceToAdd
    }
}

export function decreasePropertyArea(property,surfaceToSubtract){
    if(property.area.input)property.area.input -= surfaceToSubtract

    let countryCode = property.countryCode
    if(countryCode.toUpperCase() == "BE") {
        return property.area.geom -= surfaceToSubtract
    }
    if(countryCode.toUpperCase() == "FR") {
        return property.area.admin -= surfaceToSubtract
    }
}

export function getPropertyOwners(property) {
    if(property.lots && property.lots.length) {
        let contactsIds = property.lots.filter(l=>{return !l.neighbour}).map(l=>{
            let ids = l.owners.map(o=>{
                return o.contactId
            })
            return ids
        })
        // contactsIds = [...new Set(contactsIds.flat())]
        contactsIds = [...new Set([].concat.apply([], contactsIds))]

        return property.contacts.filter(c => {
            // return c.roles.includes("owner")
            return contactsIds.includes(c.id)
        })
    }
    return property.contacts.filter(c => {
        // return c.defaultOwnership
        return c.roles.includes("owner")
    })
}

export function doesUserHaveGrantingPrivileges(property) {
    if(property && property.userMandate) {
        for (let index = 0; index < property.userMandate.privileges.length; index++) {
            const element = property.userMandate.privileges[index];
            if(element.access == "property" && element.level.indexOf("g")>-1) {
                return true
            }
        }
    }
    return false
}

export function doesUserHaveWritePrivileges(property) {
    if(property && property.userMandate) {
        for (let index = 0; index < property.userMandate.privileges.length; index++) {
            const element = property.userMandate.privileges[index];
            if(element.access == "property" && element.level.indexOf("w")>-1) {
                return true
            }
        }
        return false
    }
    return false
}

export function getPropertyUserPrivilegesString(mandate) {
    if(mandate && mandate.privileges && mandate.privileges.length) {
        let Str = []
        for (let index = 0; index < mandate.privileges.length; index++) {
            const element = mandate.privileges[index];
            
            if(element.access == "property") {
                if(element.level.indexOf("r")>-1){
                    Str.push("lecture")
                }
                if(element.level.indexOf("w")>-1){
                    Str.push("modification")
                }
                if(element.level.indexOf("g")>-1){
                    Str.push("administration")
                    Str.push("suppression")
                }
                
            }
        }
        if(Str.length)return Str.join(", ")
        return "Aucun"
    }
    return "Aucun"
}