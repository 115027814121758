import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MapLayerRaster } from './mapLayerRaster.typing';
import { map } from 'rxjs/operators';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';


@Injectable({
    providedIn: 'root',
})
export class MapLayersRasterFetcher {
    constructor(private http: HttpClient) { }
    
    create(data) {
        const formData = new FormData();
        formData.append('propertyId', data.propertyId.toString())
        formData.append('opacity', data.opacity)
        formData.append('file', data.file)
        formData.append('name', data.name)
        if(data.envelope)formData.append('envelope', data.envelope)
        
        let url =  endpointsUrls.property.mapLayerRaster
        return this.http.post<any>(
            url, 
            formData,
            {
                reportProgress: true,
                observe: 'events'
            }
        )
        .pipe(map((event) => {

            switch (event.type) {
      
              case HttpEventType.UploadProgress:
                const progress = Math.round(100 * event.loaded / event.total);
                console.log("upload progress",progress)
                return { status: 'progress', message: progress };
      
              case HttpEventType.Response:
                return event.body;
              default:
                return `Unhandled event: ${event.type}`;
            }
          })
        );
    }

    delete(mapLayerRasterId) {
        let url =  `${endpointsUrls.property.mapLayerRaster}/${mapLayerRasterId}`
        return this.http.delete(url)
    }

    patch(mapLayerRasterId: string, data) {
        let url =  `${endpointsUrls.property.mapLayerRaster}/${mapLayerRasterId}`
        return this.http.patch<MapLayerRaster>(url, data)
    }
}