import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { MapLayerVector } from './mapLayerVector.typing';
import { isDefined } from '@mf-framework/utils/lang';


@Injectable({
    providedIn: 'root',
})
export class MapLayersVectorFetcher {
    constructor(private http: HttpClient) { }
    
    create(data) {
        const formData = new FormData();
        formData.append('propertyId', data.propertyId.toString())
        formData.append('file', data.file)
        formData.append('name', data.name)

        if(isDefined(data.active))formData.append('active', data.active)
        if(isDefined(data.color))formData.append('color', data.color)
        if(isDefined(data.fillColor))formData.append('fillColor', data.fillColor)
        if(isDefined(data.opacity))formData.append('opacity', data.opacity)
        if(isDefined(data.weight))formData.append('weight', data.weight)
        
        let url =  endpointsUrls.property.mapLayerVector
        return this.http.post<any>(
            url, 
            formData,
            {
                reportProgress: true,
                observe: 'events'
            }
        )
        .pipe(map((event) => {

            switch (event.type) {
      
              case HttpEventType.UploadProgress:
                const progress = Math.round(100 * event.loaded / event.total);
                console.log("upload progress",progress)
                return { status: 'progress', message: progress };
      
              case HttpEventType.Response:
                return event.body;
              default:
                return `Unhandled event: ${event.type}`;
            }
          })
        );
    }

    delete(mapLayerVectorId) {
        let url =  `${endpointsUrls.property.mapLayerVector}/${mapLayerVectorId}`
        return this.http.delete(url)
    }

    patch(mapLayerVectorId: string, data) {
        let url =  `${endpointsUrls.property.mapLayerVector}/${mapLayerVectorId}`
        return this.http.patch<MapLayerVector>(url, data)
    }
}