import { Injectable } from '@angular/core';
import { Observable, merge, throwError } from 'rxjs'
import { DocumentCommunication } from './document.communication';
import { saveAs } from 'file-saver';
import { UiService } from '@mf-framework/ui/ui.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    constructor(
        private documentCommunication: DocumentCommunication,
        public uiService: UiService,
    ) {}
    
    async generateDocument(payload) {
        this.uiService.showLoadingSpinner("DocumentService:generateDocument")
        return await this.documentCommunication.generateDocument(payload.propertyId,payload.body)
        .subscribe(
            res => {
                var filename = payload.fileName || 'document.' + payload.body.format
                saveAs(res, filename);
                setTimeout(() => {this.uiService.hideLoadingSpinner("DocumentService:generateDocument:success")},10)
            }
            ,error => {
                setTimeout(() => {this.uiService.hideLoadingSpinner("DocumentService:generateDocument:error")},10)
                return throwError({error:error}).toPromise()
            }
        )
    }

    async generateShapefile(payload) {
        this.uiService.showLoadingSpinner("DocumentService:generateShapefile")
        return await this.documentCommunication.generateShapefile(payload.propertyId,payload.path,payload.body)
        .subscribe(
            res => {
                if(res.status && res.status == 200) {
                    var filename = payload.fileName || 'shapefile.zip'
                    saveAs(res.body, filename);
                }
                else{
                    alert("L'opération n'a donné aucun résultat. Assurez-vous d'avoir défini les limites géographiques des parcelles cadastrales et forestières.")
                }
                setTimeout(() => {this.uiService.hideLoadingSpinner("DocumentService:generateShapefile:success")},10)
            }
            ,error => {
                setTimeout(() => {this.uiService.hideLoadingSpinner("DocumentService:generateShapefile:error")},10)
                return throwError({error:error}).toPromise()
            }
        )
    }
    async generateCsv(payload) {
        this.uiService.showLoadingSpinner("DocumentService:generateCsv")
        return await this.documentCommunication.generateCsv(payload.propertyId,payload.path,payload.body)
        .subscribe(
            res => {
                if(res.status && res.status == 200) {
                    var filename = payload.fileName || 'export.csv'
                    saveAs(res.body, filename);
                }
                else{
                    alert("L'opération n'a donné aucun résultat. Assurez-vous d'avoir renseigné les données correspondantes pour cette propriété.")
                }
                setTimeout(() => {this.uiService.hideLoadingSpinner("DocumentService:generateCsv:success")},10)
            }
            ,error => {
                setTimeout(() => {this.uiService.hideLoadingSpinner("DocumentService:generateCsv:error")},10)
                return throwError({error:error}).toPromise()
            }
        )
    }

    async exportBlobFormats(payload) {
        this.uiService.showLoadingSpinner("DocumentService:exportShapefile")
        return await this.documentCommunication.exportBlobFormats(payload.path,payload.body)
        .subscribe(
            res => {
                if(res.status && res.status == 200) {
                    var filename = payload.fileName || 'export'
                    saveAs(res.body, filename);
                }
                else{
                    alert("L'opération n'a donné aucun résultat.")
                }
                setTimeout(() => {this.uiService.hideLoadingSpinner("DocumentService:exportShapefile:success")},10)
            }
            ,error => {
                setTimeout(() => {this.uiService.hideLoadingSpinner("DocumentService:exportShapefile:error")},10)
                return throwError({error:error}).toPromise()
            }
        )
    }
}