import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { ProductionTable } from './productionTable.typing';

@Injectable({
    providedIn: 'root',
})
export class ProductionTablesFetcher {
    constructor(private http: HttpClient) { }
    
    create(data) {
        delete data.propertyId
        let url =  endpointsUrls.property.productionTable
        return this.http.post<ProductionTable>(url, data)
    }

    patch(productionTableId: string, data) {
        let url =  `${endpointsUrls.property.productionTable}/${productionTableId}`
        return this.http.patch<ProductionTable>(url, data)
    }

    delete(productionTableId) {
        let url =  `${endpointsUrls.property.productionTable}/${productionTableId}`
        return this.http.delete(url)
    }

}