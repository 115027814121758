import { Injectable } from '@angular/core';
import { Observable, merge, throwError } from 'rxjs'
import { map, filter, switchMap, tap, mergeMap } from 'rxjs/operators'

import { AppStore } from '../../application-store'
import { ContactsFetcher } from './contacts.communication';
import mergeDeep from '@mf-framework/utils/objects';

@Injectable({
    providedIn: 'root'
})
export class ContactsService {
    ContactsFetcher: any;
    constructor(
        private appStore: AppStore,
        private contactsFetcher: ContactsFetcher,
    ) {}
        
    getContactLots$(payload) {
        return this.contactsFetcher.getContactLots(payload.contactid)
    }

    async addContact(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            
            try {
                await this.contactsFetcher.addContact(payload)
                .toPromise()
                .then(
                    newContact => {
                        state.model.properties.collection[index] = {
                            ...property,
                            contacts : [
                                ...property.contacts,
                                newContact
                            ]
                        }

                        this.appStore.setState(state)
                    }
                )
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
            }
        }
        else {throw `addContact : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchContact(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const contactRef = property.contacts.find(c => c.id == payload.id)
            const contactRefIndex = property.contacts.indexOf(contactRef)

            state.model.properties.collection[index].contacts[contactRefIndex] = {
                ...contactRef,
                ...mergeDeep(contactRef,payload.data)
            }

            this.appStore.setState(state)

            try {
                return await this.contactsFetcher.patch(payload.id,payload.data).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `patchContact : Unidentified Property with id [${payload.propertyId}]`}
    }

    async deleteContact(payload) {
        const state = this.appStore.getState()
        const propertyId = payload.contact.propertyId
        const property = state.model.properties.collection.find(p => p.id === propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const newContactsArray = property.contacts.filter(contact => contact.id != payload.contact.id)
            state.model.properties.collection[index] = {
                ...property,
                contacts : newContactsArray
            }
            this.appStore.setState(state)

            try {
                await this.contactsFetcher.delete(payload.contact.id).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
    }
}

