import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@current-environment/environment'
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { getApiUrl } from '@mf-framework/utils/application';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ForecastsFetcher {
    constructor(private http: HttpClient) { }

    // POST {{host}}/api/{{property}}/forecasts?extra_fields=volumeForecast
    // Content-Type: application/json
    // Accept: application/json;version=3.1
    // Authorization: Bearer {{authorization}}

    // {
    // "standIds": [{{other-stand}}],
    // "lastYear": 2050
    // }

    getStandForecast(propertyId,standId,lastYear=2050) {
        let rawUrl = `/api/${propertyId}/forecasts?extra_fields=volumeForecast`
        let url =  getApiUrl(environment,rawUrl)
        
        return this.http.post(url, {
            "standIds": [+standId],
            // "lastYear": lastYear
        })
    }
    
}