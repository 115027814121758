import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { StandType } from '../Stands/stand.typing';


@Injectable({
    providedIn: 'root',
})
export class StandTypesFetcher {
    constructor(private http: HttpClient) { }

    add(data) {
        let url =  endpointsUrls.property.standType
        return this.http.post<StandType>(url, data)
    }

    delete(standTypeId) {
        let url =  `${endpointsUrls.property.standType}/${standTypeId}`
        return this.http.delete(url)
    }

    patch(standTypeId: string, data) {
        let url =  `${endpointsUrls.property.standType}/${standTypeId}`
        return this.http.patch<StandType>(url, data)
    }

}