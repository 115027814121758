<div class="content-wrapper position-relative min-vh-100">
    <maforet-header></maforet-header>
   
    <ng-container *ngIf="coreService.Selector$({type:'uiState'}) | async as uiState">
        <div class="page-spinner" *ngIf="uiState.loadingSpinner">
            <mff-spinner class="m-5 p-5"></mff-spinner>
        </div>
    </ng-container>
    
    <mff-app-toasts></mff-app-toasts>
    <router-outlet></router-outlet>

</div>