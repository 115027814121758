import { Injectable } from '@angular/core';
import { Observable, merge, throwError } from 'rxjs'
import { map, filter, switchMap, tap, mergeMap, delay } from 'rxjs/operators'

import { AppStore } from '../../application-store'
import { StandsFetcher } from './stands.communication';
import { PropertyService } from '../Property/property.service';
import { Stand } from './stand.typing';
import mergeDeep from '@mf-framework/utils/objects';

@Injectable({
    providedIn: 'root'
})
export class StandsService {
    constructor(
        private appStore: AppStore,
        private standsFetcher : StandsFetcher,
        private propertyService : PropertyService,
    ) { }

    selectStand$(payload) : Observable<Stand> {
        return this.propertyService.selectProperty$({id:payload.propertyId})
        .pipe(
            switchMap(() => this.appStore.store$),
            mergeMap(data => data.model.properties.collection),
            filter(property => property.id == payload.propertyId),
            mergeMap(property => property.stands),
            filter(stand => stand.id == payload.standId),
        )
    }

    async getStand(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const standRef = property.stands.find(c => c.id == payload.standId)
            const standRefIndex = property.stands.indexOf(standRef)

            try {
                let httpQuery = this.standsFetcher.get(payload.standId)
                .toPromise()

                    httpQuery.then(
                        updatedStand => {
                            state.model.properties.collection[index].stands[standRefIndex] = {
                                ...standRef,
                                ...updatedStand
                                // ...mergeDeep(standRef,updatedStand)
                            }
                            this.appStore.setState(state)
                        }
                    )

                return await httpQuery
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `getStand : Unidentified Property with id [${payload.propertyId}]`}
    }

    async addStand(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const index = state.model.properties.collection.indexOf(property)
            
            return await this.standsFetcher.create(payload)
            .toPromise()
            .then(
                newStand => {
                    state.model.properties.collection[index] = {
                        ...property,
                        stands : [
                            ...property.stands,
                            newStand
                        ]
                    }

                    this.appStore.setState(state)
                    return newStand
                }
            )
        }
        else {throw `addStand : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchStandGeographicalFeature(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const standRef = property.stands.find(c => c.id == payload.standId)
            const standRefIndex = property.stands.indexOf(standRef)
            const unitRef = standRef.geographicalFeatures[payload.type].find(u => u.codeUnique == payload.codeUnique)
            const unitRefIndex = standRef.geographicalFeatures[payload.type].indexOf(unitRef)
            
            state.model.properties.collection[index].stands[standRefIndex].geographicalFeatures[payload.type][unitRefIndex] = {
                ...unitRef,
                intersectionArea : {
                    ...unitRef.intersectionArea,
                    input : payload.input
                }
            }

            this.appStore.setState(state)

            try {
                return await this.standsFetcher.patchGeographicalFeature(payload.standId, payload.type, payload.codeUnique, {input: payload.input})
                .toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
    }

    async patchStand(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const standRef = property.stands.find(c => c.id == payload.standId)
            const standRefIndex = property.stands.indexOf(standRef)

            state.model.properties.collection[index].stands[standRefIndex] = {
                ...standRef,
                // ...payload.data
                ...mergeDeep(standRef,payload.data)
            }
            this.appStore.setState(state)

            try {
                let httpQuery = this.standsFetcher.patch(payload.standId,payload.data)
                .toPromise()
                if(payload.updateModel){
                    httpQuery.then(
                        updatedStand => {
                            state.model.properties.collection[index].stands[standRefIndex] = {
                                ...standRef,
                                ...updatedStand
                                // ...mergeDeep(standRef,updatedStand)
                            }
                            this.appStore.setState(state)
                        }
                    )
                }
                return await httpQuery
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `patchStand : Unidentified Property with id [${payload.propertyId}]`}
    }

    async deleteStand(payload) {
        const state = this.appStore.getState()
        const propertyId = payload.propertyId
        const property = state.model.properties.collection.find(p => p.id === propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const newStandsArray = property.stands.filter(stand => stand.id != payload.standId)
            state.model.properties.collection[index] = {
                ...property,
                stands : newStandsArray
            }
            this.appStore.setState(state)

            try {
                await this.standsFetcher.delete(payload.standId).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `deleteStand : Unidentified Property with id [${payload.propertyId}]`}
    }

    async standGeometryUpdateJob(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            // const backup = JSON.stringify(property)
            // property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const standRef = property.stands.find(c => c.id == payload.standId)
            const standRefIndex = property.stands.indexOf(standRef)

            // state.model.properties.collection[index].stands[standRefIndex].geometryUpdateJob = {
            //     ...standRef,
            //     // ...payload.data
            //     ...mergeDeep(standRef,payload.data)
            // }
            // this.appStore.setState(state)

            try {
                let httpQuery = this.standsFetcher.standGeometryUpdateJob(payload.jobId)
                .toPromise()
                // if(payload.updateModel){
                    httpQuery.then(
                        updatedJob => {
                            state.model.properties.collection[index].stands[standRefIndex].geometryUpdateJob = updatedJob
                            this.appStore.setState(state)
                        }
                    )
                // }
                return await httpQuery
            } 
            catch (error) {
                // state.model.properties.collection[index] = JSON.parse(backup)
                // this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
    }

}