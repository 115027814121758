import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LandEquipment } from './landEquipment.typing';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';

@Injectable({
    providedIn: 'root',
})
export class LandEquipmentsFetcher {
    constructor(private http: HttpClient) { }
    
    create(data) {
        let url =  endpointsUrls.property.landEquipment
        return this.http.post<LandEquipment>(url, data)
    }

    delete(landEquipmentId) {
        let url =  `${endpointsUrls.property.landEquipment}/${landEquipmentId}`
        return this.http.delete(url)
    }

    patch(landEquipmentId: string, data) {
        let url =  `${endpointsUrls.property.landEquipment}/${landEquipmentId}`
        return this.http.patch<LandEquipment>(url, data)
    }
}