import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { environment } from '@current-environment/environment'
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppToastService } from '@mf-framework/interface/app-toast/app-toast.service';
import { AppModalService } from '@mf-framework/interface/app-modal/app-modal.service';
import { Router } from '@angular/router';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { Injectable } from "@angular/core";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        public appToastService: AppToastService,
        public appModalService: AppModalService,
        private router: Router,
    ) {}

    doNotNotify(url){
        let exceptions =  [
            endpointsUrls.user.refreshToken, 
            endpointsUrls.user.userLogin, 
            endpointsUrls.user.userRegister, 
            endpointsUrls.lots.intersects, 
            endpointsUrls.lots.localitySearch,
            endpointsUrls.cadxmap.fr,
            endpointsUrls.cadxmap.be
            // ,"create-mandate-request"
        ]
        for (let index = 0; index < exceptions.length; index++) {
            if(url.includes(exceptions[index]))return true
        }
        return false
    }

    isErrorCrippling(request) {
        if(request && request.method && request.method.toUpperCase() == "GET"){
            let exceptions =  []
            
            if(!environment.storeLocalState){
                exceptions =  [endpointsUrls.references.private,endpointsUrls.property.property]
            }

            for (let index = 0; index < exceptions.length; index++) {
                if(request.url.includes(exceptions[index]))return true
            }
        }
        return false
    }

    // Accept: application/json;version=3.1,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/zip,text/csv,text/plain
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let options = {}
            options["setHeaders"] =  { 
                // "X-AppId": environment.appId,
                "Accept": `application/json;version=${environment.apiVersion},application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/zip,text/csv,text/plain`
            } 
        const modified = request.clone(options)        
        return next.handle(modified)
            .pipe(
                tap(evt => {
                    if (evt instanceof HttpResponse) {
                        if(request.method && evt.status && !this.doNotNotify(request.url)){
                            switch(request.method){
                                case "POST" : 
                                    this.appToastService.notifyCreate();
                                break;
                                case "PUT" : 
                                case "PATCH" : 
                                    this.appToastService.notifyUpdate();
                                break;
                                case "DELETE" : 
                                    this.appToastService.notifyDelete();
                                break;
                            }
                        }
                    }
                    
                }),
                // retry(1),
                catchError((error: HttpErrorResponse) => {
                    console.log("interceptor")
                    // console.log("catchError",error)
                    // console.log(error.error)
                    // console.log(error.error instanceof ErrorEvent)
                    // client-side error
                    if (error.error instanceof ErrorEvent) {
                        console.log("notifyError simple")
                        this.appToastService.notifyError(error)
                        if(this.isErrorCrippling(request)){
                            this.appModalService.alertCripplingError()
                        }
                    } 
                    // server-side error
                    else {
                        if(error.status != 401) {
                            if(this.isErrorCrippling(request)){
                                this.appModalService.alertCripplingError()
                            }

                            // errorType = 'server-side'
                            // errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                            if(!this.doNotNotify(request.url)){
                                this.appToastService.notifyHttpError()
                            }
                        }
                    }

                    return throwError(error);
                    // return throwError({type:errorType,error:error,message:errorMessage});
                })
            )
    }
}