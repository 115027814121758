import { Injectable } from '@angular/core';
import { merge, throwError } from 'rxjs'
import { map, filter, switchMap, tap } from 'rxjs/operators'
import { AppStore } from '../../application-store'
import { ProfileFetcher } from './profile.communication';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    ContactsFetcher: any;
    constructor(
        public appStore: AppStore,
        public profileFetcher: ProfileFetcher,
    ) {}
    
    setUserProfileUnFetched() {
        const state = this.appStore.getState()
        state.userProfile = {
            ...state.userProfile,
            fetched: false,
            fetching: false
        }
        this.appStore.setState(state)
    }

    setUserProfileData(profile){
        const state = this.appStore.getState()

        state.userProfile = {
            data : profile,
            fetched : true,
            fetching : false
        }
        this.appStore.setState(state)
    }

    setUserProfileFetching() {
        const state = this.appStore.getState()
        state.userProfile = {...state.userProfile,fetching: true}
        this.appStore.setState(state)
    }

    selectProfile$() {
        const userProfile = this.appStore.store$.pipe(
            map(data => data.userProfile)
        )
  
        const notFetched = userProfile.pipe(
            filter(userProfile => !userProfile.fetched && !userProfile.fetching),
            tap(userProfile => this.setUserProfileFetching()),
            switchMap(_ => this.profileFetcher.index()),
            tap(profile => this.setUserProfileData(profile)),
        )
        
        const fetched = userProfile.pipe(
            filter(userProfile => userProfile.fetched === true),
            map(userProfile => userProfile.data),
        )

        return merge(
            notFetched,
            fetched
        )
    }

    async patchProfile(payload) {
        const state = this.appStore.getState()
        const backup = JSON.stringify(state.userProfile)
        
        state.userProfile = {
            ...state.userProfile,
            data : {
                ...state.userProfile.data,
                ...payload
            }
        }
        this.appStore.setState(state)

        try {
            return await this.profileFetcher.patch(payload).toPromise()
        } 
        catch (error) {
            state.userProfile = JSON.parse(backup)
            this.appStore.setState(state)
            return throwError({error:error}).toPromise()
        }

    }

    
}

