<div class="app-content-wrapper" *ngIf="coreService.Selector$({type:'uiState'}) | async as uiState">

    <div class="screen-splitter-wrapper" [ngClass]="screenSplitterHeight"
    *ngIf="coreService.Selector$({type:'uiState'}) | async as uiState">

        <div class="screen-splitter-top">

            <mff-base-map #currentMapComponent (mapReady)="mapReady($event)" [(layers)]="mapLayers" (screenSplitterHeightSetted)="screenSplitterHeightSetted($event)">
            </mff-base-map>

        </div>

        <div class="screen-splitter-bottom p-4">

            <div class="row align-items-center">
                <div class="col-2">
                  <h4 class="m-0 align-middle">Propriétés</h4>
                </div>
                <div class="col-4">
                  <div class="input-group ml-2">
                    <div class="input-group-prepend">
                      <i class="material-icons notranslate">search</i>
                    </div>
                    <input type="text" class="form-control form-control-sm" placeholder="rechercher par nom, localité ou propriétaire" (input)="filterProperties($event)">
                  </div>
                </div>
                <div class="col-6">
                    <button class="btn btn-success float-right right-aligned m-0" routerLink="/properties-create">
                        <i class="material-icons notranslate">add</i>
                        Créer une propriété
                    </button>
                </div>

            </div>

            <div class="screen-splitter-bottom-sub-scrollable px-4">

                <table class="table table-responsive-lg table-hover table-sticky-header mb-0">
                    <thead class="propertiesTableHead">
                        <tr class="border-top-0">
                            <th style="width: 25%" class="pt-0 pl-0">Propriété</th>
                            <th style="width: 5%" class="pt-0">Superficie</th>
                            <th style="width: 20%" class="pt-0">Propriétaires</th>
                            <th style="width: 20%" class="pt-0">Gestionnaire</th>
                            <th style="width: 30%" class="pt-0 pr-0">
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr *ngIf="!filteredProperties || !filteredProperties.length">
                            <td colspan="10" class="text-center text-muted font-italic">Aucune propriété trouvée</td>
                        </tr>

                        <tr class="hoverPaddFirstCol" *ngFor="let property of filteredProperties; trackBy: propertiesTrackFn">
                            <td class="pl-0">
                                <a class="pointer" (click)="jumpToProperty(property.id)">
                                    <div class="text-small">{{ property.locality.name }}</div>
                                    <strong>{{ property.name }}</strong>
                                </a>
                            </td>

                            <td class="text-small">
                                <mff-surface [area]="getPropertyCurrentArea(property)"></mff-surface>
                            </td>

                            <td class="text-small">
                                <ul class="comma-separated-list p-0 mb-0">
                                    <li class="font-italic" *ngFor="let owner of getPropertyOwners(property)">
                                        {{getContactName(owner)}}</li>
                                </ul>
                            </td>
                            <td class="text-small">
                                {{property.userMandate.creator_email}}
                            </td>
                            <td class="text-right">

                                <button class="btn-circle btn-primary ml-2 mb-1"
                                    ngbTooltip="Décrire" container="body" placement="left" routerLink="/property/{{property.id}}">
                                    <i class="material-icons notranslate align-top mt-0">description</i>
                                </button>
                                <!-- <button class="btn-circle btn-primary ml-2 mb-1"
                                    ngbTooltip="Cadastre" container="body" placement="left" routerLink="/property/{{property.id}}/cadaster">
                                    <i class="material-icons notranslate align-top mt-0">dashboard</i>
                                </button>
                                <button class="btn-circle btn-primary ml-2 mb-1"
                                    ngbTooltip="Parcelles forestières" container="body" placement="left" routerLink="/property/{{property.id}}/stands">
                                    <i class="material-icons notranslate align-top mt-0">nature</i>
                                </button>
                                <button class="btn-circle btn-primary ml-2 mb-1"
                                    ngbTooltip="Cartographie" container="body" placement="left" routerLink="/property/{{property.id}}/access">
                                    <i class="material-icons notranslate align-top mt-0">explore</i>
                                </button> -->
                                <!-- <button class="btn-circle btn-primary ml-2 mb-1"
                                    ngbTooltip="Plan de gestion" container="body" placement="left" routerLink="/property/{{property.id}}/management-plans">
                                    <i class="material-icons notranslate align-top mt-0">bar_chart</i>
                                </button> -->
                                <button class="btn-circle btn-primary ml-2 mb-1"
                                    ngbTooltip="Editer une carte" container="body" placement="left" routerLink="/property/{{property.id}}/cartography">
                                    <i class="material-icons notranslate align-top mt-0">map</i>
                                </button>
                                <button class="btn-circle btn-primary ml-2 mb-1"
                                    ngbTooltip="Exporter" container="body" placement="left" routerLink="/property/{{property.id}}/documents">
                                    <i class="material-icons notranslate align-top mt-0">import_export</i>
                                </button>

                                <button class="btn-circle btn-warning ml-2 mb-1 position-relative"
                                    ngbTooltip="Partager" container="body" placement="left" routerLink="/property/{{property.id}}/mandate" *ngIf="doesUserHaveGrantingPrivileges(property)">
                                    <i class="material-icons notranslate align-top mt-0">share</i>

                                    <div class="nbMandates" *ngIf="property.mandates.length">
                                        {{property.mandates.length}}
                                    </div>
                                </button>

                                <button class="btn-circle btn-warning ml-2 mb-1"
                                    container="body" placement="left" *ngIf="!doesUserHaveGrantingPrivileges(property)" disabled>
                                    <i class="material-icons notranslate align-top mt-0">share</i>
                                </button>

                                <button class="btn-circle btn-danger ml-2 mb-1"
                                    ngbTooltip="Supprimer" container="body" placement="left" (click)="propertyDeleteConfirmCall(deletePropertyConfirmModal,property)" *ngIf="doesUserHaveGrantingPrivileges(property)">
                                    <i class="material-icons notranslate align-top mt-0">delete</i>
                                </button>
                                <button class="btn-circle btn-danger ml-2 mb-1"
                                    ngbTooltip="Ne plus accéder à cette propriété" container="body" placement="left" (click)="withdrawMandateConfirmCall(withdrawMandateConfirmModal,property)" *ngIf="!doesUserHaveGrantingPrivileges(property)">
                                    <i class="material-icons notranslate align-top mt-0">delete</i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>

</div>

<ng-template #deletePropertyConfirmModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">SUPPRESSION D'UNE PROPRIÉTÉ</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            <strong>
                Êtes-vous sûr(e) de vouloir supprimer la propriété &laquo; {{ deletionPropertyName }} &raquo; ?
            </strong>
            <br>
            <span class="text-danger">
                Attention, les données effacées ne seront pas récupérables.
            </span>
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default left-aligned" (click)="modal.dismiss('Cancel click')">
            <i class="material-icons notranslate">cancel</i>
            Annuler
        </button>
        <button type="button" class="btn btn-danger right-aligned" (click)="modal.close('Confiirm click')">
            <i class="material-icons notranslate">delete_forever</i>
            Supprimer définitivement
        </button>
    </div>
</ng-template>

<ng-template #withdrawMandateConfirmModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">SUPPRESSION DU PARTAGE</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            <strong>
                Etes-vous certain de vouloir supprimer le partage de la propriété &laquo; {{ deletionPropertyName }} &raquo; ? Si vous confirmez, cette propriété n'apparaitra plus dans la liste des propriétés.
            </strong>
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default left-aligned" (click)="modal.dismiss('Cancel click')">
            <i class="material-icons notranslate">cancel</i>
            Annuler
        </button>
        <button type="button" class="btn btn-danger right-aligned" (click)="modal.close('Confiirm click')">
            <i class="material-icons notranslate">delete_forever</i>
            Confirmer
        </button>
    </div>
</ng-template>
