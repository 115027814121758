export function contactsSortingFunction(contact1, contact2) {
    let contactName1 = getContactName(contact1,"np")
    let contactName2 = getContactName(contact2,"np")
    if (contactName1 > contactName2) return 1;
    if (contactName1 < contactName2) return -1;
}

export function getContactAddress(contact) {
    let address = contact.address
    let result = []
    if(address.address1)result.push(address.address1)
    if(address.address2)result.push(address.address2)
    if(address.postalCode)result.push(address.postalCode)
    if(address.localityName)result.push(address.localityName)
    if(address.country)result.push(`(${address.country})`)
    return result.join(" ")
}

export function getContactInfos(contact) {
    let result = []
    if(contact.phone)result.push(contact.phone)
    if(contact.email)result.push(contact.email)
    return result.join(" ")
}

export function getContactName(contact,format="pn") {
    if(contact && contact.type) {
        if(contact.type == "person" && contact.person){
            let fullName = contact.person.familyName ? contact.person.familyName : ""
            
            if(contact.person.givenName){
                if(format=="np"){
                    fullName = fullName + " " + contact.person.givenName
                }
                if(format=="pn"){
                    fullName = contact.person.givenName + " " + fullName
                }
            }
            
            return fullName
        }
        else if(contact.organization) {
            return contact.organization.name
        }
    }
    return "Inconnu"
}

