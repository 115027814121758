import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '@current-environment/environment';

@Injectable({
  providedIn: 'root'
})
export class IsUnconfirmedGuard implements CanActivate, CanLoad {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate() {
        return this.canLoad();
    }

    canLoad() {
        if(environment.sessionManagement.type == "clientside"){
            if (this.authService.isLoggedIn() && this.authService.isConfirmed()) {
                this.router.navigate(['/dashboard']);
            }
            return !this.authService.isConfirmed();
        }
        else {
            return false
        }
    }
}