import { Injectable } from '@angular/core';
import { throwError } from 'rxjs'

import { AppStore } from '../../application-store'
import mergeDeep from '@mf-framework/utils/objects';
import { MapLayersVectorFetcher } from './mapLayersVector.communication';
import { MapLayerVector } from './mapLayerVector.typing';



@Injectable({
    providedIn: 'root'
})
export class MapLayersVectorService {
    constructor(
        private appStore: AppStore,
        private mapLayersVectorFetcher: MapLayersVectorFetcher,
    ) { }

    async addMapLayerVector(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const index = state.model.properties.collection.indexOf(property)
            return await this.mapLayersVectorFetcher.create(payload)
            .toPromise()
            .then(
                newMapLayerVector => {
                    state.model.properties.collection[index] = {
                        ...property,
                        mapLayersVector : [
                            ...property.mapLayersVector,
                            newMapLayerVector
                        ]
                    }

                    this.appStore.setState(state)
                    return newMapLayerVector
                }
            )
        }
        else {throw `addMapLayerVector : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchMapLayerVector(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const mapLayerVectorRef = property.mapLayersVector.find(o => o.id == payload.elementId)
            const mapLayerVectorRefIndex = property.mapLayersVector.indexOf(mapLayerVectorRef)
            
            state.model.properties.collection[index].mapLayersVector[mapLayerVectorRefIndex] = {
                ...mapLayerVectorRef,
                ...mergeDeep(mapLayerVectorRef,payload.data)
            }

            this.appStore.setState(state)

            try {
                return await this.mapLayersVectorFetcher.patch(payload.elementId,payload.data).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `patchMapLayerVector : Unidentified Property with id [${payload.propertyId}]`}
    }

    async deleteMapLayerVector(payload) {
        const state = this.appStore.getState()
        const propertyId = payload.propertyId
        const property = state.model.properties.collection.find(p => p.id === propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const newMapLayersVectorArray = property.mapLayersVector.filter(event => event.id != payload.id)

            state.model.properties.collection[index] = {
                ...property,
                mapLayersVector : newMapLayersVectorArray
            }
            this.appStore.setState(state)

            try {
                return await this.mapLayersVectorFetcher.delete(payload.id).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `deleteMapLayerVector : Unidentified Property with id [${payload.propertyId}]`}
    }
    

}