<div class="app-content-wrapper p-3 p-md-5 min-vh-50" *ngIf="coreService.Selector$({type:'uiState'}) | async as uiState">
    

        <h2 class="mb-3">Nouvelle propriété</h2>
        <hr class="mb-4">

        <div class="row" *ngIf="countries">

            <div class="col-md-8 order-md-0">

                
                <!-- <div class="mb-3">
                    <label class="d-block font-weight-bold" for="country">Région</label>

                    <div class="custom-control custom-radio custom-control-inline" *ngFor="let cntry of countries">
                        <input type="radio" class="form-control custom-control-input" [value]="cntry.key" id="cntry{{cntry.key}}"  [(ngModel)]="selectedCountry" (change)="updateFormCountry(cntry)" required>
                        <label class="custom-control-label" for="cntry{{cntry.key}}">
                            {{cntry.label}}
                        </label>
                    </div>
                    
                    <div class="invalid-feedback d-block" *ngIf="submitted && !selectedCountry">
                        Merci d'indiquer le pays dans lequel se situe la propriété
                    </div>
                </div> -->
                

                <form novalidate (ngSubmit)="onSubmit($event)" [formGroup]="newProperty">

                    <div class="mb-3">
                        <label class="font-weight-bold" for="city">Commune</label>

                        <input type="text" class="form-control" placeholder="Saint-Pierre" id="typeaheadCity"
                            name="typeaheadCity" [class.is-invalid]="citySearchFailed" formControlName="currentCity"
                            [ngbTypeahead]="searchCity" [resultFormatter]="cityResultFormatter"
                            [inputFormatter]="cityInputFormatter" required />

                        <em class="text-success" *ngIf="citySearching">recherche en cours...</em>

                        <div class="text-danger text-center p-2" *ngIf="citySearchFailed">
                            Désolé, aucun résultat trouvé.
                        </div>
                        
                        <!-- <div class="text-danger text-center p-2" *ngIf="sectionsSearchFailed">
                                Nous ne connaissons pas le cadastre de la commune sélectionnée, en conséquence l'application ne pourra pas proposer l'ensemble de ses fonctionnalités pour cette propriété.
                        </div> -->

                        <div class="invalid-feedback" *ngIf="submitted">
                            Merci d'indiquer la commune dans laquelle se situe la propriété
                        </div>
                    </div>

                    <div class="mb-3">
                        <label class="font-weight-bold" for="propertyName">Nom de la propriété</label>
                        <input type="text" class="form-control" placeholder="Bois Joli" name="propertyName" id="propertyName" formControlName="propertyName" required>

                        <div class="invalid-feedback" *ngIf="submitted">
                            Merci d'indiquer un nom pour la nouvelle propriété
                        </div>
                    </div>

                    <div class="d-flex justify-content-between">

                            <button type="button" routerLink="/dashboard"
                            class="btn btn-default left-aligned btn-lg mt-4">
                                <i class="material-icons notranslate">cancel</i>
                                Annuler
                            </button>

                            <button type="button" appNoDblClick class="btn btn-success btn-lg right-aligned mt-4 mb-3" (click)="onSubmit($event)">
                                <i class="material-icons notranslate">add</i>
                                Créer la propriété
                            </button>

                    </div>
                </form>

                <div class="alert alert-secondary text-small mt-3" *ngIf="selectedCountry=='BE-WAL'">
                    <h5>AVERTISSEMENT</h5>
                    La superposition d’informations provenant de différentes sources cartographiques peut présenter certains biais en matière de localisation et de description des parcelles cadastrales. L’information mise à disposition est donc donnée à titre exclusivement indicatif et Maforet.fr ne peut garantir que les informations fournies sont correctes, complètes ou actuelles.
                    <br>
                    La fiche cartographique réalisée par Maforet.fr n’a pas de valeur juridique.
                    <br>
                    Une validation sur le terrain et le cas échéant le recours à des géomètres et/ou experts sont conseillés au propriétaire qui cherche à localiser ou décrire précisément ses parcelles cadastrales et/ou forestières.
                </div>
            </div>


            <div class="col-md-4">

                <p class="font-italic text-muted">
                    <strong class="d-block">
                        CERTIFICATION PEFC
                    </strong>
                    
                    Maforet.fr permet la rédaction d’un document simple de gestion requis dans le cadre de la <a href="https://www.pefc-france.org/pefc-comment-ca-marche/" target="_blank">certification PEFC</a>. 
                    <br>
                    Les informations nécessaires pour répondre aux exigences de la charte PEFC sont mises en évidence par le logo <img class="pefc-mark" src="/assets/images/pefc-logo.svg">

                </p>

            </div>


        </div>

</div>