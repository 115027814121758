import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseMapComponent } from './base-map/base-map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { PropertyMapComponent } from './property-map/property-map.components';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InterfaceModule } from '@mf-framework/interface/interface.module';
import { FormsModule } from '@angular/forms';
import { DndModule } from 'ngx-drag-drop';

@NgModule({
  declarations: [BaseMapComponent,PropertyMapComponent],
  imports: [
    CommonModule,
    LeafletModule,
    NgbTooltipModule,
    FormsModule,
    InterfaceModule,
    DndModule
  ],
  exports: [BaseMapComponent,PropertyMapComponent]
})
export class MapModule { }
