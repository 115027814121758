import { doesUserHaveGrantingPrivileges } from "../Property/utils"

export function sortProperties(properties){
    return properties
    .sort((a,b) => {
        if(a.updatedAt && b.updatedAt){
            if(typeof a.updatedAt != "number")a.updatedAt = Date.parse(a.updatedAt)
            if(typeof b.updatedAt != "number")b.updatedAt = Date.parse(b.updatedAt)
            return b.updatedAt - a.updatedAt
        }
        else if(a.createdAt && b.createdAt) {
            if(typeof a.createdAt != "number")a.createdAt = Date.parse(a.createdAt)
            if(typeof b.createdAt != "number")b.createdAt = Date.parse(b.createdAt)
            return b.createdAt - a.createdAt
        }
    })
    .sort((a,b) => {
        let aGrant = doesUserHaveGrantingPrivileges(a)
        let bGrant = doesUserHaveGrantingPrivileges(b)
        if(aGrant && bGrant) return 0
        if(aGrant && !bGrant) return -1
        if(!aGrant && bGrant) return 1

    })
}