import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { of, Observable, throwError } from 'rxjs'
import { catchError, mapTo, tap } from 'rxjs/operators'
import { Tokens } from './tokens.typing'
import { environment } from '@current-environment/environment'
import { getApplicationId, getApiUrl } from '@mf-framework/utils/application'
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls'

// TODO : switch to a storage lib compatible with web app and ionic app. Maybe IonicStorage ?
// import { Storage } from '@ionic/storage';
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    readonly USER_INFOS = 'USER_INFOS'
    readonly JWT_TOKEN = 'JWT_TOKEN'
    readonly REFRESH_TOKEN = 'REFRESH_TOKEN'
    // private readonly JWT_PAYLOAD = 'JWT_PAYLOAD'

    constructor(
        public http: HttpClient,
        // private storage: Storage
    ) { }

    logout() {
        return this.http.get<any>(getApiUrl(environment,endpointsUrls.user.userLogout))
        .pipe(
            tap(() => {
                this.doLogoutUser()
            }),
            mapTo(true),
            catchError(error => {
                this.doLogoutUser()
                return of(false)
            })
        )
    }

    dryLogout() {
        this.doLogoutUser()
    }

    login(user: { email: string, password: string, app?:string }): Observable<any> {
        const appId = getApplicationId(environment)
        user.app = appId

        // if(!environment.production && isDefined(environment.cookieDomain)){
        //     user["cookie-domain"] = environment.cookieDomain
        // }

        return this.http.post<any>(getApiUrl(environment,endpointsUrls.user.userLogin), user)
            .pipe(
                tap((response: Tokens) => {
                    this.doLoginUser(response)
                }),
                mapTo({status:200}),
                catchError(error => {
                    return of(error)
                })
            )
    }

    
    refreshToken() {
        const appId = getApplicationId(environment)
        let params : {app:string,refreshToken?:string} = {
            app : appId
        }

        if(environment.sessionManagement.type == "clientside"){
            params.refreshToken =  this.getRefreshToken()
        }

        // if(!environment.production && isDefined(environment.cookieDomain)){
        //     params["cookie-domain"] = environment.cookieDomain
        // }
        
        return this.http.post<any>(getApiUrl(environment,endpointsUrls.user.refreshToken), params).pipe(
            tap((response: Tokens) => {
                this.doLoginUser(response)
            }),
            catchError(error => {
                return of(error)
            })
        )
    }

    register(user): Observable<any> {
        const appId = getApplicationId(environment)
        user.app = appId
        return this.http.post<any>(getApiUrl(environment,endpointsUrls.user.userRegister), user)
            .pipe(
                tap((response: Tokens) => this.doLoginUser(response)),
                mapTo({status:200}),
                catchError(error => {
                    return of(error)
                })
            )
    }

    unRegister(): Observable<any> {
        return this.http.get<any>(getApiUrl(environment,endpointsUrls.user.userUnRegister))
            .pipe(
                mapTo({status:200}),
                catchError(error => {
                    return of(error)
                })
            )
    }

    resendRegisterConfirmation(): Observable<any> {
        return this.http.get<any>(getApiUrl(environment,endpointsUrls.user.userResendConfirmation))
            .pipe(
                mapTo({status:200}),
                catchError(error => {
                    return of(error)
                })
            )
    }

    changeEmail(newemail): Observable<any> {
        return this.http.patch<any>(getApiUrl(environment,endpointsUrls.user.userChangeEmail),{email:newemail})
            .pipe(
                mapTo({status:200}),
                catchError(error => {
                    return of(error)
                })
            )
    }

    resetPassword(email): Observable<any> {
        const appId = getApplicationId(environment)
        let payload = {
            email : email
        }
        return this.http.post<any>(getApiUrl(environment,endpointsUrls.user.userResetPassword), payload)
            .pipe(
                mapTo({status:200}),
                catchError(error => {
                    return of(error)
                }))
    }
    
    changePassword(currentPassword,password): Observable<any> {
        const appId = getApplicationId(environment)
        let payload = {
            currentPassword : currentPassword,
            password : password
        }
        return this.http.post<any>(getApiUrl(environment,endpointsUrls.user.userChangePassword), payload)
            .pipe(
                tap((response: Tokens) => this.doLoginUser(response)),
                mapTo({status:200}),
                catchError(error => {
                    return of(error)
                })
            )
    }

    isConfirmed() {
        if(environment.sessionManagement.type == "clientside"){
            let userInfos = this.getUserInfos()
            if(userInfos && userInfos.confirmed)return true
            return false
        }
        else{
            return true
        }
    }

    isLoggedIn() {
        if(environment.sessionManagement.type == "clientside"){
            return !!this.getJwtToken()
        }
        else{
            return true
        }
    }

    getJwtToken() {
        return localStorage.getItem(this.JWT_TOKEN)
    }

    userInfos
    getUserInfos() {
        if(this.isLoggedIn()) {
            if(!this.userInfos){
                let infos = localStorage.getItem(this.USER_INFOS)
                try {
                    this.userInfos = JSON.parse(window.atob(infos))
                }
                catch (error) {
                    // this.dryLogout()
                    // window.location.replace("/")
                }
            }
            return this.userInfos
        }
    }

    getUserEmail() {
        let userInfos = this.getUserInfos()
        return userInfos && userInfos.email ? userInfos.email : ""
    }

    doLoginUser(response) {
        if(environment.sessionManagement.type == "clientside"){
            // this.storeJwtPayload(response.token)
            this.storeTokens(response)
            this.storeUserInfos(response.userInfos)
        }
    }

    doLogoutUser() {
        if(environment.sessionManagement.type == "clientside"){
            // this.removeJwtPayload()
            this.removeUserInfos()
            this.removeTokens()
            this.userInfos = undefined
        }
    }
    storeTokens(tokens: Tokens) {
        localStorage.setItem(this.JWT_TOKEN, tokens.maf_token)
        localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken)
    }

    removeTokens() {
        localStorage.removeItem(this.JWT_TOKEN)
        localStorage.removeItem(this.REFRESH_TOKEN)
    }
    
    getRefreshToken() {
        return localStorage.getItem(this.REFRESH_TOKEN)
    }

    storeUserInfos(userInfos) {
        localStorage.setItem(this.USER_INFOS, window.btoa(JSON.stringify(userInfos)))
    }
    removeUserInfos() {
        localStorage.removeItem(this.USER_INFOS)
    }

    // private storeJwtPayload(token) {
    //     if(!token)throw "No token provided !"

    //     let JWTpayload = JSON.parse(window.atob(token.split('.')[1]))
    //     // console.log(JWTpayload)
    //     // let iat = JWTpayload.iat
    //     // let exp = JWTpayload.exp
    //     // // console.log(exp)
        
    //     // var T = new Date()
    //     // var I = new Date(iat*1000)
    //     // var E = new Date(exp*1000)
    //     // // console.log(T.toLocaleString())
    //     // // console.log(I.toLocaleString())
    //     // // console.log(E.toLocaleString())
    //     // // console.log(Math.round(T.getTime()/1000))
    //     // console.log((exp-iat)/60,"mn")
        
    //     localStorage.setItem(this.JWT_PAYLOAD, JSON.stringify(JWTpayload))
    // }
    
    // private removeJwtPayload() {
    //     localStorage.removeItem(this.JWT_PAYLOAD)
    // }

    // private storeJwtToken(jwt: string) {
    //     localStorage.setItem(this.JWT_TOKEN, jwt)
    // }

    
}