import { Injectable } from '@angular/core';
import { Observable, merge, throwError } from 'rxjs'
import { map, filter, switchMap, tap, mergeMap } from 'rxjs/operators'

import { AppStore } from '../../application-store'
import { StandTypesFetcher } from './standTypes.communication';
import mergeDeep from '@mf-framework/utils/objects';

@Injectable({
    providedIn: 'root'
})
export class StandTypesService {
    constructor(
        private appStore: AppStore,
        private standTypesFetcher: StandTypesFetcher,
    ) {}
        
    async addStandType(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            
            try {
                return await this.standTypesFetcher.add(payload)
                .toPromise()
                .then(
                    newStandType => {
                        state.model.properties.collection[index] = {
                            ...property,
                            standTypes : [
                                ...property.standTypes,
                                newStandType
                            ]
                        }

                        this.appStore.setState(state)
                        return newStandType
                    }
                )
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
            }
        }
        else {throw `addStandType : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchStandType(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const standTypeRef = property.standTypes.find(c => c.id == payload.id)
            const standTypeRefIndex = property.standTypes.indexOf(standTypeRef)

            state.model.properties.collection[index].standTypes[standTypeRefIndex] = {
                ...standTypeRef,
                // ...payload.data
                ...mergeDeep(standTypeRef,payload.data)
            }

            this.appStore.setState(state)

            try {
                return await this.standTypesFetcher.patch(payload.id,payload.data)
                        .toPromise()
                        .then(result => {
                            state.model.properties.collection[index].standTypes[standTypeRefIndex] = {
                                // ...placeRef,
                                ...result
                            }
                            this.appStore.setState(state)
                        })
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `patchStandType : Unidentified Property with id [${payload.propertyId}]`}
    }

    async deleteStandType(payload) {
        const state = this.appStore.getState()
        const propertyId = payload.propertyId
        const property = state.model.properties.collection.find(p => p.id === propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const newStandTypesArray = property.standTypes.filter(standType => standType.id != payload.id)
            state.model.properties.collection[index] = {
                ...property,
                standTypes : newStandTypesArray
            }
            this.appStore.setState(state)

            try {
                await this.standTypesFetcher.delete(payload.id).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
    }
}

