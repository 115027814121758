import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Property } from '../Property/property.typing'
import { map, mergeMap, toArray, switchMap } from 'rxjs/operators';
import { processPropertyLots } from '../Lots/utils';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { throwError } from 'rxjs';
import { getApiUrl } from '@mf-framework/utils/application';
import { environment } from '@current-environment/environment';
import { properties_extraFields_summary, properties_extraFields_detailed, properties_extraFields_detailed_mobile } from './properties-extra_fields';

const PARAMS_SUMMARY = new HttpParams({
    fromObject: {
        "extra_fields": properties_extraFields_summary
    }
})

const PARAMS_DETAILED = new HttpParams({
    fromObject: {
        "extra_fields": properties_extraFields_detailed
    }
})

const PARAMS_DETAILED_MOBILE = new HttpParams({
    fromObject: {
        "extra_fields": properties_extraFields_detailed_mobile
    }
})

@Injectable({
    providedIn: 'root',
})
export class PropertiesFetcher {
    constructor(private http: HttpClient) { }

    index(extraFieldsLevel) {
        let params = PARAMS_SUMMARY
        if(extraFieldsLevel=="detailed")params = PARAMS_DETAILED
        if(extraFieldsLevel=="detailedMobile")params = PARAMS_DETAILED_MOBILE
        
        // let url =  endpointsUrls.property.properties
        let url =  getApiUrl(environment,endpointsUrls.property.properties)

        return this.http.get<Property[]>(url,{params : params})
        .pipe(
            mergeMap(Response => Response),
            map(item => {
                processPropertyLots(item)
                return item;
            }),
            toArray(),
        )
    }

    create(property: Property) {
        // let url =  endpointsUrls.property.property
        let url =  getApiUrl(environment,endpointsUrls.property.property)
        return this.http.post<Property>(url, property,{params : PARAMS_SUMMARY})
    }
    
    
    delete(id) {
        // let url =  `${endpointsUrls.property.property}/${id}`
        let url =  getApiUrl(environment,`${endpointsUrls.property.property}/${id}`)
        return this.http.delete(url)
    }
}