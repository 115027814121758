import { Injectable } from '@angular/core';
import { Observable, merge, of, throwError } from 'rxjs'
import { map, filter, switchMap, tap, mergeMap, catchError } from 'rxjs/operators'

import { AppStore } from '../../application-store'
import { ReferencesFetcher } from './references.communication';
import { UiService } from '@mf-framework/ui/ui.service';
import mergeDeep from '@mf-framework/utils/objects';
import { AppModalService } from '@mf-framework/interface/app-modal/app-modal.service';

@Injectable({
    providedIn: 'root'
})
export class ReferencesService {
    constructor(
        public appStore: AppStore,
        public uiService: UiService,
        public referencesFetcher: ReferencesFetcher,
        public appModalService: AppModalService,
    ) { }
    
    setReferencesFetching(isPublic?) {
        const state = this.appStore.getState()
        if(isPublic) {
            state.references = { ...state.references, publicfetching: true }
        }
        else{
            state.references = { ...state.references, fetching: true }
        }
        this.appStore.setState(state)
    }

    setReferencesData(referencesData,isPublic?) {
        const state = this.appStore.getState()
        state.references = {
            ...state.references,
            data: {
                ...state.references.data,
                ...referencesData
            },
            // fetched: true, fetching: false
        }
        if(isPublic) {
            state.references = { ...state.references, publicfetched: true, publicfetching: false }
        }
        else{
            state.references = { ...state.references, fetched: true, fetching: false }
        }

        this.appStore.setState(state,true)
    }

    // fetchReferencesMobile$() {
    //     return this.fetchReferences$().pipe(
    //         catchError(err => {
    //             if(err.status == 0){
    //                 let stored = JSON.parse(localStorage.getItem("STATE"))
    //                 if(stored){
    //                     if(stored.references.data){
    //                         return of(stored.references.data)
    //                     }
    //                 }
    //             }

    //             this.appModalService.alertNoConnexion()
    //             return throwError('Error!');
    //         })
    //     )
    // }

    fetchReferences$() {
        const references = this.appStore.store$.pipe(
            map(data => data.references)
        )

        const notFetched = references.pipe(
            filter(references => !references.fetched && !references.fetching),
            tap(_ => this.setReferencesFetching()),
            switchMap(_ => this.referencesFetcher.index()),
            tap(referencesData => this.setReferencesData(referencesData)),
            // switchMap(_ => references),
        )

        const fetched = references.pipe(
            filter(references => references.fetched === true),
            map(refs => refs.data),
        )

        return merge(
            notFetched,
            fetched
        )
    }
    
    fetchPublicReferences$() {
        const references = this.appStore.store$.pipe(
            map(data => data.references)
        )

        const notFetched = references.pipe(
            filter(references => !references.publicfetched && !references.publicfetching),
            tap(_ => this.setReferencesFetching("public")),
            switchMap(_ => this.referencesFetcher.publicIndex()),
            tap(referencesData => this.setReferencesData(referencesData,"public")),
        )

        const fetched = references.pipe(
            filter(references => references.publicfetched === true),
            map(refs => refs.data),
        )

        return merge(
            notFetched,
            fetched
        )
    }

}