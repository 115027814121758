import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@current-environment/environment'
import { Stand } from './stand.typing';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { Job } from '@mf-framework/utils/jobs.typing';

const PARAMS = new HttpParams({
    fromObject: {
        "extra_fields" : "geometry,components,standType,speciesMeasures,huntingContract,geographicalFeatures,context,biodiversityMeasures,centroid,job"
    }
})

@Injectable({
    providedIn: 'root',
})
export class StandsFetcher {
    constructor(private http: HttpClient) { }

    create(data) {
        let url =  endpointsUrls.property.stand
        return this.http.post<Stand>(url, data, {params : PARAMS})
    }
    
    get(standId) {
        let url =  `${endpointsUrls.property.stand}/${standId}`
        return this.http.get<Stand>(url, {params : PARAMS})
    }

    patch(id: string, data) {
        let url =  `${endpointsUrls.property.stand}/${id}` 
        return this.http.patch<Stand>(url, data, {params : PARAMS})
    }

    patchGeographicalFeature(standId, type, codeUnique, data) {
        // api/stand/{{stand}}/geographical-feature/{{type}}/{{codeUnique}}
        let url =  `${endpointsUrls.property.stand}/${standId}/geographical-feature/${type}/${codeUnique}` 
        return this.http.patch<Stand>(url, data)
    }
    
    delete(id: string) {
        let url =  `${endpointsUrls.property.stand}/${id}` 
        return this.http.delete<Stand>(url)
    }
    
    standGeometryUpdateJob(jobId) {
        // api/stand/{{stand}}/geographical-feature/{{type}}/{{codeUnique}}
        let url =  `${endpointsUrls.property.job}/${jobId}` 
        return this.http.get<Job>(url, {params : PARAMS})
    }
}
