import * as L from 'leaflet';
import { WMSOptions } from 'leaflet';
import { MapLayer } from '@mf-framework/map-layers/mapLayer.typing';
import { environment } from '../environments/environment';

export const IGN_TOPO_URL = '//data.geopf.fr/wmts?LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'
export let LAYER_IGN_CADASTER: MapLayer = {
    id: 'igncadaster',
    name: 'Cadastre',
    selected: false,
    cadaster: true,
    displayOpacity: 100,
    // zIndex:100,
    printLayers: [{name: 'fr.geoportail:CADASTRALPARCELS.PARCELLAIRE_EXPRESS', isTransparent: true}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?layer=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'
    , {
        maxNativeZoom: 18,
        maxZoom: 21,
        opacity: 1
    })]
};

export let LAYER_IGN_ORTHO: MapLayer = {
    id: 'ignortho',
    name: 'Vue aérienne',
    selected: true,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:HR.ORTHOIMAGERY.ORTHOPHOTOS'}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=ORTHOIMAGERY.ORTHOPHOTOS&EXCEPTIONS=text/xml&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'
        , {
            maxNativeZoom: 18,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_ORTHOIMAGERYORTHOPHOTOSIRC: MapLayer = {
    id: 'ignorthoimageryorthophotosirc',
    name: 'Vue aérienne Infrarouge',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:ORTHOIMAGERY.ORTHOPHOTOS.IRC'}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=ORTHOIMAGERY.ORTHOPHOTOS.IRC&EXCEPTIONS=text/xml&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'
        , {
            maxNativeZoom: 18,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_PROTECTEDAREA: MapLayer;
LAYER_IGN_PROTECTEDAREA = {
  id: 'ignprotectedarea',
  name: 'Natura 2000',
  selected: false,
  displayOpacity: 70,
  // zIndex:10,
  printLayers: [{name: 'fr.geoportail:PROTECTEDAREAS.SIC', isTransparent: true}, {
    name: 'fr.geoportail:PROTECTEDAREAS.ZPS',
    isTransparent: true
  }],
  layers: [
    L.tileLayer('//data.geopf.fr/wmts?LAYER=PROTECTEDAREAS.SIC&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=PROTECTEDAREAS.SIC&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'
      , {
        maxNativeZoom: 18,
        maxZoom: 21,
        opacity: 1
      }
    ),
    L.tileLayer('//data.geopf.fr/wmts?LAYER=PROTECTEDAREAS.ZPS&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=PROTECTEDAREAS.ZPSnormal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'
      , {
        maxNativeZoom: 18,
        maxZoom: 21,
        opacity: 1
      }
    )
  ]
};

export let LAYER_IGN_RESERVESREGIONALES: MapLayer = {
  id: 'ignreservesregionales',
  name: 'Réserves naturelles régionales',
  selected: false,
  displayOpacity: 100,
  printLayers: [{name: 'fr.geoportail:PROTECTEDSITES.MNHN.RESERVES-REGIONALES', isTransparent: true}],
  layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=PROTECTEDSITES.MNHN.RESERVES-REGIONALES&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=PROTECTEDSITES.MNHN.RESERVES-REGIONALES&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'

    , {
      maxNativeZoom: 18,
      maxZoom: 21,
      opacity: 1
    })]
};

export let LAYER_IGN_PROTECTEDAREASRB: MapLayer = {
    id: 'ignprotectedareasrb',
    name: 'Réserves biologiques',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:PROTECTEDAREAS.RB', isTransparent: true}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=PROTECTEDAREAS.RB&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'

        , {
            maxNativeZoom: 18,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_PROTECTEDAREASPN: MapLayer = {
    id: 'ignprotectedareaspn',
    name: 'Parcs nationaux',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:PROTECTEDAREAS.PN', isTransparent: true}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=PROTECTEDAREAS.PN&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=PROTECTEDAREAS.PN&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'

        , {
            maxNativeZoom: 18,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_TOPO: MapLayer = {
    id: 'igntopo',
    name: 'Plan IGN',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2'}],
    layers: [L.tileLayer(IGN_TOPO_URL

        , {
            maxNativeZoom: 18,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_TOPOCLASSIC: MapLayer = {
    id: 'igntopoclassic',
    name: 'Carte IGN Scan25',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:GEOGRAPHICALGRIDSYSTEMS.MAPS'}],
    layers: [L.tileLayer('//data.geopf.fr/private/wmts?apikey='+environment.ignApiKey+'&LAYER=GEOGRAPHICALGRIDSYSTEMS.MAPS&EXCEPTIONS=text/xml&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'

        , {
            maxNativeZoom: 18,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_TERRAIN: MapLayer = {
    id: 'ignterrain',
    name: 'Pentes',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:GEOGRAPHICALGRIDSYSTEMS.SLOPES.MOUNTAIN'}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=GEOGRAPHICALGRIDSYSTEMS.SLOPES.MOUNTAIN&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'

        , {
            maxNativeZoom: 14,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_BRGM_GEOLOGY: MapLayer = {
    id: 'brgmgeology',
    name: 'Carte géologique BRGM',
    selected: false,
    displayOpacity: 100,
    layers: [L.tileLayer.wms('//geoservices.brgm.fr/geologie'
        , {
            maxNativeZoom: 18,
            maxZoom: 21,
            opacity: 1,
            layers: 'GEOLOGIE',
        })]
};

export let LAYER_OPENSTREETMAP_TOPO: MapLayer = {
    id: 'openstreetmaptopo',
    name: 'Carte OpenStreetMap',
    selected: false,
    displayOpacity: 100,
    layers: [L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

        , {
            maxNativeZoom: 18,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_ORTHO20002005: MapLayer = {
    id: 'ignortho20002005',
    name: 'Vue aérienne 2000-2005',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:ORTHOIMAGERY.ORTHOPHOTOS2000-2005'}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=ORTHOIMAGERY.ORTHOPHOTOS2000-2005&EXCEPTIONS=text/xml&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'

        , {
            maxNativeZoom: 18,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_LANDCOVER19872004: MapLayer = {
    id: 'ignlandcover19872004',
    name: 'Carte forestière 1987-2004',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:LANDCOVER.FORESTINVENTORY.V1'}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=LANDCOVER.FORESTINVENTORY.V1&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'

        , {
            maxNativeZoom: 18,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_LANDCOVER2006: MapLayer = {
    id: 'ignlandcover2006',
    name: 'Carte forestière 2006',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:LANDCOVER.FORESTINVENTORY.V2'}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=LANDCOVER.FORESTINVENTORY.V2&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=LANDCOVER.FORESTINVENTORY.V2&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'

        , {
            maxNativeZoom: 18,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_SYLVOECOREGIONS: MapLayer = {
    id: 'ignsylvoecoregions',
    name: 'SYLVOECOREGIONS',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:LANDCOVER.SYLVOECOREGIONS'}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=LANDCOVER.SYLVOECOREGIONS&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'

        , {
            maxNativeZoom: 18,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_FORETSPUBLIQUES: MapLayer = {
  id: 'ignforetspubliques',
  name: 'Forêts publiques',
  selected: false,
  displayOpacity: 100,
  printLayers: [ {name: 'fr.geoportail:FORETS.PUBLIQUES'} ],
  layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=FORETS.PUBLIQUES&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=FORETS PUBLIQUES ONF&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'

    , {
      maxNativeZoom: 18,
      maxZoom: 21,
      opacity: 1
    })]
};

export let LAYER_IGN_DEBROUSSAILLEMENT: MapLayer = {
  id: 'igndebroussaillement',
  name: 'Obligations légales de débroussaillement',
  selected: false,
  displayOpacity: 100,
  printLayers: [ {name: 'fr.geoportail:DEBROUSSAILLEMENT'} ],
  layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=DEBROUSSAILLEMENT&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=nolegend&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'

    , {
      maxNativeZoom: 18,
      maxZoom: 21,
      opacity: 1
    })]
};

export let LAYER_IGN_HYDROGRAPHY: MapLayer = {
    id: 'ignhydrography',
    name: 'Hydrographie',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:BDCARTO-GEOPO-HYDROGRAPHIE_WLD_WGS84G', isTransparent: true}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=HYDROGRAPHY.HYDROGRAPHY&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'
        , {
            maxNativeZoom:14,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_UTILITYANDGOVERNMENTALSERVICESALL: MapLayer = {
    id: 'ignutilityandgovernmentalservicesall',
    name: 'Lignes électriques',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:BDTOPO-GEOPO-TRANSPORT_ENERGIE_WLD_WGS84G', isTransparent: true}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=UTILITYANDGOVERNMENTALSERVICES.ALL&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'
        , {
            maxNativeZoom: 18,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_TRANSPORTNETWORKSRAILWAYS: MapLayer = {
    id: 'igntransportnetworksrailways',
    name: 'Réseau ferroviaire',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:BDTOPO-GEOPO-TRANSPORT_VOIES_FERREES_WLD_WGS84G', isTransparent: true}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=TRANSPORTNETWORKS.RAILWAYS&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'
        , {
            maxNativeZoom:14,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_TRANSPORTNETWORKSROADS: MapLayer = {
    id: 'igntransportnetworksroads',
    name: 'Réseau routier',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:BDCARTO-GEOPO-RESEAU_ROUTIER_WLD_WGS84G', isTransparent: true}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=TRANSPORTNETWORKS.ROADS&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'
        , {
            maxNativeZoom:14,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_BUILDINGSBUILDINGS: MapLayer = {
    id: 'ignbuildingsbuildings',
    name: 'Batiments',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:BDTOPO-GEOPO-BATI_WLD_WGS84G', isTransparent: true}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=BUILDINGS.BUILDINGS&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'

        , {
            maxNativeZoom:18,
            maxZoom: 21,
            opacity: 1
        })]
};

export let LAYER_IGN_TRANSPORTNETWORKSRUNWAYS: MapLayer = {
    id: 'igntransportnetworksrunways',
    name: 'Aéroports',
    selected: false,
    displayOpacity: 100,
    printLayers: [{name: 'fr.geoportail:TN.AirTransportNetwork', isTransparent: true}],
    layers: [L.tileLayer('//data.geopf.fr/wmts?LAYER=TRANSPORTNETWORKS.RUNWAYS&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'

        , {
            maxNativeZoom:18,
            maxZoom: 21,
            opacity: 1
        })]
};

export let layersLibrary: MapLayer[] = [
    LAYER_IGN_CADASTER,
    LAYER_IGN_ORTHO,
    LAYER_IGN_ORTHO20002005,
    LAYER_IGN_ORTHOIMAGERYORTHOPHOTOSIRC,
    LAYER_IGN_TOPO,
    LAYER_IGN_TOPOCLASSIC,
    LAYER_IGN_TERRAIN,
    LAYER_BRGM_GEOLOGY,
    LAYER_OPENSTREETMAP_TOPO,
    {
        categoryLabel: 'Cartes forestières',
        deployed: false,
        layersCollection: [
            LAYER_IGN_FORETSPUBLIQUES,
            LAYER_IGN_DEBROUSSAILLEMENT,
            LAYER_IGN_LANDCOVER2006,
            LAYER_IGN_LANDCOVER19872004,
            LAYER_IGN_SYLVOECOREGIONS
        ]
    },
    {
        categoryLabel: 'Cartes environnementales',
        deployed: false,
        layersCollection: [
            LAYER_IGN_PROTECTEDAREA,
            LAYER_IGN_RESERVESREGIONALES,
            LAYER_IGN_PROTECTEDAREASRB,
            LAYER_IGN_PROTECTEDAREASPN,
            LAYER_IGN_HYDROGRAPHY
        ]
    },
    {
        categoryLabel: 'Infrastructures',
        deployed: false,
        layersCollection: [
            LAYER_IGN_UTILITYANDGOVERNMENTALSERVICESALL,
            LAYER_IGN_TRANSPORTNETWORKSRAILWAYS,
            LAYER_IGN_TRANSPORTNETWORKSROADS,
            LAYER_IGN_BUILDINGSBUILDINGS,
            LAYER_IGN_TRANSPORTNETWORKSRUNWAYS
        ]
    },
];
