import { Component } from '@angular/core';

@Component({
    selector: 'mff-crippling-error-modal',
    template: `
        <div class="p-5">
            <h4>
            Le service est actuellement indisponible
            </h4>
            Cette interruption est temporaire. Veuillez réessayer de charger l'application dans quelques instants.
        </div>
    `,
    styles: ['']
})
export class CripplingErrorModal{}