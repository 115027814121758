import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { environment } from '@current-environment/environment'
import { Lot } from './lot.typing';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { map } from 'rxjs/operators';
import { properties_extraFields_detailed } from '../Properties/properties-extra_fields';

const PARAMS = new HttpParams({
    fromObject: {
        "extra_fields" : properties_extraFields_detailed
    }
})

@Injectable({
    providedIn: 'root',
})
export class LotsFetcher {
    constructor(private http: HttpClient) { }

    get(propertyId) {
        // let url =  `${endpointsUrls.property.property}/${propertyId}/lots`
        let url =  `${endpointsUrls.property.property}/${propertyId}/lots`
        return this.http.get<Lot[]>(url, {params : PARAMS})
    }

    patch(lotId: string, data) {
        let url =  `${endpointsUrls.lots.lot}/${lotId}`
        return this.http.patch<Lot>(url, data)
    }

    uploadFile(data) {
        const formData = new FormData();
        formData.append('file', data.file)
        formData.append('async', "true")

        let url =  `${endpointsUrls.property.property}/${data.propertyId}/import/lots`
        return this.http.post<any>(
            url, 
            formData,
            {
                params : PARAMS,
                reportProgress: true,
                observe: 'events'
            }
        )
        .pipe(map((event) => {

            switch (event.type) {
      
              case HttpEventType.UploadProgress:
                const progress = Math.round(100 * event.loaded / event.total);
                console.log("upload progress",progress)
                return { status: 'progress', message: progress };
      
              case HttpEventType.Response:
                return event.body;
              default:
                return `Unhandled event: ${event.type}`;
            }
          })
        );
    }
}