export const TRUNKSIZE_UNIT_CM = "cm"
export type trunksize_unit_cm = typeof TRUNKSIZE_UNIT_CM
export type TrunkSizeUnit = trunksize_unit_cm

export const TRUNKSIZE_MEASUREMENT_CIR = "cir"
export type trunksize_measurement_cir = typeof TRUNKSIZE_MEASUREMENT_CIR
export const TRUNKSIZE_MEASUREMENT_DIA = "dia"
export type trunksize_measurement_dia = typeof TRUNKSIZE_MEASUREMENT_DIA
export type TrunkSizeMeasurement =  trunksize_measurement_cir | trunksize_measurement_dia | ""

export interface TrunkSize {
    value: number
    unit: TrunkSizeUnit
    measurement: TrunkSizeMeasurement
}

export type DendrometricsCount = number
export type DendrometricsBasal = number
export type DendrometricsHeightMax = number
export type DendrometricsHeightCom = number

export const SIZE_CATEGORY_SMALL = "small" // Petit bois
export type size_category_small = typeof SIZE_CATEGORY_SMALL
export const SIZE_CATEGORY_MEDIUM = "medium" // Bois moyen
export type size_category_medium = typeof SIZE_CATEGORY_MEDIUM
export const SIZE_CATEGORY_BIG = "big" // Gros bois
export type size_category_big = typeof SIZE_CATEGORY_BIG
export const SIZE_CATEGORY_VERY_BIG = "very_big" // Très gros bois
export type size_category_very_big = typeof SIZE_CATEGORY_VERY_BIG
export type DendrometricsSizeCategory = size_category_small | size_category_medium | size_category_big | size_category_very_big

export type VolumeValue = number

export const VOLUME_UNIT_M3 = "m3"
export type volume_unit_m3 = typeof VOLUME_UNIT_M3
export const VOLUME_UNIT_ST = "st"
export type volume_unit_st = typeof VOLUME_UNIT_ST
// export const VOLUME_UNIT_T = "t"
// export type volume_unit_t = typeof VOLUME_UNIT_T
// export const VOLUME_UNIT_CORD = "cord"
// export type volume_unit_cord = typeof VOLUME_UNIT_CORD
export type VolumeUnit = volume_unit_m3 | volume_unit_st /*| volume_unit_cord | volume_unit_t*/

export const VOLUME_MEASUREMENT_TOTAL = "volume_total"
export type volume_measurement_total = typeof VOLUME_MEASUREMENT_TOTAL

export const VOLUME_MEASUREMENT_WOOD = "volume_wood"
export type volume_measurement_wood = typeof VOLUME_MEASUREMENT_WOOD

export type VolumeMeasurement = volume_measurement_total | volume_measurement_wood | ""

export type VolumeShareLog = number

export interface Volume {
    value: VolumeValue
    unit: VolumeUnit
    measurement: VolumeMeasurement
    shareLog: VolumeShareLog
}

export const SHARE_UNIT_COUNT = "share_unit_count"
export type ShareUnitCount = typeof SHARE_UNIT_COUNT
export const SHARE_UNIT_VOLUME = "share_unit_volume"
export type ShareUnitVolume = typeof SHARE_UNIT_VOLUME
export const SHARE_UNIT_BASAL = "share_unit_basal"
export type ShareUnitBasal = typeof SHARE_UNIT_BASAL
export type ShareQualityUnit = ShareUnitCount | ShareUnitVolume | ShareUnitBasal

export type ShareQualityLumberValue = number
export interface ShareQualityLumber {
    value: ShareQualityLumberValue
    unit: ShareQualityUnit
}
export type ShareQualityIndustryValue = number
export interface ShareQualityIndustry {
    value: ShareQualityIndustryValue
    unit: ShareQualityUnit
}
export type ShareQualityEnergyValue = number
export interface ShareQualityEnergy {
    value: ShareQualityEnergyValue
    unit: ShareQualityUnit
}
    
// export type ShareLog = number

export interface Dendrometrics {
    count? : DendrometricsCount
    trunkSize?: TrunkSize
    basal?: DendrometricsBasal
    heightMax?: DendrometricsHeightMax
    heightCom?: DendrometricsHeightCom
    volume?:Volume
    // shareLog?: ShareLog
    shareQualityLumber?: ShareQualityLumber
    shareQualityIndustry?: ShareQualityIndustry
    shareQualityEnergy?: ShareQualityEnergy
    sizeCategory?: DendrometricsSizeCategory
}