import { Injectable } from '@angular/core';
import { throwError } from 'rxjs'

import { AppStore } from '../../application-store'
import { HealthEventsFetcher } from './healthEvents.communication';
import mergeDeep from '@mf-framework/utils/objects';


@Injectable({
    providedIn: 'root'
})
export class HealthEventsService {
    constructor(
        private appStore: AppStore,
        private healthEventsFetcher: HealthEventsFetcher,
    ) { }

    async addHealthEvent(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const index = state.model.properties.collection.indexOf(property)
            
            return await this.healthEventsFetcher.create(payload)
            .toPromise()
            .then(
                newHealthEvent => {
                    state.model.properties.collection[index] = {
                        ...property,
                        healthEvents : [
                            ...property.healthEvents,
                            newHealthEvent
                        ]
                    }

                    this.appStore.setState(state)
                    return newHealthEvent
                }
            )
        }
        else {throw `addHealthEvent : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchHealthEvent(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const healthEventRef = property.healthEvents.find(o => o.id == payload.elementId)
            const healthEventRefIndex = property.healthEvents.indexOf(healthEventRef)

            state.model.properties.collection[index].healthEvents[healthEventRefIndex] = {
                ...healthEventRef,
                ...mergeDeep(healthEventRef,payload.data)
            }

            this.appStore.setState(state)

            try {
                return await this.healthEventsFetcher.patch(payload.elementId,payload.data).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `patchHealthEvent : Unidentified Property with id [${payload.propertyId}]`}
    }

    async deleteHealthEvent(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const newHealthEventsArray = property.healthEvents.filter(event => event.id != payload.id)
            state.model.properties.collection[index] = {
                ...property,
                healthEvents : newHealthEventsArray
            }
            this.appStore.setState(state)

            try {
                await this.healthEventsFetcher.delete(payload.id).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `deleteHealthEvent : Unidentified Property with id [${payload.propertyId}]`}
    }
    

}