import { Injectable } from '@angular/core';
import { GeometryImportCommunication } from './geometry-import.communication';

@Injectable({
    providedIn: 'root'
})
export class GeometryImportService {
    constructor(
        private geometryImportCommunication: GeometryImportCommunication
    ) {}
    
    async uploadFile(payload) {
        let httpQuery = this.geometryImportCommunication.uploadFile(payload)
        .toPromise()
        return await httpQuery
    }
}