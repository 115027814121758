import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AuthService } from './auth.service';
import { IsLoggedInGuard } from './isLoggedIn.guard';
import { IsLoggedOutGuard } from './isLoggedOut.guard';
import { IsConfirmedGuard } from './isConfirmed.guard';
import { IsUnconfirmedGuard } from './isUnconfirmed.guard';
// import { IonicStorageModule } from '@ionic/storage';

@NgModule({
    declarations: [],
    providers: [
        AuthService,
        IsLoggedInGuard,
        IsLoggedOutGuard,
        IsConfirmedGuard,
        IsUnconfirmedGuard,
    ],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        ReactiveFormsModule,
        // IonicStorageModule.forRoot()
    ]
})
export class AuthModule { }