export let endpointsUrls = {
    user : {
        refreshToken : "/api/public/user/token/refresh",
        userRegister : "/api/public/user/register", 
        userUnRegister : "/api/user/unregister", 
        userResendConfirmation : "/api/user/resend-confirmation", 
        userLogin : "/api/public/user/login", 
        userLogout : "/api/user/logout",
        userResetPassword : "/api/public/user/reset-password-request",
        userChangePassword : "/api/user/change-password",
        userChangeEmail : "/api/user/email",
        profile : "/api/user/profile"
    },
    references : {
        public : "/api/public/references",
        private : "/api/references"
    },
    lots : {
        lot : "/api/lot",
        intersects : "/lot/intersects",
        localitySearch : "/api/locality/search",
    },
    property : {
        properties : "/api/property",
        property : "/api/property",
        mandate : "/api/property/mandate",
        landEquipment : "/api/property/land-equipment",
        mapLayerRaster : "/api/property/map-layer-raster",
        mapLayerVector : "/api/property/map-layer-vector",
        place : "/api/property/place",
        standType : "/api/stand-type",
        superDelegates : "/api/property/super-delegates",
        plannedOperation : "/api/planned-operation",
        operationMeasure : "/api/operation-measure",
        productionTable : "/api/production-table",
        managementPlan : "/api/management-plan",
        commitment : "/api/commitment",
        contact : "/api/contact",
        stand : "/api/stand",
        gameDamage : "/api/stand/game-damage",
        healthEvent : "/api/stand/health-event",
        speciesMeasure : "/api/stand/species-measure",
        job : "/api/public/job",
    },
    geometry : {
        convert : "/api/geometry/convert"
    },
    cadxmap : {
        be : "/cadxmap/api/be",
        fr : "/cadxmap/api/fr",
    }    
}
