import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomData } from './customData.typing';

@Injectable({
    providedIn: 'root',
})
export class CustomDataFetcher {
    constructor(private http: HttpClient) { }

    index(propertyId) {
        let url =  `api/property/${propertyId}/custom-data`
        return this.http.get<CustomData[]>(url)
    }

    addCustomData(propertyId,data) {
        let url =  `api/property/${propertyId}/custom-data`
        return this.http.post<CustomData>(url, data)
    }

    patch(propertyId, customDataKey, data) {
        let url =  `api/property/${propertyId}/custom-data/${customDataKey}`
        return this.http.patch<CustomData>(url, data)
    }
    
    delete(propertyId, customDataKey) {
        let url =  `api/property/${propertyId}/custom-data/${customDataKey}`
        return this.http.delete<CustomData>(url)
    }
}