import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ParamMap, Params, ActivatedRouteSnapshot } from '@angular/router';
import { filter, switchMap, map, takeUntil } from 'rxjs/operators';
import { AuthService } from '@mf-framework/authentification/auth.service';
import { of, Observable, Subject, combineLatest } from 'rxjs';
import { RootComponent } from '@current-app/modules/property/root/root.component';
import { CoreService } from '@mf-framework/core-service';
import { Property } from '@mf-framework/model/Property/property.typing';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { sortProperties } from '@mf-framework/model/Properties/utils';
import { environment } from '@current-environment/environment'
import { doesUserHaveGrantingPrivileges, doesUserHaveWritePrivileges } from '@mf-framework/model/Property/utils';
import { UiService } from '@mf-framework/ui/ui.service';

@Component({
    selector: 'maforet-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    
    animations: [
        trigger('smoothCollapse', [
            state('final', style({
                height: '0',
                overflow: 'hidden',
            })),
            state('initial', style({
                height: '123px',
                overflow: 'hidden',
            })),
            transition('initial=>final', animate('150ms')),
            transition('final=>initial', animate('150ms'))
        ]),
    ]
})
export class HeaderComponent implements OnInit {
    private _destroyed$ = new Subject();
    public isNavbarCollapsed = true;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private cd: ChangeDetectorRef,
        public coreService: CoreService,
        public uiService: UiService,

    ) {}

    currentPropertyId
    properties:Property[]
    property: Property
    pathArray 

    getPathArray(route: ActivatedRouteSnapshot) {
        let array = [];
    
        if (route.routeConfig && route.routeConfig.path !== '') {
            array.push(route.routeConfig.path);
        }
    
        if (route.firstChild) {
            array = array.concat(this.getPathArray(route.firstChild));
        }
    
        return array;
    }
    private _userInfos
    ngOnInit() {

        this.coreService.Selector$({ type: 'selectProfile' }).subscribe(profileData => {
            this._userInfos = profileData
        })

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.pathArray = this.getPathArray(this.router.routerState.snapshot.root);
            }
        })

        let subsciption

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            switchMap(() => this.getChildRoute()),
            map(params => {return params && params.id ? params.id : null}),
            takeUntil(this._destroyed$)
        )
        .subscribe(propertyId => {
            if(propertyId){
                this.currentPropertyId = propertyId

                if(subsciption && subsciption.unsubscribe)subsciption.unsubscribe()

                subsciption = combineLatest([
                    this.coreService.Selector$({ type: 'selectProperties'}),
                    this.coreService.Selector$({ type: 'selectProperty', payload: { id: propertyId } })
                ])
                .subscribe(results => {
                    this.properties = sortProperties(results[0].collection)
                    this.property = results[1]
                    if(doesUserHaveWritePrivileges(this.property)){
                        this.uiService.setPadlockState(false)
                    }
                    else{
                        this.uiService.setPadlockState(true)
                    }
                    
                    this.cd.detectChanges()
                })
                // this.coreService.Selector$({ type: 'selectProperty', payload: { id: propertyId } })
                // .subscribe(res => {
                //     this.property = res
                //     this.cd.detectChanges()
                // })
            }
            else {
                this.currentPropertyId = null
                this.property = null
                this.properties = []
            }
            this.cd.detectChanges()
        })
        this.cd.detectChanges()
    }

    getChildRoute(): Observable<Params> | null {
        let child = this.route.firstChild;
        while (child) {
            if(child.component == RootComponent){
                return child.params
            }
            else if (child.firstChild) {
                child = child.firstChild;
            } 
            else {
                return of(null)
            }
        }
        return of(null)
    }

    get isConfirmed () {
        return this.authService.isConfirmed()
    }

    get isLoggedIn () {
        return this.authService.isLoggedIn()
    }
    
    // get userEmail () {
    //     return this.authService.getUserEmail()
    // }

    get userInfos () {
        return this._userInfos
    }

    doesUserHaveGrantingPrivileges(property) {
        return doesUserHaveGrantingPrivileges(property)
    }

    exitApp(page='default') {
        let URL = environment.outgoingUrls[page] ? environment.outgoingUrls[page] : environment.outgoingUrls.default
        window.location.replace(URL);
        // this.authService.logout()
        // .subscribe(success => {
        //     window.location.replace(URL);
        // })        
    }

    // logout() {
    //     this.authService.logout()
    //     .subscribe(success => {
    //         window.location.replace("https://www.maforet.local");
    //     })
    // }
    
    userMenuDisplayed = false
    switchUserMenuDisplay() {
        this.userMenuDisplayed = !this.userMenuDisplayed
    }

    propertiesTrackFn = (i, property) => property.id

    @HostListener('document:click', ['$event'])
    onDocumentClick(ev) {
        if(this.propertiesDisplayed)this.propertiesDisplayed = false
    }

    propertiesDisplayed = false
    displayProperties(event) {
        this.propertiesDisplayed = !this.propertiesDisplayed
        event.stopPropagation()
    }

    jumpToProperty(propertyId) {
        let destination = this.pathArray.map(path => {
            return path.replace(':id', propertyId);
        })
        
        if(destination[2].includes('stand/') || destination[2].includes('stand-geometry/')){
            destination.splice(2,99,'stands')
        }

        this.redirectTo(destination)
        this.propertiesDisplayed = false
    }

    redirectTo(uri){
        this.router.navigateByUrl('/', {skipLocationChange: true})
        .then(
            () => this.router.navigate(uri)
        )
     }

    public ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
