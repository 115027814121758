import { Injectable } from '@angular/core';
import { Observable, throwError, merge } from 'rxjs'
import { filter, switchMap, mergeMap, tap } from 'rxjs/operators'

import { AppStore } from '../../application-store'
import { PropertiesService } from '../Properties/properties.service'
import { PropertyFetcher } from './property.communication'
import { Property } from './property.typing';
import { processPropertyLots, getLotArea } from '../Lots/utils';
import mergeDeep from '@mf-framework/utils/objects';
import { UiService } from '@mf-framework/ui/ui.service';
import { PropertyPreferencesService } from '../PropertyPreferences/propertyPreferences.service';
import { CustomDataService } from '../CustomData/customData.service';
import { increasePropertyArea, decreasePropertyArea } from './utils';

@Injectable({
    providedIn: 'root'
})
export class PropertyService {
    constructor(
        public appStore: AppStore,
        public propertiesService: PropertiesService,
        public propertyPreferencesService: PropertyPreferencesService,
        public propertyFetcher: PropertyFetcher,
        public customDataService: CustomDataService,
        public uiService: UiService,
    ) {
        this.customDataService.instantiatePropertyService(this)
        this.propertyPreferencesService.instantiateCustomDataService(this.customDataService)
    }

    selectProperty$(payload) : Observable<Property> {
        // return this.propertiesService.selectProperties$()
        // .pipe(
        //     switchMap(() => this.appStore.store$),
        //     mergeMap(data => data.model.properties.collection),
        //     filter(property => property.id == payload.id),
        // )

        const property = this.propertiesService.selectProperties$()
        .pipe(
            switchMap(() => this.appStore.store$),
            // tap(data => {
            //     if(!data.model.properties.collection.find(p=>p.id==payload.id)){
            //         console.log("Nop")
            //     }
            // }),
            mergeMap(data => data.model.properties.collection),
            filter(property => property.id == payload.id),
        )
            
        const uncomplete = property.pipe(
            filter(property => !property._loading),
            tap(_ => this.setPropertyFetching(+payload.id)),
            switchMap(_ => this.propertyFetcher.select(payload.id)),
            tap(propertyData => this.setPropertyData(propertyData)),
            switchMap(_ => property),
                
        )
        const complete = property.pipe(
            filter(property => property._loading && property._loading=="complete"),
        )

        return merge(
            uncomplete,
            complete
        )
    }

    setPropertyFetching(propertyId) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === propertyId)
        const index = state.model.properties.collection.indexOf(property)
        state.model.properties.collection[index] = {
            ...property,
            _loading:"inProgress"
        }
        this.appStore.setState(state)
        this.uiService.showLoadingSpinner("PropertyService:setPropertyFetching")
    }

    setPropertyData(propertyData) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === propertyData.id)
        const index = state.model.properties.collection.indexOf(property)
        
        state.model.properties.collection[index] = {
            ...property,
            ...mergeDeep(property,propertyData),
            _loading:"complete"
        }
        
        this.propertyPreferencesService.definePreferencesGetter(state.model.properties.collection[index])
        this.appStore.setState(state)
        
        setTimeout(() => {this.uiService.hideLoadingSpinner("PropertyService:setPropertyData")},100)
    }
    
    async patchProperty({ id, data }) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === id)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)

            state.model.properties.collection[index] = {
                ...property,
                ...mergeDeep(property,data)
                // ...data
            }
            
            this.appStore.setState(state)

            try {
                await this.propertyFetcher.patch(id,data).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
    }

    async deletePropertyLot(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        if (property) {
            const backup = JSON.stringify(property)
            
            property.updatedAt = Date.now()
            decreasePropertyArea(property,getLotArea(payload.lot))

            const index = state.model.properties.collection.indexOf(property)
            const newLotsArray = property.lots.filter(lot => lot.id != payload.lot.id)
            state.model.properties.collection[index] = {
                ...property,
                lots : newLotsArray
            }
            
            processPropertyLots(state.model.properties.collection[index])

            this.appStore.setState(state)

            try {
                await this.propertyFetcher.deleteLot(payload.lot.id).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
    }

    async addLotToProperty(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)

            property.updatedAt = Date.now()
            increasePropertyArea(property,getLotArea(payload.lot))

            const index = state.model.properties.collection.indexOf(property)
            // state.model.properties.collection[index] = {
            //     ...property,
            //     lots : [
            //         ...property.lots,
            //         payload.lot
            //     ]
            // }

            // processPropertyLots(state.model.properties.collection[index])

            // this.appStore.setState(state)

            try {
                await this.propertyFetcher.addLot({
                    propertyId : payload.propertyId,
                    code : payload.lot.code,
                    owners : payload.owners,
                    jointOwnership : payload.jointOwnership,
                    neighbour : payload.neighbour || false
                })
                .toPromise()
                .then(
                    newLot => {
                        state.model.properties.collection[index] = {
                            ...property,
                            lots : [
                                ...property.lots,
                                newLot
                            ]
                        }
                        processPropertyLots(state.model.properties.collection[index])
                        this.appStore.setState(state)
                    }
                )
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
    }

    getSuperDelegates$() : Observable<any> {
        return this.propertyFetcher.getSuperDelegates()
    }

    async propertyMandate(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)

            property.updatedAt = Date.now()
            
            const index = state.model.properties.collection.indexOf(property)

            let requestPayload
            if(payload.organizationId) {
                // type regiowood
                requestPayload = {
                    organizationId : payload.organizationId
                }
            }
            else{
                requestPayload = {
                    "email" : payload.email,
                    "privileges": payload.privileges
                }
            }

            try {
                await this.propertyFetcher.mandate(payload.propertyId,requestPayload)
                .toPromise()
                .then(
                    newMandate => {
                        state.model.properties.collection[index] = {
                            ...property,
                            mandates : [
                                ...property.mandates,
                                newMandate
                            ]
                        }
                        this.appStore.setState(state)
                    }
                )
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                // return error
                return throwError({error:error}).toPromise()
            }
        }
    }

    async propertyRevokeMandate(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)

            property.updatedAt = Date.now()
            
            const index = state.model.properties.collection.indexOf(property)
            const newMandatesArray = property.mandates.filter(mandate => mandate.id != payload.mandateId)
            state.model.properties.collection[index] = {
                ...property,
                mandates : newMandatesArray
            }
            
            this.appStore.setState(state)

            try {
                await this.propertyFetcher.revokeMandate(payload.mandateId)
                .toPromise()
                .then(res=>{
                    if(payload.memoryRemove){
                        this.propertiesService.deleteProperty({id : payload.propertyId,serverRemove : false})
                    }                    
                })
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
    }

    
}