import { Injectable } from '@angular/core';
import { from, of, Observable } from 'rxjs'
import { PropertiesService } from './model/Properties/properties.service'
import { PropertyService } from './model/Property/property.service'
import { UiService } from './ui/ui.service'
import { LotsService } from './model/Lots/lots.service';
import { LocalizationService } from './utils/localization.service';
import { ContactsService } from './model/Contacts/contacts.service';
import { ReferencesService } from './model/References/references.service';
import { CommitmentsService } from './model/Commitments/commitments.service';
import { StandsService } from './model/Stands/stands.service';
import { HealthEventsService } from './model/HealthEvents/healthEvents.service';
import { GameDamagesService } from './model/GameDamages/gameDamages.service';
import { SpeciesMeasuresService } from './model/Stands/SpeciesMeasures/speciesMeasures.service';
import { LandEquipmentsService } from './model/LandEquipments/landEquipments.service';
import { PlannedOperationsService } from './model/PlannedOperations/plannedOperations.service';
import { MapLayersRasterService } from './model/MapLayersRaster/mapLayersRaster.service';
import { MapLayersVectorService } from './model/MapLayersVector/mapLayersVector.service';
import { CustomDataService } from './model/CustomData/customData.service';
import { PlacesService } from './model/Places/places.service';
import { ProfileService } from './model/Profile/profile.service';
import { DocumentService } from './utils/document.service';
import { PropertyPreferencesService } from './model/PropertyPreferences/propertyPreferences.service';
import { OperationMeasuresService } from './model/OperationMeasures/operationMeasures.service';
import { ProductionTablesService } from './model/ProductionTables/productionTables.service';
import { ForecastsService } from './model/Forecasts/forecasts.service';
import { StandTypesService } from './model/StandTypes/standTypes.service';
import { JobsService } from './utils/jobs.service';
import { GeometryImportService } from './utils/geometry-import.service';

@Injectable({
    providedIn: 'root'
})
export class CoreService {
    public name: string = "maforetFramework"
    
    constructor(
        private propertiesService: PropertiesService,
        private propertyService: PropertyService,
        private referencesService: ReferencesService,
        private propertyPreferencesService: PropertyPreferencesService,
        private localizationService: LocalizationService,
        private lotsService: LotsService,
        private standsService: StandsService,
        private standTypesService: StandTypesService,
        private contactsService: ContactsService,
        private commitmentsService: CommitmentsService,
        private customDataService: CustomDataService,
        private healthEventsService: HealthEventsService,
        private gameDamagesService: GameDamagesService,
        private landEquipmentsService: LandEquipmentsService,
        private plannedOperationsService: PlannedOperationsService,
        private operationMeasuresService: OperationMeasuresService,
        private productionTablesService: ProductionTablesService,
        private mapLayersRasterService: MapLayersRasterService,
        private mapLayersVectorService: MapLayersVectorService,
        private placesService: PlacesService,
        private speciesMeasuresService: SpeciesMeasuresService,
        private profileService: ProfileService,
        private documentService: DocumentService,
        private geometryImportService: GeometryImportService,
        private forecastsService: ForecastsService,
        private jobsService: JobsService,
        private uiService: UiService
    ) {
        
    }

    Selector$({type,payload=null}) : Observable<any> {
        switch(type) {
            // case "referencesMobile" : 
            //     return this.referencesService.fetchReferencesMobile$()
            // break;
            case "references" : 
                return this.referencesService.fetchReferences$()
                // return this['referencesService']['fetchReferences$']()
            break;
            case "publicReferences" : 
                return this.referencesService.fetchPublicReferences$()
                // return this['referencesService']['fetchPublicReferences$']()
            break;

            // case "selectPropertiesDetailedMobile" : 
            //     return this.propertiesService.selectPropertiesDetailedMobile$()
            // break;
            case "selectProperties" : 
                return this.propertiesService.selectProperties$()
                // return this['propertiesService']['selectProperties$']()
            break;
            
            // case "selectPropertyMobile" : 
            //     return this.propertyService.selectPropertyMobile$(payload)
            //     // return this['propertyService']['selectProperty$'](payload)
            // break;
            case "selectProperty" : 
                return this.propertyService.selectProperty$(payload)
                // return this['propertyService']['selectProperty$'](payload)
            break;

            case "getSuperDelegates" : 
                return this.propertyService.getSuperDelegates$()
                // return this['propertyService']['getSuperDelegates$']()
            break;
            
            case "getStandForecast" : 
                return this.forecastsService.getStandForecast$(payload)
                // return this['propertyService']['getSuperDelegates$']()
            break;
            
            case "selectStand" : 
                return this['standsService']['selectStand$'](payload)
            break;
            
            case "selectAllCustomData" : 
                return this['customDataService']['selectAllCustomData$'](payload)
            break;

            case "selectCustomDataKey" : 
                return this['customDataService']['selectCustomDataKey$'](payload)
            break;
            
            case "getContactLots" : 
                return this['contactsService']['getContactLots$'](payload)
            break;
            
            case "findLotByCoords" : 
                return this['localizationService']['findLotByCoords$'](payload)
            break;
            
            case "queryGeographicalPoint" : 
                return this['localizationService']['queryGeographicalPoint$'](payload)
            break;
            
            case "findCityByString" : 
                return this['localizationService']['findCityByString$'](payload)
            break;

            case "queryCadastralDivisionsByCityCode" : 
                return this['localizationService']['queryCadastralDivisionsByCityCode$'](payload)
            break;
            
            case "queryCadastralSectionsByDivisonCode" : 
                return this['localizationService']['queryCadastralSectionsByDivisonCode$'](payload)
            break;
            
            case "queryCadastralSectionsByCityCode" : 
                return this['localizationService']['queryCadastralSectionsByCityCode$'](payload)
            break;

            case "queryCadastralNumbersBySectionCode" : 
                return this['localizationService']['queryCadastralNumbersBySectionCode$'](payload)
            break;
           
            case "queryLotByCode" : 
                return this['localizationService']['queryLotByCode$'](payload)
            break;

            case "selectProfile" : 
                return this['profileService']['selectProfile$']()
            break;

            case "generateDocument" : 
                return this['documentService']['generateDocument$'](payload)
            break;

            case "uiState" : 
                return this['uiService']['uiState$']()
            break;

            default :
                const error = `Missing Selector ${type} in CoreService`
                throw(error)
                return of( new Error(error) )
            break;
        }
    }

    SyncReducer(args) {
        const subscription = this.Reducer$(args).subscribe()
        subscription.unsubscribe()
    }

    Reducer$({type,payload}) : Observable<any> {
        switch(type) {
            case "deleteProperty" : 
                return from( this.propertiesService.deleteProperty(payload) )
            break;
            
            case "addProperty" : 
                return from( this.propertiesService.addProperty(payload) )
            break;
            
            case "patchProperty" : 
                return from( this.propertyService.patchProperty(payload) )
            break;
            
            case "addLotToProperty" : 
                return from( this.propertyService.addLotToProperty(payload) )
            break;

            case "deletePropertyLot" : 
                return from( this.propertyService.deletePropertyLot(payload) )
            break;

            case "propertyMandate" : 
                return from( this.propertyService.propertyMandate(payload) )
            break;
            
            case "propertyRevokeMandate" : 
                return from( this.propertyService.propertyRevokeMandate(payload) )
            break;
            
            case "patchLot" : 
                return from( this.lotsService.patchLot(payload) )
            break;
            
            case "importLots" : 
                return from( this.lotsService.importLots(payload) )
            break;
            
            case "getLots" : 
                return from( this.lotsService.getLots(payload) )
            break;

            case "geometryImportUploadFile" : 
                return from( this.geometryImportService.uploadFile(payload) )
            break;


            case "addStand" : 
                return from( this.standsService.addStand(payload) )
            break;
            
            case "addStandType" : 
                return from( this.standTypesService.addStandType(payload) )
            break;
            case "patchStandType" : 
                return from( this.standTypesService.patchStandType(payload) )
            break;
            case "deleteStandType" : 
                return from( this.standTypesService.deleteStandType(payload) )
            break;

            case "patchStand" : 
                return from( this.standsService.patchStand(payload) )
            break;
            
            case "patchStandGeographicalFeature" : 
                return from( this.standsService.patchStandGeographicalFeature(payload) )
            break;
            
            case "standGeometryUpdateJob" : 
                return from( this.standsService.standGeometryUpdateJob(payload) )
            break;
            
            case "updateJob" : 
                return from( this.jobsService.updateJob(payload) )
            break;
            
            case "getStand" : 
                return from( this.standsService.getStand(payload) )
            break;
            case "deleteStand" : 
                return from( this.standsService.deleteStand(payload) )
            break;
            
            case "addHealthEvent" : 
                return from( this.healthEventsService.addHealthEvent(payload) )
            break;
            case "patchHealthEvent" : 
                return from( this.healthEventsService.patchHealthEvent(payload) )
            break;
            case "deleteHealthEvent" : 
                return from( this.healthEventsService.deleteHealthEvent(payload) )
            break;
            
            case "addGameDamage" : 
                return from( this.gameDamagesService.addGameDamage(payload) )
            break;
            case "patchGameDamage" : 
                return from( this.gameDamagesService.patchGameDamage(payload) )
            break;
            case "deleteGameDamage" : 
                return from( this.gameDamagesService.deleteGameDamage(payload) )
            break;
            
            case "addLandEquipment" : 
                return from( this.landEquipmentsService.addLandEquipment(payload) )
            break;
            case "patchLandEquipment" : 
                return from( this.landEquipmentsService.patchLandEquipment(payload) )
            break;
            case "deleteLandEquipment" : 
                return from( this.landEquipmentsService.deleteLandEquipment(payload) )
            break;

            case "addPlannedOperation" : 
                return from( this.plannedOperationsService.addPlannedOperation(payload) )
            break;
            case "patchPlannedOperation" : 
            return from( this.plannedOperationsService.patchPlannedOperation(payload) )
            break;
            case "deletePlannedOperation" : 
            return from( this.plannedOperationsService.deletePlannedOperation(payload) )
            break;
            
            case "addOperationMeasure" : 
                return from( this.operationMeasuresService.addOperationMeasure(payload) )
            break;
            case "patchOperationMeasure" : 
                return from( this.operationMeasuresService.patchOperationMeasure(payload) )
            break;
            case "deleteOperationMeasure" : 
                return from( this.operationMeasuresService.deleteOperationMeasure(payload) )
            break;
            
            case "addProductionTable" : 
                return from( this.productionTablesService.addProductionTable(payload) )
            break;
            case "patchProductionTable" : 
                return from( this.productionTablesService.patchProductionTable(payload) )
            break;
            case "deleteProductionTable" : 
                return from( this.productionTablesService.deleteProductionTable(payload) )
            break;
            
            case "addMapLayerRaster" : 
                return from( this.mapLayersRasterService.addMapLayerRaster(payload) )
            break;
            case "patchMapLayerRaster" : 
                return from( this.mapLayersRasterService.patchMapLayerRaster(payload) )
            break;
            case "deleteMapLayerRaster" : 
                return from( this.mapLayersRasterService.deleteMapLayerRaster(payload) )
            break;
            
            case "addMapLayerVector" : 
                return from( this.mapLayersVectorService.addMapLayerVector(payload) )
            break;
            case "patchMapLayerVector" : 
                return from( this.mapLayersVectorService.patchMapLayerVector(payload) )
            break;
            case "deleteMapLayerVector" : 
                return from( this.mapLayersVectorService.deleteMapLayerVector(payload) )
            break;

            case "addPlace" : 
                return from( this.placesService.addPlace(payload) )
            break;
            case "patchPlace" : 
                return from( this.placesService.patchPlace(payload) )
            break;
            case "deletePlace" : 
                return from( this.placesService.deletePlace(payload) )
            break;
            
            case "addSpeciesMeasure" : 
                return from( this.speciesMeasuresService.addSpeciesMeasure(payload) )
            break;
            case "patchSpeciesMeasure" : 
                return from( this.speciesMeasuresService.patchSpeciesMeasure(payload) )
            break;
            case "deleteSpeciesMeasure" : 
                return from( this.speciesMeasuresService.deleteSpeciesMeasure(payload) )
            break;

            case "addContact" : 
                return from( this.contactsService.addContact(payload) )
            break;
            
            case "patchContact" : 
                return from( this.contactsService.patchContact(payload) )
            break;

            case "deleteContact" : 
                return from( this.contactsService.deleteContact(payload) )
                break;
                

            case "addCommitment" : 
                return from( this.commitmentsService.addCommitment(payload) )
            break;
            
            case "patchCommitment" : 
                return from( this.commitmentsService.patchCommitment(payload) )
            break;

            case "deleteCommitment" : 
                return from( this.commitmentsService.deleteCommitment(payload) )
            break;

            case "addCustomDataKey" : 
                return from( this.customDataService.addCustomDataKey(payload) )
            break;
            case "patchCustomDataKey" : 
                return from( this.customDataService.patchCustomDataKey(payload) )
            break;
            case "deleteCustomDataKey" : 
                return from( this.customDataService.deleteCustomDataKey(payload) )
            break;
           
           
            case "patchProfile" : 
                return from( this.profileService.patchProfile(payload) )
            break;
            
            case "patchProfile" : 
                return from( this.profileService.patchProfile(payload) )
            break;
            
            case "generateDocument" : 
                return from( this.documentService.generateDocument(payload) )
            break;
            case "generateShapefile" : 
                return from( this.documentService.generateShapefile(payload) )
            break;
            case "exportBlobFormats" : 
                return from( this.documentService.exportBlobFormats(payload) )
            break;
            case "generateCsv" : 
                return from( this.documentService.generateCsv(payload) )
            break;
            
            
            case "setPreference" : 
                return from( this.propertyPreferencesService.setPreference(payload) )
            break;
            
            
            case "togglePadlockState" : 
                return of( this.uiService.togglePadlockState() )
            break;
           

            default :
                const error = `Missing Reducer ${type} in CoreService`
                throw(error)
                return of( new Error(error) )
            break;
        }
    }
}