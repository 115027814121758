<!-- <div class="vertical-nav bg-white user-select-none" id="sidebar" *ngIf="coreService.Selector$({type:'uiState'}) | async as uiState">

    <div class="px-2 bg-gray-600 d-none d-sm-block" style="padding-top:4px;padding-bottom:5px">
        <a class="text-white" [routerLink]="['/dashboard']">
            <small class="text-uppercase">
                &laquo; Retour 
                <span class="d-none d-lg-inline">
                    à la liste des propriétés
                </span>
            </small>
        </a>
    </div>

    <div class="px-2 pt-2 pb-3 mb-0 bg-light" *ngIf="property">

        <div class="d-flex d-sm-block d-md-flex justify-content-center justify-content-md-end">
            
            <div>
                <p class="font-weight-light text-muted mb-0 d-none d-md-block">
                    <small>
                        Tableau de bord
                    </small>
                </p>
                <h4>
                    {{ property.name }}
                </h4>
            </div>

            <div *ngIf="property.countryLevel1Code=='BE-WAL'" class="align-self-center pt-md-2 pl-3 pl-sm-0 pl-md-2 pr-1 text-center">
                <div class="padlock user-select-none" [class.locked]="!uiState.padlocked"
                    (click)="coreService.SyncReducer({type:'togglePadlockState'})">
                    <i class="material-icons notranslate" *ngIf="uiState.padlocked">lock</i>
                    <i class="material-icons notranslate" *ngIf="!uiState.padlocked">lock_open</i>
                </div>
            </div>
        </div>
    </div>

    <nav class="navbar navbar-expand-md p-0" *ngIf="property">

        <div [@smoothCollapse]="isNavbarCollapsed?'initial':'final'" class="navbar-collapse">
            <ul class="nav flex-column bg-white mb-0 w-100">
                <li class="nav-item" *ngIf="property.countryLevel1Code=='BE-WAL'">
                    <a class="d-none d-sm-block nav-link text-uppercase font-weight-bold p-3 pt-md-4" routerLink="infos" routerLinkActive="active">Infos générales</a>
                </li>
                <li class="nav-item" *ngIf="property.countryLevel1Code=='BE-WAL'">
                    <a class="d-none d-sm-block nav-link text-uppercase font-weight-bold p-3" routerLink="cadaster" routerLinkActive #cadasterActive="routerLinkActive" [ngClass]="{'active': cadasterActive.isActive || cadasterInformationActive.isActive}">Parcelles cadastrales</a>

                    <a routerLink="cadaster-information" routerLinkActive #cadasterInformationActive="routerLinkActive" style="display: none"></a>
                </li>
                <li class="nav-item" *ngIf="property.countryLevel1Code=='BE-WAL'">
                    <a class="d-none d-sm-block nav-link text-uppercase font-weight-bold p-3" routerLink="stands" routerLinkActive #standsActive="routerLinkActive" [ngClass]="{'active': standActive.isActive || standsActive.isActive || standsGeometryActive.isActive}">Parcelles forestières</a>

                    <a routerLink="stand" routerLinkActive #standActive="routerLinkActive" style="display: none"></a>
                    <a routerLink="stand-geometry" routerLinkActive #standsGeometryActive="routerLinkActive" style="display: none"></a>
                </li>
                <li class="nav-item" *ngIf="property.countryLevel1Code=='BE-WAL'">
                    <a class="d-none d-sm-block nav-link text-uppercase font-weight-bold p-3" routerLink="synthesis" routerLinkActive="active">Synthèse propriété</a>
                </li>
                <li class="nav-item" *ngIf="property.countryLevel1Code=='BE-WAL'">
                    <a class="d-none d-sm-block nav-link text-uppercase font-weight-bold p-3" routerLink="cartography" routerLinkActive="active">Édition de cartes</a>
                </li>
                <li class="nav-item" *ngIf="property.countryLevel1Code=='BE-WAL'">
                    <a class="d-none d-sm-block nav-link text-uppercase font-weight-bold p-3" routerLink="documents" routerLinkActive="active">Exports et formulaires</a>
                </li>
            </ul>
        </div>
    </nav>

</div> -->

<div class="app-content-wrapper">
    <router-outlet></router-outlet>
</div>