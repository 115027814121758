<div class="bg-light pt-5 pb-5">
    <div class="container min-vh-50 mb-5" *ngIf="property">
        <h4 class="mb-1 text-muted text-uppercase">{{property.name}}</h4>
        <h3 class="mb-3 text-uppercase">Droits d'accès à la propriété</h3>
        <p *ngIf="!property.mandates.length">
            Vous avez ci-dessous la possibilité de permettre l'accès à cette propriété à une ou plusieurs organisations conseil. En leur accordant cette autorisation, vous leur permettez de visualiser, de modifier et d'ajouter des données à la propriété afin de vous aider dans l’utilisation de la plateforme. Vous aurez à tout moment la possibilité de révoquer ces autorisations.
        </p>
        <p *ngIf="property.mandates.length">
            Votre propriété est actuellement accessible aux membres des organisations de conseil indiquées ci-dessous.
        </p>
        <hr class="mb-4">

        <div *ngIf="property.mandates.length">
            <div class="mb-3" *ngFor="let mandate of property.mandates">
                <div class="d-flex">
                    <div class="flex-grow-1">
                        <strong class="d-block lead font-weight-bold">
                            {{mandate.organization.name}}
                        </strong>

                        <!-- <span class="text-muted font-italic">
                            <label>Conseillers :  </label>
                            <ul class="comma-separated-list p-0 ml-1 mb-0">
                                <li class="font-italic" *ngFor="let member of mandate.organization.members">{{member.signature}}</li>
                            </ul>
                        </span> -->
                    </div>
                    
                    <div>
                        <button class="btn btn-sm line-height-1 btn-danger btn-block mb-2" (click)="revokeMandate(mandate)">
                            <i class="material-icons notranslate align-middle">delete</i> 
                            Révoquer l'accès <span class="d-none d-md-inline">des membres de 
                                <span *ngIf="mandate.organization.acronym; else elseBlock">{{mandate.organization.acronym}}</span>
                                <ng-template #elseBlock>{{mandate.organization.name}}</ng-template>
                            </span>
                        </button>
                    </div>
        
                </div>

                <div class="font-italic font-weight-bold text-primary" *ngIf="!mandate.confirmed">
                    Un e-mail a été envoyé à votre adresse. Vous devez cliquer sur le lien de confirmation qu'il contient à fin de valider cette autorisation.
                </div>
            </div>
        </div>

        <div>

            <ng-container *ngFor="let organization of superGelegates">
                
                <div class="d-flex mb-3" *ngIf="notAlreadyMandatary(organization)">
                    <div class="flex-grow-1">
                        <strong class="d-block lead">
                            {{organization.name}}
                        </strong>
    
                        <!--<span class="text-muted font-italic">
                            <label>Conseillers :  </label>
                            <ul class="comma-separated-list p-0 ml-1 mb-0">
                                <li class="font-italic" *ngFor="let member of organization.members">{{member.signature}}</li>
                            </ul>
                        </span> -->
                    </div>
                    
                    <div>
                        <button class="btn btn-sm line-height-1 btn-success btn-block mb-2" (click)="delegateTo(organization)">
                            <i class="material-icons notranslate align-middle">supervisor_account</i> 
                            Accorder l'accès 
                            <span class="d-none d-md-inline">
                                aux membres de 
                                <span *ngIf="organization.acronym; else elseBlock">{{organization.acronym}}</span>
                                <ng-template #elseBlock>{{organization.name}}</ng-template>
                            </span>
                        </button>
                    </div>
        
                </div>
            </ng-container>

            
        </div>

        <!-- <div>
            MANDATES : 
            <pre>{{property.mandates|json}}</pre>
        </div>

        <div>
            SUPER DELEGATES : 
            <pre>{{superGelegates|json}}</pre>
        </div>

        <div *ngIf="property">
            <hr>

            <div *ngFor="let contact of property.contacts">
                {{ contact.person.givenName }}
                {{ contact.person.familyName }}
                {{ contact.organization.name }}
                :
                {{contact.roles |json}}
            </div>

            <hr class="m-3">
            <pre>{{property.contacts |json}}</pre>
        </div> -->

    </div>
</div>
