import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Profile } from './profile.typing';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { getApiUrl } from '@mf-framework/utils/application';
import { environment } from '@current-environment/environment'

@Injectable({
    providedIn: 'root',
})
export class ProfileFetcher {
    constructor(private http: HttpClient) { }

    index() {
        let url =  getApiUrl(environment,endpointsUrls.user.profile)
        return this.http.get<Profile>(url)
    }


    patch(data) {
        let url =  getApiUrl(environment,endpointsUrls.user.profile)
        return this.http.patch<Profile>(url, data)
    }
}