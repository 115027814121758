import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SpeciesMeasure } from './speciesMeasure.typing';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';

@Injectable({
    providedIn: 'root',
})
export class SpeciesMeasuresFetcher {
    constructor(private http: HttpClient) { }

    create(data) {
        let url =  endpointsUrls.property.speciesMeasure
        if(data.propertyId)delete data.propertyId
        return this.http.post<SpeciesMeasure>(url, data)
    }

    delete(speciesMeasureId) {
        let url =  `${endpointsUrls.property.speciesMeasure}/${speciesMeasureId}`
        return this.http.delete(url)
    }

    patch(speciesMeasureId: string, data) {
        let url =  `${endpointsUrls.property.speciesMeasure}/${speciesMeasureId}`
        return this.http.patch<SpeciesMeasure>(url, data)
    }
}