import { Injectable } from '@angular/core';
import { throwError } from 'rxjs'
import mergeDeep from '@mf-framework/utils/objects';
import { AppStore } from '@mf-framework/application-store';
import { SpeciesMeasuresFetcher } from './speciesMeasures.communication';



@Injectable({
    providedIn: 'root'
})
export class SpeciesMeasuresService {
    constructor(
        private appStore: AppStore,
        private speciesMeasuresFetcher: SpeciesMeasuresFetcher,
    ) { }

    async addSpeciesMeasure(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const index = state.model.properties.collection.indexOf(property)
            const standRef = property.stands.find(o => o.id == payload.standId)
            const standRefIndex = property.stands.indexOf(standRef)
            
            return await this.speciesMeasuresFetcher.create(payload)
            .toPromise()
            .then(
                newSpeciesMeasure => {
                    state.model.properties.collection[index].stands[standRefIndex] = {
                        ...standRef,
                        speciesMeasures : [
                            ...standRef.speciesMeasures,
                            newSpeciesMeasure
                        ]
                    }

                    this.appStore.setState(state)
                    return newSpeciesMeasure
                }
            )
        }
        else {throw `addSpeciesMeasure : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchSpeciesMeasure(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const standRef = property.stands.find(o => o.id == payload.standId)
            const standRefIndex = property.stands.indexOf(standRef)
            const speciesMeasureRef = standRef.speciesMeasures.find(o => o.id == payload.speciesMeasureId)
            const speciesMeasureRefIndex = standRef.speciesMeasures.indexOf(speciesMeasureRef)

            state.model.properties.collection[index].stands[standRefIndex].speciesMeasures[speciesMeasureRefIndex] = {
                ...speciesMeasureRef,
                ...mergeDeep(speciesMeasureRef,payload.data)
            }

            this.appStore.setState(state)

            try {
                return await this.speciesMeasuresFetcher.patch(payload.speciesMeasureId,payload.data).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `patchSpeciesMeasure : Unidentified Property with id [${payload.propertyId}]`}
    }

    async deleteSpeciesMeasure(payload) {
        const state = this.appStore.getState()
        const propertyId = payload.propertyId
        const property = state.model.properties.collection.find(p => p.id === propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const standRef = property.stands.find(o => o.id == payload.standId)
            const standRefIndex = property.stands.indexOf(standRef)

            const newSpeciesMeasuresArray = standRef.speciesMeasures.filter(o => o.id != payload.id)

            state.model.properties.collection[index].stands[standRefIndex] = {
                ...standRef,
                speciesMeasures : newSpeciesMeasuresArray
            }
            this.appStore.setState(state)

            try {
                await this.speciesMeasuresFetcher.delete(payload.id).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `deleteSpeciesMeasure : Unidentified Property with id [${payload.propertyId}]`}
    }
      

}