import { ltrim, rtrim } from '@mf-framework/utils/strings'

export function processPropertyLots(property) {
    if(property.lots){
        property.lots.forEach(lot => {
            setLotCodes(lot)
        })
    }
}

export function parseLotCode(lot){
    let countryCode = lot.locality.countryCode || lot.locality.country.code
    if(countryCode.toUpperCase() == "BE") {
        let code = lot.code
        return {
            division : code.substring(0,5),
            section : code.substring(5,6),
            radix : code.substring(6,10),
            bis : code.substring(10,12),
            exAlpha : code.substring(12,13),
            exDigit : code.substring(13,16)
        }
        // lot.division = code.substring(0,5)
        // lot.lotCode = [ 
        //     code.substring(5,6), 
        //     code.substring(6,10), 
        //     code.substring(10,12), 
        //     code.substring(12,13), 
        //     code.substring(13,16)
        // ].join("-")
        // section-radical-bis-exAlpha-exDigit
    }
    if(countryCode.toUpperCase() == "FR") {
        let code = lot.code
        return {
            localityCode : code.substring(0,5),
            sectionPrefix : code.substring(5,8),
            section : code.substring(8,10),
            number : code.substring(10,14)
        }
        // F1529000FG0044

    }
}


export function parseLotShortCodeBE(lot){
    return rtrim(ltrim([lot.cadastralRefs.radix,lot.cadastralRefs.exAlpha].join(""),"0"),"_")
}

export function setLotCodes(lot){
    let countryCode = lot.locality.countryCode || lot.locality.country.code
    if(countryCode.toUpperCase() == "BE") {
        if(!lot.division || !lot.lotCode || !lot.cadastralRefs){
            let refs = parseLotCode(lot)
            lot.cadastralRefs = refs
            lot.division = refs.division
            lot.lotCode = [
                refs.section,
                refs.radix,
                refs.bis,
                refs.exAlpha,
                refs.exDigit
            ].join("-")
            lot.shortLotCode = parseLotShortCodeBE(lot)
            // let code = lot.code
            // lot.division = code.substring(0,5)
            // lot.lotCode = [ 
            //     code.substring(5,6), 
            //     code.substring(6,10), 
            //     code.substring(10,12), 
            //     code.substring(12,13), 
            //     code.substring(13,16)
            // ].join("-")
            // section-radical-bis-exAlpha-exDigit
        }
    }
    if(countryCode.toUpperCase() == "FR") {
        if(!lot.lotCode || !lot.cadastralRefs){
            let refs = parseLotCode(lot)
            lot.cadastralRefs = refs
            lot.lotCode = [ 
                refs.section, 
                refs.number 
            ].join(" ")
            // F1529000FG0044
        }
    }
    return lot
}

export function getLotCode(lot){
    let countryCode = lot.locality.countryCode || lot.locality.country.code
    if(countryCode.toUpperCase() == "BE") {
        if(!lot.division || !lot.lotCode){
            setLotCodes(lot)
        }

        return {
            division : lot.division,
            lotCode : lot.lotCode
        }
    }
    
    if(countryCode.toUpperCase() == "FR") {
        if(!lot.lotCode){
            setLotCodes(lot)
        }

        return {
            lotCode : lot.lotCode
        }
    }
}

export function getLotArea(lot){
    if(lot.area.input)return lot.area.input

    let countryCode = lot.locality.countryCode || lot.locality.country.code
    if(countryCode.toUpperCase() == "BE") {
        return lot.area.geom
    }
    if(countryCode.toUpperCase() == "FR") {
        return lot.area.admin        
    }
}