import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 

import { NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { InputTextComponent } from './inputs/input-text.component';
import { InputTextAreaComponent } from './inputs/input-textarea.component';
import { SurfacePipe } from './pipes/surface.pipe';
import { NgbDateStructPipe } from './pipes/ngb-date-struct.pipe';
import { AppToastsComponent } from './app-toast/app-toast.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { InputCheckboxComponent } from './inputs/input-checkbox.component';
import { InputSwitchComponent } from './inputs/input-switch.component';
import { InputDateComponent } from './inputs/input-date.component';
import { SurfaceComponent } from './display/surface.component';
import { VarDirective } from './var/var.directive';
import { InputRadioComponent } from './inputs/input-radio.component';
import { InputSelectComponent } from './inputs/input-select.component';
import { InputNumberComponent } from './inputs/input-number.component';
import { MinDirective } from './inputs/input-number.min.directive';
import { MaxDirective } from './inputs/input-number.max.directive';
import { InputDateYearComponent } from './inputs/input-date-year.component';
import { ValueComponent } from './values/value.component';
import { OperationProgressComponent } from './operation-progress/operation-progress.component';
import { CripplingErrorModal } from './app-modal/crippling-error.component';
import { SurfaceEditorComponent } from './inputs/surface-editor.component';
import { LengthPipe } from './pipes/length.pipe';
import { NoDblClickDirective } from './no-dblclick/no-dblclick.directive';
import { InputRangeComponent } from './inputs/input-range.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { BytesPipe } from './pipes/bytes.pipe';
import { InputSelectableButtonsComponent } from './inputs/input-selectable-buttons.component';

@NgModule({
  declarations: [InputTextComponent,InputTextAreaComponent,InputNumberComponent,MinDirective,MaxDirective,InputCheckboxComponent,InputSwitchComponent,InputDateComponent,InputDateYearComponent,InputRadioComponent,InputSelectComponent,InputRangeComponent,NgbDateStructPipe,SurfacePipe,LengthPipe,SurfaceComponent,AppToastsComponent,CripplingErrorModal,SpinnerComponent,VarDirective,ValueComponent,OperationProgressComponent,SurfaceEditorComponent,NoDblClickDirective,ColorPickerComponent,BytesPipe,InputSelectableButtonsComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    NgbToastModule,
    NgbDatepickerModule,
    NgbTooltipModule
  ],
    
  entryComponents: [SpinnerComponent,CripplingErrorModal],

  exports: [InputTextComponent,InputTextAreaComponent,InputNumberComponent,MinDirective,MaxDirective,InputCheckboxComponent,InputSwitchComponent,InputDateComponent,InputDateYearComponent,InputRadioComponent,InputSelectComponent,InputRangeComponent,NgbDateStructPipe,SurfacePipe,LengthPipe,SurfaceComponent,AppToastsComponent,CripplingErrorModal,SpinnerComponent,VarDirective,ValueComponent,OperationProgressComponent,SurfaceEditorComponent,NoDblClickDirective,ColorPickerComponent,BytesPipe,InputSelectableButtonsComponent]
})
export class InterfaceModule { }
