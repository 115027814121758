export function cleanStringForSearch(string) {
    return string.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

// @function template(str: String, data: Object): String
// Simple templating facility, accepts a template string of the form `'Hello {a}, {b}'`
// and a data object like `{a: 'foo', b: 'bar'}`, returns evaluated string
// `('Hello foo, bar')`. You can also specify functions instead of strings for
// data values — they will be evaluated passing `data` as an argument.

export function parseTemplate(str, data) {
    var templateRe = /\{ *([\w_-]+) *\}/g;

    return str.replace(templateRe, function (str, key) {
        var value = data[key];

        if (value === undefined) {
            throw new Error('No value provided for variable ' + str);

        } else if (typeof value === 'function') {
            value = value(data);
        }
        return value;
    });
}

export function ltrim (str, charlist?) {
    charlist = !charlist ? ' \\s\u00A0' : (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '$1')
    const re = new RegExp('^[' + charlist + ']+', 'g')
    return (str + '').replace(re, '')
}

export function rtrim (str, charlist?) {
    charlist = !charlist ? ' \\s\u00A0' : (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '\\$1')
    const re = new RegExp('[' + charlist + ']+$', 'g')
    return (str + '').replace(re, '')
  }