import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DocumentCommunication {
    constructor(private http: HttpClient) { }
    
    generateDocument(propertyId,body) {
        let url =  `${endpointsUrls.property.property}/${propertyId}/document`
        return this.http.request('POST',url, {
            body:body,
            responseType:'blob'
        })
    }
    
    generateShapefile(propertyId,path,body={}) {
        let url =  `${endpointsUrls.property.property}/${propertyId}${path}`
        return this.http.request('POST',url, {
            body:body,
            observe: 'response',
            responseType:'blob'
        })
    }
    
    generateCsv(propertyId,path,body={}) {
        let url =  `${endpointsUrls.property.property}/${propertyId}${path}`
        return this.http.request('POST',url, {
            body:body,
            observe: 'response',
            responseType:'blob'
        })
    }

    exportBlobFormats(path,body={}) {
        let url =  `${path}`
        return this.http.request('POST',url, {
            body:body,
            observe: 'response',
            responseType:'blob'
        })
    }
}
