import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreService } from '@mf-framework/core-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@mf-framework/authentification/auth.service';
import { take, switchMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Property } from '@mf-framework/model/Property/property.typing';

@Component({
    selector: 'mandate',
    templateUrl: './mandate.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MandateComponent implements OnInit, OnDestroy {
    private _destroyed$ = new Subject();
    references
    property:Property
    superGelegates

    constructor(
        public coreService: CoreService,
        private cd: ChangeDetectorRef,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private modalService: NgbModal,
        private authService: AuthService, 
    ) {}

    ngOnInit() {
        this.route.params
            .pipe(
                switchMap(routeParams => combineLatest(
                    this.coreService.Selector$({ type: 'references' }).pipe(take(1)),
                    this.coreService.Selector$({ type: 'selectProperty', payload: { id: routeParams.id }}),
                    this.coreService.Selector$({ type: 'getSuperDelegates'}),
                ))
                ,takeUntil(this._destroyed$)
            )
            .subscribe(results => {
                this.references = results[0]
                this.property = results[1]
                this.superGelegates = results[2]
                
                this.cd.markForCheck()

                
            })
    }

    delegateTo(organization) {
        this.coreService.Reducer$({
            type:'propertyMandate', payload:{
                propertyId:this.property.id,
                organizationId: organization.id
            }
        })
    }
    
    revokeMandate(mandate) {
        this.coreService.Reducer$({
            type:'propertyRevokeMandate', payload:{
                propertyId:this.property.id,
                mandateId: mandate.id
            }
        })
    }

    notAlreadyMandatary(organization) {
        return !this.property.mandates.find(m => {
            return m.organization.id == organization.id
        })
    }

    public ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}

    

