import { Injectable } from '@angular/core';
import { Observable, merge } from 'rxjs'
import { Lot } from '../model/Lots/lot.typing';
import { LocalizationCommunication } from './localization.communication';

@Injectable({
    providedIn: 'root'
})
export class LocalizationService {
    constructor(
        private localizationCommunication: LocalizationCommunication
    ) {}
    
    findLotByCoords$(payload) : Observable<Lot> {
        return this.localizationCommunication.lotIntersects(payload.countryCode,payload.latlng)
    }
    
    queryGeographicalPoint$(payload) : Observable<Lot> {
        return this.localizationCommunication.featuresIntersects(payload.countryCode,payload.latlng)
    }
    
    findCityByString$(payload) : Observable<any> {
        return this.localizationCommunication.findCityByString(payload.countryCode,payload.text,payload.level1)
    }

    queryCadastralDivisionsByCityCode$(payload) : Observable<any> {
        return this.localizationCommunication.queryCadastralDivisionsByCityCode(payload.countryCode,payload.localityCode)
    }

    
    queryCadastralSectionsByDivisonCode$(payload) : Observable<any> {
        return this.localizationCommunication.queryCadastralSectionsByDivisonCode(payload.countryCode,payload.divisionCode)
    }

    queryCadastralSectionsByCityCode$(payload) : Observable<any> {
        return this.localizationCommunication.queryCadastralSectionsByCityCode(payload.countryCode,payload.localityCode)
    }

    
    queryCadastralNumbersBySectionCode$(payload) : Observable<any> {
        return this.localizationCommunication.queryCadastralNumbersBySectionCode(payload.countryCode,payload.sectionCode)
    }
    
    queryLotByCode$(payload) : Observable<any> {
        return this.localizationCommunication.queryLotByCode(payload.countryCode,payload.lotCode)
    }

    
}