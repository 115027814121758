import * as L from 'leaflet';
import { GeoJsonObject } from 'geojson';
import turfBuffer from '@turf/buffer';

export function extendBounds(boundsBuffer,geometry){
    if(boundsBuffer instanceof L.LatLngBounds){
        boundsBuffer.extend(geometry.getBounds())
    }
    else {
        boundsBuffer = geometry.getBounds()
        // const bounds = geometry.getBounds()
        // boundsBuffer = new L.LatLngBounds(
        //     bounds.getNorthWest(),
        //     bounds.getSouthWest()
        // )
    }
    return boundsBuffer
}

export function fitPropertyEnvelopeBounds(map,envelope){
    map.fitBounds(getGeometryBounds(envelope),{animate:false});
}

export function getGeometryBounds(envelope,bufferSize?){
    if(bufferSize && envelope.type == "Point"){
        envelope = turfBuffer(envelope,bufferSize/1000).geometry
    }

    const geo = L.geoJSON({
        "type": "Feature",
        "geometry": envelope
    } as GeoJsonObject)

    return geo.getBounds()
}

export function recenterBounds(center, bounds) {
  const width = bounds.getEast() - bounds.getWest();
  const height = bounds.getNorth() - bounds.getSouth();
  return L.latLngBounds(
    new L.LatLng(center.lat - height / 2, center.lng - width / 2),
    new L.LatLng(center.lat + height / 2, center.lng + width / 2)
  );
}
