import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { IsUnconfirmedGuard } from '@mf-framework/authentification/isUnconfirmed.guard';
import { IsLoggedInGuard } from '@mf-framework/authentification/isLoggedIn.guard';
import { IsConfirmedGuard } from '@mf-framework/authentification/isConfirmed.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MandateComponent } from './pages/mandate/mandate.component';
// import { ProfileComponent } from './pages/profile/profile.component';
import { PropertiesCreateComponent } from './pages/properties-create/properties-create.component';

const routes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    { 
        path: 'home', 
        // canActivate: [IsUnconfirmedGuard],
        component: HomeComponent,
    },
    {
        path: 'dashboard',
        canActivate: [IsLoggedInGuard,IsConfirmedGuard],
        component: DashboardComponent,
    },
    {
        path: 'mandate/:id',
        canActivate: [IsLoggedInGuard,IsConfirmedGuard],
        component: MandateComponent,
    },
    // {
    //     path: 'profile',
    //     canActivate: [IsLoggedInGuard,IsConfirmedGuard],
    //     component: ProfileComponent,
    // },
    { 
        path: 'properties-create',
        canActivate: [IsLoggedInGuard,IsConfirmedGuard],
        // // canLoad: [IsLoggedInGuard,IsConfirmedGuard],
        component: PropertiesCreateComponent ,
    },
    {
        path: 'property', 
        canActivate: [IsLoggedInGuard,IsConfirmedGuard],
        // canLoad: [IsLoggedInGuard,IsConfirmedGuard],
        loadChildren: () => import('./modules/property/property.module').then(mod => mod.PropertyModule)
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
