import { Injectable } from '@angular/core';
import { throwError } from 'rxjs'

import { AppStore } from '../../application-store'
import mergeDeep from '@mf-framework/utils/objects';
import { OperationMeasuresFetcher } from './operationMeasures.communication';


@Injectable({
    providedIn: 'root'
})
export class OperationMeasuresService {
    constructor(
        private appStore: AppStore,
        private operationMeasuresFetcher: OperationMeasuresFetcher,
    ) { }

    async addOperationMeasure(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const index = state.model.properties.collection.indexOf(property)
            const plannedOperationRef = property.plannedOperations.find(o => o.id == payload.plannedOperationId)
            const plannedOperationRefIndex = property.plannedOperations.indexOf(plannedOperationRef)

            return await this.operationMeasuresFetcher.create(payload)
            .toPromise()
            .then(
                newOperationMeasure => {
                    state.model.properties.collection[index].plannedOperations[plannedOperationRefIndex] = {
                        ...plannedOperationRef,
                        operationMeasures : [
                            ...plannedOperationRef.operationMeasures,
                            newOperationMeasure
                        ]
                    }

                    this.appStore.setState(state)
                    return newOperationMeasure
                }
            )
        }
        else {throw `addOperationMeasure : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchOperationMeasure(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const plannedOperationRef = property.plannedOperations.find(o => o.id == payload.plannedOperationId)
            const plannedOperationRefIndex = property.plannedOperations.indexOf(plannedOperationRef)
            const operationMeasureRef = plannedOperationRef.operationMeasures.find(om=>om.id==payload.operationMeasureId)
            const operationMeasureRefIndex = plannedOperationRef.operationMeasures.indexOf(operationMeasureRef)
            
            state.model.properties.collection[index].plannedOperations[plannedOperationRefIndex].operationMeasures[operationMeasureRefIndex] = {
                ...operationMeasureRef,
                ...mergeDeep(operationMeasureRef,payload.data)
            }

            this.appStore.setState(state)

            try {
                return await this.operationMeasuresFetcher.patch(payload.operationMeasureId,payload.data).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {
            throw `patchOperationMeasure : Unidentified Property with id [${payload.propertyId}]`
        }
    }

    async deleteOperationMeasure(payload) {
        const state = this.appStore.getState()
        const propertyId = payload.propertyId
        const property = state.model.properties.collection.find(p => p.id === propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const propertyIndex = state.model.properties.collection.indexOf(property)
            const plannedOperationRef = property.plannedOperations.find(po=>po.id==payload.plannedOperationId)
            const plannedOperationRefIndex = property.plannedOperations.indexOf(plannedOperationRef)
            
            const newOperationMeasuresArray = plannedOperationRef.operationMeasures.filter(measure => measure.id != payload.operationMeasureId)

            state.model.properties.collection[propertyIndex].plannedOperations[plannedOperationRefIndex] = {
                ...plannedOperationRef,
                operationMeasures : newOperationMeasuresArray
            }
            this.appStore.setState(state)

            try {
                return await this.operationMeasuresFetcher.delete(payload.operationMeasureId).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[propertyIndex] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `deleteOperationMeasure : Unidentified Property with id [${payload.propertyId}]`}
    }
    

}