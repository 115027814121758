import { Injectable } from '@angular/core';
import { JobsCommunication } from './jobs.communication';

@Injectable({
    providedIn: 'root'
})
export class JobsService {
    constructor(
        private jobsCommunication: JobsCommunication
    ) {}
    
    async updateJob(payload) {
        let httpQuery = this.jobsCommunication.updateJob(payload.jobId)
        .toPromise()
        return await httpQuery
    }
}