import * as Rollbar from 'rollbar';
import {
    Injectable,
    Inject,
    InjectionToken,
    ErrorHandler
} from '@angular/core';
import { environment } from '@current-environment/environment';

const rollbarConfig = {
    enabled: environment.production,
    accessToken: environment.rollbarApiKey,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: environment.production ? "production" : "development"
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
    constructor(@Inject(RollbarService) private rollbar: Rollbar) { }

    handleError(err: any): void {
        this.rollbar.error(err.originalError || err);
    }
}

export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}