import { Injectable } from '@angular/core';
import { merge, of, throwError } from 'rxjs'
import { map, filter, tap, switchMap, catchError } from 'rxjs/operators'

import { AppStore } from '../../application-store'
import { PropertiesFetcher } from './properties.communication'
import { UiService } from '@mf-framework/ui/ui.service';
import { AppModalService } from '@mf-framework/interface/app-modal/app-modal.service';


@Injectable({
    providedIn: 'root'
})
export class PropertiesService {
    constructor(
        public appStore: AppStore,
        public uiService: UiService,
        public propertiesFetcher: PropertiesFetcher,
        public appModalService: AppModalService,
    ) {}

    selectPropertiesDetailed$(extraFieldsLevel='detailed') {
        return this.selectProperties$(extraFieldsLevel).pipe(
            tap(propertyData => {
                propertyData.collection.forEach(p=>{
                    p._loading = "complete"
                })
            }),
        )
    }

    selectProperties$(extraFieldsLevel='summary') {
        const properties = this.appStore.store$.pipe(
            map(data => data.model.properties)
        )

        const notFetched = properties.pipe(
            filter(properties => !properties.fetched && !properties.fetching),
            tap(_ => this.setPropertiesFetching()),
            switchMap(_ => this.propertiesFetcher.index(extraFieldsLevel)),
            tap(propertiesCollection => this.setPropertiesData(propertiesCollection)),
            switchMap(_ => properties),
        )

        const fetched = properties.pipe(
            filter(properties => properties.fetched === true),
        )

        return merge(
            notFetched,
            fetched
        )
    }
        
    setPropertiesFetching() {
        const state = this.appStore.getState()
        state.model.properties = {
            ...state.model.properties,
            fetched: false,
            fetching: true
        }
        this.appStore.setState(state)
        this.uiService.showLoadingSpinner("PropertiesService:setPropertiesFetching")
    }

    setPropertiesData(propertiesCollection) {
        const state = this.appStore.getState()
        state.model.properties = {
            ...state.model.properties,
            collection: propertiesCollection,
            fetched: true,
            fetching: false
        }
        this.appStore.setState(state,true)
        
        setTimeout(() => {this.uiService.hideLoadingSpinner("PropertiesService:setPropertiesData")},100)
    }

    async addProperty(property) {
        const id = Math.floor(Math.random() * 1000000)
        const state = this.appStore.getState()
        const toCreateProperty = {
            id: id,
            name: property.name
        }

        state.model.properties.collection = [
            ...state.model.properties.collection,
            toCreateProperty
        ]

        this.appStore.setState(state)

        try {
            const createdProperty = await this.propertiesFetcher.create(property).toPromise()
            const index = state.model.properties.collection.indexOf(state.model.properties.collection.find(t => t.id === id));

            state.model.properties.collection[index] = {
                ...createdProperty
            }
            this.appStore.setState(state)
            return createdProperty
        }
        catch (error) {
            this.deleteProperty({ id, serverRemove: false })
            return throwError({error:error}).toPromise()
            
        }
    }

    setPropertiesUnFetched() {
        const state = this.appStore.getState()
        state.model.properties = {
            ...state.model.properties,
            fetched: false,
            fetching: false
        }
        this.appStore.setState(state)
        // this.uiService.showLoadingSpinner("PropertiesService:setPropertiesUnFetched")
    }
    
    async deleteProperty({ id, serverRemove = true }) {
        const state = this.appStore.getState()
        const properties = Object.assign({}, state.model.properties)

        state.model.properties.collection = state.model.properties.collection.filter(p => p.id !== id)
        this.appStore.setState(state)

        if (serverRemove) {
            try {
                await this.propertiesFetcher.delete(id).toPromise();
            }
            catch (error) {
                state.model.properties = properties
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
    }

    // async setPropertyCompleted({ id }) {
    //     const state = this.appStore.getState()
    //     const slice = state.model.properties.collection.find(p => p.id === id)

    //     if (slice) {
    //         const index = state.model.properties.collection.indexOf(slice)
    //         state.model.properties.collection[index] = {
    //             ...slice,
    //             isCompleted: !slice.isCompleted
    //         }
    //         this.appStore.setState(state)
    //     }
    // }

}