const basecolors = {
    $black : "#000",
    $white : "#fff",
    $blue : "#4297ff",
    $indigo : "#22036a",
    $brown : "#5a3616",
    $purple : "#6f42c1",
    $pink : "#e83e8c",
    $red : "#dc3545",
    $orange : "#e3701e",
    // $orangeLight : LightenDarkenColor("#e3701e",-20),
    $yellow : "#ffc107",
    $green : "#78c148 ",
    $greenLight : "#86c06f",
    $greenDark : "#3e692e",
    $teal : "#20c997",
    $cyan : "#4285f4",
    $gray100: "#f0f2f4",
    $gray400: "#ced4da",
    $gray800: "#696969"
}

const appcolors = {
    $primary:       basecolors.$orange,
    $secondary:     "#EFA875",
    $success:       basecolors.$green,
    $info:          basecolors.$cyan,
    $warning:       basecolors.$orange,
    $danger:        basecolors.$red,
    $light:         basecolors.$gray100,
    $gray:          basecolors.$gray400,
    $dark:          basecolors.$gray800,
}



export { basecolors, appcolors }


// export function LightenDarkenColor(col, amt) {
  
//     var usePound = false;
  
//     if (col[0] == "#") {
//         col = col.slice(1);
//         usePound = true;
//     }
 
//     var num = parseInt(col,16);
 
//     var r = (num >> 16) + amt;
 
//     if (r > 255) r = 255;
//     else if  (r < 0) r = 0;
 
//     var b = ((num >> 8) & 0x00FF) + amt;
 
//     if (b > 255) b = 255;
//     else if  (b < 0) b = 0;
 
//     var g = (num & 0x0000FF) + amt;
 
//     if (g > 255) g = 255;
//     else if (g < 0) g = 0;
 
//     return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  
// }
