import {Environment} from '@mf-framework/model/Environment/environment.typing';
import {latLng} from 'leaflet';

export const environment: Environment = {
  apiVersion: "3.2.1",
  application: {
    domain: "maforet_fr",
    plateform: "web"
  },
  sessionManagement: {
    type: "serverside",
  },
  outgoingUrls: {
    default: "https://connect.preprod.maforet.fr/",
    profile: "https://connect.preprod.maforet.fr/mon-compte",
    subscription: "https://connect.preprod.maforet.fr/app-forestry/abonnement",
    login: "https://connect.preprod.maforet.fr/connexion"
  },
  ignApiKey: "ign_scan_ws",
  mapsDefaultCenter: latLng(46.6027, 1.8752),
  rollbarApiKey: "1b12e67cbf07407a9e1ab02a8b55d9b8",
  deployment: "staging",
  production: true
};
