import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UiService } from '@mf-framework/ui/ui.service';
import { CripplingErrorModal } from './crippling-error.component';
import { NoConnexionModal } from './no-connexion.component';

@Injectable({
    providedIn: 'root'
})
export class AppModalService {
  
    constructor(
        private modalService: NgbModal,
        public uiService: UiService
    ){}
    
    alertCripplingError() {
        this.modalService.open(
            CripplingErrorModal
            , {centered: true }
        )
        setTimeout(() => {this.uiService.hideLoadingSpinner("AppModalService:alertCripplingError")},100)
    }
    
    alertNoConnexion() {
        this.modalService.open(
            NoConnexionModal
            , {centered: true }
        )
        setTimeout(() => {this.uiService.hideLoadingSpinner("AppModalService:alertNoConnexion")},100)
    }
    
}