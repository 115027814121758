import { Injectable } from '@angular/core';
import { throwError } from 'rxjs'

import { AppStore } from '../../application-store'
import mergeDeep from '@mf-framework/utils/objects';
import { GameDamagesFetcher } from './gameDamages.communication';


@Injectable({
    providedIn: 'root'
})
export class GameDamagesService {
    constructor(
        private appStore: AppStore,
        private gameDamagesFetcher: GameDamagesFetcher,
    ) { }

    async addGameDamage(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const index = state.model.properties.collection.indexOf(property)
            
            return await this.gameDamagesFetcher.create(payload)
            .toPromise()
            .then(
                newGameDamage => {
                    state.model.properties.collection[index] = {
                        ...property,
                        gameDamages : [
                            ...property.gameDamages,
                            newGameDamage
                        ]
                    }

                    this.appStore.setState(state)
                    return newGameDamage
                }
            )
        }
        else {throw `addGameDamage : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchGameDamage(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const gameDamageRef = property.gameDamages.find(o => o.id == payload.elementId)
            const gameDamageRefIndex = property.gameDamages.indexOf(gameDamageRef)

            state.model.properties.collection[index].gameDamages[gameDamageRefIndex] = {
                ...gameDamageRef,
                ...mergeDeep(gameDamageRef,payload.data)
            }

            this.appStore.setState(state)

            try {
                return await this.gameDamagesFetcher.patch(payload.elementId,payload.data).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `patchGameDamage : Unidentified Property with id [${payload.propertyId}]`}
    }

    async deleteGameDamage(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const newGameDamagesArray = property.gameDamages.filter(event => event.id != payload.id)
            state.model.properties.collection[index] = {
                ...property,
                gameDamages : newGameDamagesArray
            }
            this.appStore.setState(state)

            try {
                await this.gameDamagesFetcher.delete(payload.id).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `deleteGameDamage : Unidentified Property with id [${payload.propertyId}]`}
    }
    

}