import { Injectable } from '@angular/core';
import { Observable, merge, throwError } from 'rxjs'
import { map, filter, switchMap, tap } from 'rxjs/operators'

import { AppStore } from '../../application-store'
import { CommitmentsFetcher } from './commitments.communication';
import { PropertyService } from '../Property/property.service';


@Injectable({
    providedIn: 'root'
})
export class CommitmentsService {
    constructor(
        private appStore: AppStore,
        private propertyService: PropertyService,
        private commitmentsFetcher: CommitmentsFetcher,
    ) {}
    
    async addCommitment(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const index = state.model.properties.collection.indexOf(property)
            
            return await this.commitmentsFetcher.addCommitment(payload)
            .toPromise()
            .then(
                newCommitment => {
                    state.model.properties.collection[index] = {
                        ...property,
                        commitments : [
                            ...property.commitments,
                            newCommitment
                        ]
                    }

                    this.appStore.setState(state)
                    return newCommitment
                }
            )
        }
        else {throw `addCommitment : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchCommitment(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const commitmentRef = property.commitments.find(c => c.id == payload.id)
            const commitmentRefIndex = property.commitments.indexOf(commitmentRef)

            state.model.properties.collection[index].commitments[commitmentRefIndex] = {
                ...commitmentRef,
                ...payload.data
            }
            this.appStore.setState(state)

            try {
                return await this.commitmentsFetcher.patch(payload.id,payload.data).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `patchCommitment : Unidentified Property with id [${payload.propertyId}]`}
    }

    async deleteCommitment(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const newCommitmentsArray = property.commitments.filter(commitment => commitment.id != payload.commitment.id)
            state.model.properties.collection[index] = {
                ...property,
                commitments : newCommitmentsArray
            }
            this.appStore.setState(state)

            try {
                await this.commitmentsFetcher.delete(payload.commitment.id).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `deleteCommitment : Unidentified Property with id [${payload.propertyId}]`}
    }
}

