export function getValueByPath(obj,key) {
    if(!obj)return
    if(key.indexOf(".") > -1){
        let nameSpaces = key.split(".")
        return nameSpaces.reduce(
            (accumulator,value) => {
                if(accumulator)return accumulator[value]
            }, 
            obj
        )
    }
    else{
        return obj[key]
    }
}

export function clone(obj) {
    return JSON.parse(JSON.stringify(obj))
}

export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export default function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();
  
    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
  
    return mergeDeep(target, ...sources);
}
// export default function mergeDeep(target, source) {
//     let output = Object.assign({}, target);
//     if (isObject(target) && isObject(source)) {
//         Object.keys(source).forEach(key => {
//             if (isObject(source[key])) {
//                 if (!(key in target))
//                     Object.assign(output, { [key]: source[key] });
//                 else
//                     output[key] = mergeDeep(target[key], source[key]);
//             } else {
//                 Object.assign(output, { [key]: source[key] });
//             }
//         });
//     }
//     return output;
// }

export function arraySample(items) {
    if(items.length)return items[Math.floor(Math.random()*items.length)]
    return null
}

export function arrayPull(arr, ...removeList){
    var removeSet = new Set(removeList) 
    return arr.filter(function(el){
        return !removeSet.has(el)
    })
}


export function pushUnique(arr, value){
    arr.push(value)
    let uArray = [...new Set(arr)]
    return uArray
}

