import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { PlannedOperation } from './plannedOperation.typing';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';

const PARAMS = new HttpParams({
    fromObject: {
        "extra_fields": "operationMeasures"
    }
})

@Injectable({
    providedIn: 'root',
})
export class PlannedOperationsFetcher {
    constructor(private http: HttpClient) { }
    
    create(data) {
        delete data.propertyId
        let url =  endpointsUrls.property.plannedOperation
        return this.http.post<PlannedOperation>(url, data, {params : PARAMS})
    }

    patch(plannedOperationId: string, data) {
        let url =  `${endpointsUrls.property.plannedOperation}/${plannedOperationId}`
        return this.http.patch<PlannedOperation>(url, data, {params : PARAMS})
    }

    delete(plannedOperationId) {
        let url =  `${endpointsUrls.property.plannedOperation}/${plannedOperationId}`
        return this.http.delete(url)
    }

}