import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoDblClick]'
})
export class NoDblClickDirective {
  
  constructor() { }
  
  @HostListener('click', ['$event'])
  clickEvent(event) {
    const button = (event.srcElement.disabled === undefined) ? event.srcElement.parentElement : event.srcElement;
    button.setAttribute('disabled', true);
    setTimeout(function () {
        button.removeAttribute('disabled');
    }, 1000);
  }

}