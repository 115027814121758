import { Injectable } from '@angular/core';
import { throwError } from 'rxjs'

import { AppStore } from '../../application-store'
import mergeDeep from '@mf-framework/utils/objects';
import { LandEquipmentsFetcher } from './landEquipments.communication';


@Injectable({
    providedIn: 'root'
})
export class LandEquipmentsService {
    constructor(
        private appStore: AppStore,
        private landEquipmentsFetcher: LandEquipmentsFetcher,
    ) { }

    async addLandEquipment(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const index = state.model.properties.collection.indexOf(property)
            return await this.landEquipmentsFetcher.create(payload)
            .toPromise()
            .then(
                newLandEquipment => {
                    state.model.properties.collection[index] = {
                        ...property,
                        landEquipments : [
                            ...property.landEquipments,
                            newLandEquipment
                        ]
                    }

                    this.appStore.setState(state)
                    return newLandEquipment
                }
            )
        }
        else {throw `addLandEquipment : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchLandEquipment(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const landEquipmentRef = property.landEquipments.find(o => o.id == payload.elementId)
            const landEquipmentRefIndex = property.landEquipments.indexOf(landEquipmentRef)
            
            state.model.properties.collection[index].landEquipments[landEquipmentRefIndex] = {
                ...landEquipmentRef,
                ...mergeDeep(landEquipmentRef,payload.data)
            }

            this.appStore.setState(state)

            try {
                return await this.landEquipmentsFetcher.patch(payload.elementId,payload.data).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `patchLandEquipment : Unidentified Property with id [${payload.propertyId}]`}
    }

    async deleteLandEquipment(payload) {
        const state = this.appStore.getState()
        const propertyId = payload.propertyId
        const property = state.model.properties.collection.find(p => p.id === propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const newLandEquipmentsArray = property.landEquipments.filter(event => event.id != payload.id)
            state.model.properties.collection[index] = {
                ...property,
                landEquipments : newLandEquipmentsArray
            }
            this.appStore.setState(state)

            try {
                return await this.landEquipmentsFetcher.delete(payload.id).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `deleteLandEquipment : Unidentified Property with id [${payload.propertyId}]`}
    }
    

}