import { Injectable } from '@angular/core';
import { Observable, merge, throwError } from 'rxjs'
import { map, filter, switchMap, tap, mergeMap } from 'rxjs/operators'

import { AppStore } from '../../application-store'
import { PlacesFetcher } from './places.communication';
import mergeDeep from '@mf-framework/utils/objects';

@Injectable({
    providedIn: 'root'
})
export class PlacesService {
    PlacesFetcher: any;
    constructor(
        private appStore: AppStore,
        private placesFetcher: PlacesFetcher,
    ) {}
        
    async addPlace(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            
            try {
                return await this.placesFetcher.addPlace(payload)
                .toPromise()
                .then(
                    newPlace => {
                        state.model.properties.collection[index] = {
                            ...property,
                            places : [
                                ...property.places,
                                newPlace
                            ]
                        }

                        this.appStore.setState(state)
                        return newPlace
                    }
                )
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
            }
        }
        else {throw `addPlace : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchPlace(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const placeRef = property.places.find(c => c.id == payload.id)
            const placeRefIndex = property.places.indexOf(placeRef)

            state.model.properties.collection[index].places[placeRefIndex] = {
                ...placeRef,
                // ...payload.data
                ...mergeDeep(placeRef,payload.data)
            }

            this.appStore.setState(state)

            try {
                return await this.placesFetcher.patch(payload.id,payload.data)
                        .toPromise()
                        .then(result => {
                            state.model.properties.collection[index].places[placeRefIndex] = {
                                // ...placeRef,
                                ...result
                            }
                            this.appStore.setState(state)
                        })
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `patchPlace : Unidentified Property with id [${payload.propertyId}]`}
    }

    async deletePlace(payload) {
        const state = this.appStore.getState()
        const propertyId = payload.propertyId
        const property = state.model.properties.collection.find(p => p.id === propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const newPlacesArray = property.places.filter(place => place.id != payload.id)
            state.model.properties.collection[index] = {
                ...property,
                places : newPlacesArray
            }
            this.appStore.setState(state)

            try {
                await this.placesFetcher.delete(payload.id).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
    }
}

