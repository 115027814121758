import { Injectable } from '@angular/core';
import { throwError } from 'rxjs'

import { AppStore } from '../../application-store'
import { LotsFetcher } from './lots.communication';
import mergeDeep from '@mf-framework/utils/objects';
import { processPropertyLots } from './utils';

@Injectable({
    providedIn: 'root'
})
export class LotsService {
    constructor(
        private appStore: AppStore,
        private lotsFetcher: LotsFetcher,
    ) {}
    
    async getLots(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)

            try {
                let httpQuery = this.lotsFetcher.get(payload.propertyId)
                .toPromise()
                httpQuery.then(
                    lots => {
                        state.model.properties.collection[index].lots = [...lots]
                        processPropertyLots(state.model.properties.collection[index])
                        this.appStore.setState(state)
                    }
                )

                return await httpQuery
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `getLots : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchLot(payload) {
        const state = this.appStore.getState()
        const propertyId = payload.lot.propertyId
        const property = state.model.properties.collection.find(p => p.id === propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const lotRef = property.lots.find(c => c.id == payload.lot.id)
            const lotRefIndex = property.lots.indexOf(lotRef)

            state.model.properties.collection[index].lots[lotRefIndex] = {
                ...lotRef,
                ...mergeDeep(lotRef,payload.data)
            }

            this.appStore.setState(state)

            try {
                let httpQuery = this.lotsFetcher.patch(payload.lot.id,payload.data).toPromise()
                if(payload.updateModel){
                    httpQuery.then(
                        updatedLot => {
                            state.model.properties.collection[index].lots[lotRefIndex] = {
                                ...lotRef,
                                ...updatedLot
                                // ...mergeDeep(standRef,updatedStand)
                            }
                            this.appStore.setState(state)
                        }
                    )
                }
                return await httpQuery
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `patchLot : Unidentified Property with id [${payload.lot.propertyId}]`}
    }

    async importLots(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const index = state.model.properties.collection.indexOf(property)
            return await this.lotsFetcher.uploadFile(payload)
            .toPromise()
            .then(
                job => {
                    console.log(job);
                    
                    return job
                }
            )
        }
        else {throw `importLots : Unidentified Property with id [${payload.propertyId}]`}
    }
}