import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Place } from './place.typing';
import { endpointsUrls } from '@mf-framework/end-points-urls/end-points-urls';

@Injectable({
    providedIn: 'root',
})
export class PlacesFetcher {
    constructor(private http: HttpClient) { }

    addPlace(data) {
        let url =  endpointsUrls.property.place
        return this.http.post<Place>(url, data)
    }

    delete(placeId) {
        let url =  `${endpointsUrls.property.place}/${placeId}`
        return this.http.delete(url)
    }

    patch(placeId: string, data) {
        let url =  `${endpointsUrls.property.place}/${placeId}`
        return this.http.patch<Place>(url, data)
    }

}