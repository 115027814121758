import { Injectable } from '@angular/core';
import { Observable, merge, throwError, of } from 'rxjs'
import { map, filter, switchMap, tap, mergeMap, defaultIfEmpty } from 'rxjs/operators'
import { AppStore } from '../../application-store'
import { PropertyService } from '../Property/property.service';
import { CustomDataFetcher } from './customData.communication';


@Injectable({
    providedIn: 'root'
})
export class CustomDataService {
    constructor(
        private appStore: AppStore,
        private customDataFetcher: CustomDataFetcher,
    ) {}

    private propertyService: PropertyService
    instantiatePropertyService(propertyService){
        this.propertyService = propertyService
    }

    setCustomDataData(id,customdata){
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === +id)

        if (property) {
            const index = state.model.properties.collection.indexOf(property)
            state.model.properties.collection[index] = {
                ...property,
                customData : customdata,
                customDataFetched : true,
                customDataFetching : false
            }
            this.appStore.setState(state)
        }
        else {throw `setCustomDataData : Unidentified Property with id [${id}]`}
    }

    setCustomDataFetching(property) {
        const state = this.appStore.getState()
        const index = state.model.properties.collection.indexOf(property)
        state.model.properties.collection[index] = {...property,customDataFetching: true}
        this.appStore.setState(state)
    }

    selectAllCustomData$(payload) {
        const property = this.propertyService.selectProperty$(payload)
        
        const notFetched = property.pipe(
            filter(property => !property.customDataFetched && !property.customDataFetching),
            tap(property => this.setCustomDataFetching(property)),
            switchMap(_ => this.customDataFetcher.index(payload.id)),
            tap(customdata => this.setCustomDataData(payload.id,customdata)),
        )
        
        const fetched = property.pipe(
            filter(property => property.customDataFetched === true),
            map(property => property.customData),
        )

        return merge(
            notFetched,
            fetched
        )
    }

    selectCustomDataKey$(payload) {
        return this.selectAllCustomData$(payload)
        .pipe(
            map(customData => {
                let Key
                customData.map(k => {
                    if(k.key == payload.key)Key = k
                })
                return Key
            }),
        )
    }

    async addCustomDataKey(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const index = state.model.properties.collection.indexOf(property)
            
            return await this.customDataFetcher.addCustomData(payload.propertyId,payload.data)
            .toPromise()
            .then(
                newCustomDataKey => {
                    state.model.properties.collection[index] = {
                        ...property,
                        customData : [
                            ...property.customData,
                            newCustomDataKey
                        ]
                    }

                    this.appStore.setState(state)
                    return newCustomDataKey
                }
            )
        }
        else {throw `addCustomDataKey : Unidentified Property with id [${payload.propertyId}]`}
    }

    async patchCustomDataKey(payload) {
        const state = this.appStore.getState()
        const property = state.model.properties.collection.find(p => p.id === payload.propertyId)
        
        if (property) {
            const backup = JSON.stringify(property)
            property.updatedAt = Date.now()
            const index = state.model.properties.collection.indexOf(property)
            const customDataKeyRef = property.customData.find(c => c.key == payload.key)
            const customDataKeyRefIndex = property.customData.indexOf(customDataKeyRef)

            state.model.properties.collection[index].customData[customDataKeyRefIndex] = {
                ...customDataKeyRef,
                ...payload.data
            }
            this.appStore.setState(state)

            try {
                return await this.customDataFetcher.patch(payload.propertyId,payload.key,payload.data).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `patchCustomDataKey : Unidentified Property with id [${payload.propertyId}]`}
    }

    async deleteCustomDataKey(payload) {
        const state = this.appStore.getState()
        const propertyId = payload.propertyId
        const property = state.model.properties.collection.find(p => p.id === propertyId)

        if (property) {
            const backup = JSON.stringify(property)
            const index = state.model.properties.collection.indexOf(property)
            const newCustomDataArray = property.customData.filter(cd => cd.key != payload.key)
            state.model.properties.collection[index] = {
                ...property,
                customData : newCustomDataArray
            }
            this.appStore.setState(state)

            try {
                await this.customDataFetcher.delete(propertyId,payload.key).toPromise()
            } 
            catch (error) {
                state.model.properties.collection[index] = JSON.parse(backup)
                this.appStore.setState(state)
                return throwError({error:error}).toPromise()
            }
        }
        else {throw `deleteCustomDataKey : Unidentified Property with id [${payload.propertyId}]`}
    }
}

