import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@mf-framework/authentification/auth.service';
import { timer, Subject } from 'rxjs';
import { takeUntil, take, distinctUntilChanged, debounceTime, pairwise, catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreService } from '@mf-framework/core-service';
import { markFormAsDirty } from '@mf-framework/utils/forms';
import { clone, arraySample } from '@mf-framework/utils/objects';


@Component({
    selector: 'maforet-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {
    private _destroyed$ = new Subject();
    references

    loginAttemptPending = false

    constructor(
        public coreService: CoreService,
        private authService: AuthService, 
        private fb: FormBuilder, 
        private router: Router,
        private route: ActivatedRoute,
        private cd: ChangeDetectorRef,
        private modalService: NgbModal
    ) { }
    
    isLoggedIn = false
    ngOnInit() {
        // this.initBgImages()
        
        // this.isLoggedIn = this.authService.isLoggedIn()
        // if(this.isLoggedIn){
        //     this.checkConfirmationStatus()
        // }

        this.coreService.Selector$({ type: 'publicReferences' }).pipe(take(1))
        .subscribe(ref => {
            this.references = ref

            // this.loginForm.patchValue({
            //     email:"anne.leblond@leblond.be",
            //     password:"33333333",
            // })

            this.cd.detectChanges()
        })

        this.route.queryParams.pipe(take(1))
        .subscribe(params => {
            if(params) {
                if(params["reason-code"]){
                    this.urlReasonCode = params["reason-code"]
                }
            }
        });
    }

    logout() {
        this.authService.logout()
        .subscribe(success => {
            console.log(111)
            window.location.replace("/");
        })
    }

    urlReasonCode

    loginForm: FormGroup = this.fb.group({
        email: [{value: '', disabled: this.loginAttemptPending}, Validators.email],
        password: [{value: '', disabled: this.loginAttemptPending}, Validators.required]
    })
    
    get f() { return this.loginForm.controls; }

    loginFailureMessage
    login() {
        if(this.loginAttemptPending)return
        
        this.loginAttemptPending = true
        this.loginFailureMessage = undefined

        this.authService.login(
            {
                email: this.f.email.value,
                password: this.f.password.value
            }
        )
        .subscribe(result => {
            this.loginAttemptPending = undefined
            this.isLoggedIn = this.authService.isLoggedIn()

            if (result.status == 200 || result.status == 204) {
                if(this.authService.isConfirmed()){
                    this.router.navigate(['/dashboard'])
                }
                else{
                    window.location.reload()
                }
            }
            else{
                if(result.status == 401){
                    this.loginFailureMessage = "credentials" 
                }
                else {
                    this.loginFailureMessage = "other"
                    setTimeout(()=>{
                        this.resetLoginFailureMessage()
                    },5000)
                }
            }
            this.cd.detectChanges()
        })
    }
    
    resetLoginFailureMessage() {
        this.loginFailureMessage = undefined
        this.cd.detectChanges()
    }


    public ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
